import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import config from "../../config.json";
import uuid from "react-uuid";
import { forwardRef } from "react";
import Select from "react-select";
import MaterialTable from "material-table";
import { alpha } from "@material-ui/core/styles";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
//import { Form } from "react-bootstrap";

// import { MDBDataTableV5 } from "mdbreact";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import daintyfirebase from "./../../daintyfirebase";
import Moment from "moment";
import Chart from "./Chart";
import AdminManagePosts from "./AdminManagePosts";
import AdminCategory from "./AdminDaintyCategory";
import AdminManageGallery from "./AdminManageGallery";
import AdminViewUsers from "./AdminViewUsers";
import AdminOrder from "./AdminOrder";
import on from "./../../assets/images/icons/on.png";
import off from "./../../assets/images/icons/off.png";
import edit from "./../../assets/images/icons/edit.png";
import JoditEditor from "jodit-react";
import deleteicon from "./../../assets/images/icons/delete.png";
import "./../../css/style.css";
import "./../../css/templete.css";
import "./../../css/skin/skin-1.css";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const AdminDaintyProduct = (props) => {
  //const [productDtl, setProductDtl] = useState({});
  //const [validationMsg, setValidationMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [support_image, setSupport_image] = useState("");
  const [product_image_more, setProduct_image_more] = useState("");
  const [smShow, setSmShow] = useState(false);
  const [show, setShow] = useState(false);
  const [imgshow, setImgShow] = useState(false);
  const [products, setProducts] = useState([]);
  const history = useHistory();
  const editor = useRef(null);
  const [orderDetails, setorderDetails] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [editProduct, setEditProduct] = useState({});
  const [categorydrp, set_categorydrp] = useState("");
  const [prioritydrp, set_prioritydrp] = useState("");
  const [networkError, setNetworkError] = useState("");
  const [posts, setPosts] = useState([]);
  const [editPost, setEditPost] = useState({});
  const [smShowCategory, setSmShowCategory] = useState(false);
  const [content, setContent] = useState("");
  const [flag, setFlag] = useState(false);
  const [access, setAccess] = useState(false);
  const [netProductPrice, setNetProductPrice] = useState(0);
  const [actualPrice, setActualPrice] = useState(0);
  const [taxPercentage, setTaxPercentage] = useState(0);
  const config_ = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };
  const handleVisible = () => {
    setSmShow(true);
    setTimeout(() => {
      setSmShow(false);
    }, 1000);
  };

  const onChange_image = async (e) => {
    setSuccessMsg("Please wait");
    setSmShow(true);
    const file = e.target.files[0];
    console.log("file", e.target.files[0]);
    if (file.size < 300000) {
      console.log("add products", file);
      const path = localStorage.getItem("name") + "-" + localStorage.getItem("uuid") + "/products";
      const storageRef = daintyfirebase.app("daintyfb").storage().ref(path);
      const fileRef = storageRef.child(editProduct.id);
      await fileRef.put(file);
      setSupport_image(await fileRef.getDownloadURL());
      setSmShow(false);
      setSuccessMsg("");
    } else {
      setSuccessMsg("Please upload file less than 300 kb");
    }
  };

  const deletePost = (id) => {
    let text = "Are you sure to delete ? \nDeleting the item cannot be retrived again.";
    if (window.confirm(text) == true) {
      console.log(id);
      fetch(config.service_url + "deletepost", {
        method: "POST",
        headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
        body: JSON.stringify({ Id: id }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 200) {
            console.log(JSON.stringify({ Id: id }));
            setSuccessMsg(data.message);
            handleVisible();
            getPostDetails();
          } else if (data?.status === 499) {
            history.push("/login");
          } else {
            console.log(data.message);
          }
        })
        .catch((err) => {
          //setNetworkError("Something went wrong, Please try again later!!");
          console.log(err);
        });
    } else {
      return;
    }
  };
  const activateDeactivateProduct = (pid, id) => {
    console.log(id);
    fetch(config.service_url + "updaterequest", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ data: { p_id: pid, isactive: id } }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          //console.log(JSON.stringify({ data: { p_id: pid, isactive: id } }));
          setSuccessMsg(data.message);
          handleVisible();
          getPostDetails();
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
          handleVisible();
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };

  const getPostDetails = async () => {
    await fetch(config.service_url + "getadminposts", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          let active;
          config.tucadminuserid === JSON.parse(localStorage.getItem("pages"))["userid"] || config.tucadminuseridqa === JSON.parse(localStorage.getItem("pages"))["userid"]
            ? (active = data.data
                .filter((filter) => filter.posttypevalue === "support" && filter.isactive === 1)
                .map((data) => {
                  return data;
                }))
            : (active = data.data
                .filter((filter) => filter.posttypevalue === "support" && filter.collection === JSON.parse(localStorage.getItem("pages"))["collection"] && filter.isactive === 1)
                .map((data) => {
                  return data;
                }));
          setPosts(active);
        } else if (data?.status === 499) {
          history.push("/login");
        }
        console.log(posts, "getsupportposts");
      })
      .catch((err) => {
        //setNetworkError("Something went wrong, Please try again later!!");
        // console.log(networkError);
      });
  };

  useEffect(() => {
    // if (access) {
    // getProductDetails();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag, access]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: {} });
  const onSubmit = (data, e) => {
    let methodname = "addpost";
    setSuccessMsg("Please wait");
    setSmShow(true);
    e.preventDefault();
    if (localStorage.getItem("uuid") === undefined || localStorage.getItem("uuid") === null) {
      history.push("/login");
    } else if (Object.keys(editProduct).length > 0) {
      data.id = editProduct.id;
      console.log("update before", data);
      data.isactive = 1;
      data.updateddate = new Date();
      methodname = "updatepost";
      data.username = data.username !== "" ? data.username : editProduct.username;
      data.category = data.category !== "" ? data.category : editProduct.category;
      data.priority = data.priority !== "" ? data.priority : editProduct.priority;
      data.description = data.description !== "" ? content : editProduct.description;
      data.image = support_image;
      data.posttypevalue = "support";
    } else {
      data.id = uuid();
      data.createddate = new Date();
      data.createduserid = localStorage.getItem("uuid");
      data.createdby = localStorage.getItem("name");
      data.isactive = 1;
      data.updateddate = new Date();
      data.image = support_image;
      data.description = content;
      data.posttypevalue = "support";
      data.status = "pending";
      data.collection = JSON.parse(localStorage.getItem("pages"))["collection"];
    }
    console.log("inputoutput", data);
    fetch(config.service_url + methodname, { method: "POST", headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") }, body: JSON.stringify({ data }) })
      .then((response) => response.json())
      .then((data) => {
        console.log("inputoutput", data);
        if (data.status === 200) {
          e.target.reset();
          reset({ name: "" });
          setEditProduct({});
          set_categorydrp("");
          set_prioritydrp("");
          setSuccessMsg(data.message);
          setSmShow(false);
          handleVisible();
          setFlag(true);
        } else if (data?.status === 499) {
          console.log("error", data);
          setSuccessMsg("Error Occured");
        } else {
          setSuccessMsg(data.message);
        }
      })
      .catch((err) => {
        setSuccessMsg("Something went wrong, Please try again later!!");
      });
  };

  const editData = (e, product) => {
    setFlag(false);
    setEditProduct({});
    document.getElementById("frmProductadd").reset();
    setShow((show) => !show);
    setEditProduct(product);
    set_prioritydrp(product.priority);
    set_categorydrp(product.category);
    setContent(product.description);
  };
  const switchproduct = () => {
    setShow((show) => !show);

    setContent("");
  };
  const refresh = () => {
    window.location.reload(false);
  };

  const { username, email, description, image, priority, category } = Object.keys(editProduct).length > 0 ? editProduct : {};

  const columns = [
    {
      title: "Sno",
      field: "sno",
    },
    {
      title: "Name",
      field: "username",
    },
    {
      title: "Email",
      field: "email",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "Category",
      field: "category",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "Prioriry",
      field: "priority",
    },

    {
      title: "Status",
      field: "status",
    },
    {
      title: "View",
      field: "view",
    },

    {
      title: "Remove",
      field: "remove",
    },
  ];

  const supportdata =
    posts &&
    posts.length > 0 &&
    posts.map((post, index) => ({
      sno: index + 1,
      username: post.username,
      email: post.email,
      category: post.category,
      priority: post.priority,
      status: (
        <>
          {config.tucadminuserid === JSON.parse(localStorage.getItem("pages"))["userid"] || config.tucadminuseridqa === JSON.parse(localStorage.getItem("pages"))["userid"] ? (
            <select className="form-select" id="status" value={post.status} required ref={register} name="status">
              <option value={""}>{""}</option>
              <option value="pending">Pending</option>
              <option value="inprogress">In Progress</option>
              <option value="resolved">Resolved</option>
              <option value="closed">Closed</option>
            </select>
          ) : (
            post.status
          )}
        </>
      ),
      view: (
        <>
          {" "}
          <Link to="#" className="py-1" onClick={(e) => editData(e, post)}>
            <img src={edit} alt="edit" className="iconwidth" />
          </Link>{" "}
        </>
      ),
      remove: (
        <>
          <Link to="#" className="" onClick={(e) => deletePost(post.id)}>
            <img src={deleteicon} alt="delete" className="iconwidth" />
          </Link>
        </>
      ),
    }));

  {
    return (
      <div>
        <Modal size="sm" className="z-index" show={smShow} onHide={() => setSmShow(false)}>
          <Modal.Header closeButton>
            <span className="text-dark"> {successMsg}</span>
          </Modal.Header>
        </Modal>
        <h4 className="text-danger">
          <small>This support page will be tentativly live from June 1. Thanks.</small>
        </h4>
        <h4>
          <small>Please raised Ticket for quicker resolution.</small>
        </h4>
        <div id="review_form_wrapper">
          <div className={!show ? "" : "d-none"} id="review_form">
            <button className="btnadmin my-2" onClick={(e) => (switchproduct(), getPostDetails())}>
              View all your Tickets
            </button>{" "}
            <div id="respond" className="comment-respond">
              <h3 className="comment-reply-title" id="reply-title">
                <small> Raise Support Request</small>
              </h3>

              <form className="comment-form" onSubmit={handleSubmit(onSubmit)} id="frmProductadd">
                <div className="comment-form-author w-75">
                  <div className="row">
                    <div className="col">
                      <label>
                        Name <span className="required">*</span>
                      </label>
                      <input type="text" className="form-control" required aria-required="true" defaultValue={username} name="username" ref={register} id="username" />
                    </div>
                    <div className="col">
                      {" "}
                      <label>
                        Email <span className="required">*</span> <small>Please enter your valid email to contact you.</small>
                      </label>
                      <input type="email" className="form-control" required aria-required="true" defaultValue={email} name="email" ref={register} id="email" />
                    </div>
                  </div>
                </div>
                <div className="comment-form-author w-75">
                  <div className="row">
                    <div className="col">
                      {" "}
                      <label>
                        Category <span className="required">*</span>
                      </label>
                      <select className="form-select" value={categorydrp} id="category" required ref={register} name="category" onChange={(e) => set_categorydrp(e.target.value)}>
                        <option value={""}>{""}</option>
                        <option value="Techinical Support">Techinical Support</option>
                        <option value="New Enhancement">New Enhancement</option>
                        <option value="New Website">New Website</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                    <div className="col">
                      <label>
                        Priority / Resolution Time <span className="required">*</span>
                      </label>

                      <select className="form-select" id="priority" value={prioritydrp} required ref={register} name="priority" onChange={(e) => set_prioritydrp(e.target.value)}>
                        <option value={""}>{""}</option>
                        <option value="Critical">Critical - 1 day</option>
                        <option value="Very High">Very High - 2 day</option>
                        <option value="High">High - 3 day</option>
                        <option value="Moderage">Moderate - 4 day</option>
                        <option value="Low">Low - 5 day</option>
                        <option value="Non Urgent">Non Urgent</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="comment-form-author w-75">
                  <div className="row"></div>
                </div>

                <div className="comment-form-comment w-100">
                  <label>
                    Description <span className="required">*</span>{" "}
                  </label>
                  <div className="row">
                    <div className="col-lg-12">
                      <JoditEditor
                        ref={editor}
                        value={content}
                        config={config_}
                        tabIndex={2} // tabIndex of textarea
                        rows="8"
                        onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={(newContent) => {}}
                      />
                    </div>{" "}
                  </div>
                </div>
                <div className="form-submit">
                  {Object.keys(editProduct).length === 0 && (
                    <button type="submit" disabled={smShow} className="btnadmin btnhover">
                      Submit
                    </button>
                  )}
                  {Object.keys(editProduct).length > 0 && (
                    <button className="btnadmin btnhover" disabled={smShow} type="submit">
                      Update
                    </button>
                  )}{" "}
                </div>
              </form>
            </div>
          </div>
          <div id="product" className={show ? "table check-tbl" : "d-none"}>
            <button
              className="btnadmin my-2"
              onClick={(e) => {
                setEditProduct({});
                setShow((show) => !show);
                setSupport_image("");
                setFlag(false);
              }}
            >
              Raise Support Request. Total request : ({posts.length > 0 ? posts.length : 0})
            </button>{" "}
            <hr />
            <div className="overflow-auto px-1 text-small">
              <small>
                <MaterialTable title="Support Request" icons={tableIcons} data={supportdata.length > 0 ? supportdata : [{ sno: "Loading..." }]} columns={columns} options={{ search: true, paging: true, filtering: false, sorting: true, pageSize: 20, exportButton: true }} />
              </small>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default AdminDaintyProduct;
