import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import config from "../../config.json";
import uuid from "react-uuid";
import { forwardRef } from "react";
import Select from "react-select";
import MaterialTable from "material-table";
import { alpha } from "@material-ui/core/styles";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
//import { Form } from "react-bootstrap";

// import { MDBDataTableV5 } from "mdbreact";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import daintyfirebase from "./../../daintyfirebase";
import Moment from "moment";
import Chart from "./Chart";
import AdminManagePosts from "./AdminManagePosts";
import AdminCategory from "./AdminDaintyCategory";
import AdminManageGallery from "./AdminManageGallery";
import AdminViewUsers from "./AdminViewUsers";
import AdminOrder from "./AdminOrder";
import on from "./../../assets/images/icons/on.png";
import off from "./../../assets/images/icons/off.png";
import edit from "./../../assets/images/icons/edit.png";
import JoditEditor from "jodit-react";
import deleteicon from "./../../assets/images/icons/delete.png";
import "./../../css/style.css";
import "./../../css/templete.css";
import "./../../css/skin/skin-1.css";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const AdminDaintyProduct = (props) => {
  //const [productDtl, setProductDtl] = useState({});
  //const [validationMsg, setValidationMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [product_image, setProduct_image] = useState("");
  const [product_image_more, setProduct_image_more] = useState("");
  const [smShow, setSmShow] = useState(false);
  const [show, setShow] = useState(false);
  const [imgshow, setImgShow] = useState(false);
  const [products, setProducts] = useState([]);
  const history = useHistory();
  const editor = useRef(null);
  const [orderDetails, setorderDetails] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [editProduct, setEditProduct] = useState({});
  const [networkError, setNetworkError] = useState("");
  const [masterMainCategory, setMainMasterCategory] = useState([{ maincategory: "Select" }]);
  const [masterCategory, setMasterCategory] = useState([]);
  const [masterSubCategory, setMasterSubCategory] = useState([]);
  const [masterBrand, setMasterBrand] = useState([]);
  const [p_maincategorydrp, setp_maincategorydrp] = useState("");
  const [p_categorydrp, setp_categorydrp] = useState("");
  const [p_subcategorydrp, setp_subcategorydrp] = useState("");
  const [p_branddrp, setp_Branddrp] = useState("");

  const [smShowCategory, setSmShowCategory] = useState(false);
  //const [dataUri, setDataUri] = useState("");
  const [showCamera, setShowCamera] = useState(false);
  const [ownerNotes, setownerNotes] = useState("");
  const [content, setContent] = useState("");
  const [flag, setFlag] = useState(false);
  const [access, setAccess] = useState(false);
  const [netProductPrice, setNetProductPrice] = useState(0);
  const [actualPrice, setActualPrice] = useState(0);
  const [taxPercentage, setTaxPercentage] = useState(0);
  const config_ = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };
  const handleVisible = () => {
    setSmShow(true);
    setTimeout(() => {
      setSmShow(false);
    }, 1000);
  };

  const handleChange = (inputValue, data) => {
    if (data === "maincategory" && inputValue != null) setp_maincategorydrp(inputValue.value);
    if (data === "category" && inputValue != null) setp_categorydrp(inputValue.value);
    if (data === "subcategory" && inputValue != null) setp_subcategorydrp(inputValue.value);
    if (data === "brand" && inputValue != null) setp_Branddrp(inputValue.value);
  };

  const onChange_image = async (e) => {
    setSuccessMsg("Please wait");
    setSmShow(true);
    const file = e.target.files[0];
    console.log("file", e.target.files[0]);
    if (file.size < 300000) {
      console.log("add products", file);
      const path = localStorage.getItem("name") + "-" + localStorage.getItem("uuid") + "/products";
      const storageRef = daintyfirebase.app("daintyfb").storage().ref(path);
      const fileRef = storageRef.child(editProduct.p_id);
      await fileRef.put(file);
      setProduct_image(await fileRef.getDownloadURL());
      console.log("add products", product_image);
      setSmShow(false);
      setSuccessMsg("");
    } else {
      setSuccessMsg("Please upload file less than 300 kb");
    }
  };
  const onChange_image_more = async (e) => {
    setSuccessMsg("Please wait");
    setSmShow(true);
    const file = e.target.files[0];
    console.log("file", e.target.files[0]);
    if (file.size < 300000) {
      console.log("add products", file);
      const path = localStorage.getItem("name") + "-" + localStorage.getItem("uuid") + "/products";
      const storageRef = daintyfirebase.app("daintyfb").storage().ref(path);
      console.log("daintystorage databbase", storageRef);
      const fileRef = storageRef.child(uuid() + "more");
      await fileRef.put(file);
      setProduct_image_more(await fileRef.getDownloadURL());
      console.log("add products", product_image_more);
      setSmShow(false);
      setSuccessMsg("");
    } else {
      setSuccessMsg("Please upload file less than 500 kb");
    }
  };
  const getcatdropdowns = () => {
    getMainCategories();
    getCategories();
    getSubCategories();
    getBrand();
  };

  const getProductDetails = async () => {
    console.log("cakecategory", props);
    await fetch(config.daintyservice_url + "getadminproducts", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
    })
      .then((response) => response.json())
      .then((data) => {
        {
          setProducts(data);
          console.log(data, "products");
        }
      })
      .catch((err) => {
        //setNetworkError("Something went wrong, Please try again later!!");
        console.log("product error", err);
      });
  };
  const deleteProduct = (id, url, moreimages) => {
    let text = "Are you sure to delete ? \nDeleting the item cannot be retrived again.";
    if (window.confirm(text) == true) {
      console.log(id);
      fetch(config.daintyservice_url + "deleteProduct", {
        method: "POST",
        headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
        body: JSON.stringify({ Id: id }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 200) {
            console.log(JSON.stringify({ Id: id }));
            setSuccessMsg(data.message);
            handleVisible();
            getProductDetails();
            console.log("mainurl", url);

            moreimages?.map((url) => {
              console.log("moreimageurl", url);
              deleteFromFirebase(url);
            });

            if (url !== undefined && url !== null && url !== "") {
              deleteFromFirebase(url);
            }
          } else if (data.status === 499) {
            history.push("/login");
          } else {
            setSuccessMsg(data.message);
            handleVisible();
          }
        })
        .catch((err) => {
          //setNetworkError("Something went wrong, Please try again later!!");
          //console.log(networkError);
        });
    } else {
      return;
    }
  };
  const activateDeactivateProduct = (pid, id) => {
    console.log(id);
    fetch(config.daintyservice_url + "updateproduct", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ data: { p_id: pid, isactive: id } }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          //console.log(JSON.stringify({ data: { p_id: pid, isactive: id } }));
          setSuccessMsg(data.message);
          handleVisible();
          getProductDetails();
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
          handleVisible();
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const isFeatured = (pid, id, name) => {
    console.log(id);
    fetch(config.daintyservice_url + "updateproduct", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: name === "onsale" ? JSON.stringify({ data: { p_id: pid, isonsale: id } }) : JSON.stringify({ data: { p_id: pid, isfeatured: id } }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          //console.log(JSON.stringify({ data: { p_id: pid, isactive: id } }));
          setSuccessMsg(data.message);
          handleVisible();
          getProductDetails();
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
          handleVisible();
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const addMoreimage = () => {
    let url = [{ url: product_image_more }];
    let _allUrls = [];
    console.log("addmoreurl", product_image_more);
    console.log("addmoredb", moreimageurl);
    //console.log("check length", editProduct.moreimageurl.length);

    if (editProduct.moreimageurl && editProduct?.moreimageurl?.length > config.imagecount) {
      return;
    }
    // if (moreimageurl !== undefined) {
    // _allUrls = moreimageurl.push(product_image_more);
    moreimageurl?.map((url) => {
      return _allUrls.push(url);
    });
    _allUrls.push(product_image_more);
    editProduct.moreimageurl = _allUrls;
    // moreimageurl.push(_allUrls);
    // _allUrls = [...moreimageurl, product_image_more];
    // _allUrls.push(product_image_more);
    console.log("addmoredbpushext", _allUrls);
    // } else {
    //   _allUrls.push(product_image_more);
    //   console.log("addmoredbpushnew", _allUrls);
    // }
    console.log("addmoredbpush", _allUrls);
    // add this to exisitng image url array in the table.
    // add delete fucntion to delte each image from the array.
    fetch(config.daintyservice_url + "updateproduct", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ data: { p_id: editProduct.p_id, moreimageurl: _allUrls } }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setSuccessMsg(data.message);
          setProduct_image_more("");
          let findProductIndex = products.findIndex((prod) => prod.p_id === editProduct.p_id); //finding index of the item
          products[findProductIndex].moreimageurl = _allUrls;
          handleVisible();
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
          handleVisible();
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const deleteMoreImage = (deleteIndex) => {
    let localImage = [];

    let deleteUrl = editProduct.moreimageurl.map((durl, index) => {
      if (deleteIndex === index) {
        console.log("durl", durl);
        deleteFromFirebase(durl);
      }
    });

    editProduct.moreimageurl.map((url, index) => {
      if (index !== deleteIndex) {
        localImage.push(url);
      }
      return localImage;
    });
    editProduct.moreimageurl = localImage;

    fetch(config.daintyservice_url + "updateproduct", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ data: { p_id: editProduct.p_id, moreimageurl: localImage } }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setSuccessMsg(data.message);
          setProduct_image_more("");
          handleVisible();
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
          handleVisible();
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };

  const deleteFromFirebase = (url) => {
    console.log("File url : " + url);
    var fileRef = daintyfirebase.storage().refFromURL(url);
    // Delete the file using the delete() method
    fileRef
      .delete()
      .then(function () {
        // File deleted successfully
        console.log("File Deleted");
        setSuccessMsg("Deleted Sucessfully");
        handleVisible();
      })
      .catch(function (error) {
        console.log("File error", error);
      });

    // console.log("File in database after delete exists : " + fileRef?.exists());
  };
  const getMainCategories = async () => {
    await fetch(config.daintyservice_url + "getmaincategory", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          console.log("main master category", data);
          let _filterData = data.data.filter((_d) => _d.type === "product" && _d.isactive === 1);
          setMainMasterCategory(_filterData);
        } else if (data.status === 400) {
          console.log("No Data");
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const getCategories = async () => {
    await fetch(config.daintyservice_url + "getcategory", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          console.log("master category", data);
          let _filterData = data.data.filter((_d) => _d.type === "product");
          setMasterCategory(_filterData);
        } else if (data.status === 400) {
          console.log("No Data");
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const getBrand = async () => {
    await fetch(config.daintyservice_url + "getbrand", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          let _filterData = data.data.filter((_d) => _d.type === "product");
          setMasterBrand(_filterData);
        } else if (data.status === 400) {
          console.log("No Data");
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const getSubCategories = async () => {
    await fetch(config.daintyservice_url + "getsubcategory", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          let _filterData = data.data.filter((_d) => _d.type === "product");
          console.log("master sub category", data);
          setMasterSubCategory(_filterData);
        } else if (data.status === 400) {
          console.log("No Data");
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const checkaccess = async () => {
    let datas = {};
    datas.userid = localStorage.getItem("uuid");
    datas.page = "Product";
    await fetch(config.service_url + "checkaccess", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ data: datas }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("access", data);
        if (data.status === 200) {
          setAccess(true);
        } else {
          history.push("/adminhome");
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
      });
  };
  useEffect(() => {
    checkaccess();
    // if (access) {
    //getProductDetails();
    getcatdropdowns();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag, access]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: {} });
  const onSubmit = (data, e) => {
    let methodname = "products";
    setSuccessMsg("Please wait");
    setSmShow(true);
    e.preventDefault();
    if (localStorage.getItem("uuid") === undefined || localStorage.getItem("uuid") === null) {
      history.push("/login");
    } else if (parseFloat(data.p_price) > parseFloat(data.p_net_product_price)) {
      //offer price > et price /mrp
      setSuccessMsg("Offer Price cannot be greater than Net Price/MRP ");
      setSmShow(false);
      handleVisible();
      return;
    } else if (Object.keys(editProduct).length > 0) {
      data.p_id = editProduct.p_id;
      console.log("update before", data);
      data.isactive = 1;
      data.p_updateddate = new Date();
      methodname = "updateproduct";
      data.p_name = data.p_name !== "" ? data.p_name : editProduct.p_name;
      data.p_category = data.p_category !== "" ? data.p_category : editProduct.p_category;
      data.p_subcategory = data.p_subcategory !== "" ? data.p_subcategory : editProduct.p_subcategory;
      data.p_brand = data.p_brand !== "" ? data.p_brand : editProduct.p_brand;
      data.p_quantity = data.p_quantity !== "" ? data.p_quantity : editProduct.p_quantity;
      data.p_itemcode = data.p_itemcode !== "" ? data.p_itemcode : editProduct.p_itemcode;
      data.p_description = data.p_description !== "" ? content : editProduct.p_description;
      data.p_price = data.p_price !== "" || data.p_price === 0 ? data.p_price : editProduct.p_price; // offer pricce
      data.p_image = product_image;
      data.p_actual_price = data.p_actual_price !== "" ? data.p_actual_price : editProduct.p_actual_price; // not in use
      data.p_net_product_price = data.p_net_product_price !== "" ? data.p_net_product_price : editProduct.p_net_product_price; //mrp
      data.p_tax = data.p_tax !== "" ? data.p_tax : editProduct.p_tax;
      data.p_expirydate = data.p_expirydate !== "" || data.p_expirydate !== undefined ? data.p_expirydate : editProduct.p_expirydate;
      data.p_productweight = data.p_productweight !== "" || data.p_productweight !== undefined ? data.p_productweight : editProduct.p_productweight;
      data.p_grossproductweight = data.p_grossproductweight !== "" || data.p_grossproductweight !== undefined ? data.p_grossproductweight : editProduct.p_grossproductweight;

      if (parseFloat(data.p_price) > parseFloat(data.p_net_product_price)) {
        //offer price > et price /mrp
        setSuccessMsg("Offer Price cannot be greater than Net Price/MRP ");
        setSmShow(false);
        handleVisible();
        return;
      }
    } else {
      data.p_id = uuid();
      data.createddate = new Date();
      data.createduserid = localStorage.getItem("uuid");
      data.createdby = localStorage.getItem("name");
      data.isactive = 1;
      data.p_updateddate = new Date();
      data.p_image = product_image;
      data.p_price = data.p_price === "" || data.p_price === undefined ? 0 : data.p_price; //offer price
      data.p_description = content;
      data.isfeatured = 0;
      console.log("test product new", data);
    }
    console.log("add products", data);
    fetch(config.daintyservice_url + methodname, { method: "POST", headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken"), accesslogin: "" }, body: JSON.stringify({ data }) })
      .then((response) => response.json())
      .then((data) => {
        console.log("regitered user", data);
        if (data.status === 200) {
          e.target.reset();
          reset({ p_name: "" });
          setEditProduct({});
          setSuccessMsg(data.message);
          setSmShow(false);
          handleVisible();
          // document.getElementById("p_category").value = "";
          // document.getElementById("p_subcategory").value = "";
          setp_maincategorydrp("");
          setp_categorydrp("");
          setp_Branddrp("");
          setp_subcategorydrp("");
          setProduct_image("");
          setTaxPercentage("");
          setActualPrice("");
          setNetProductPrice("");
          setFlag(true);
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
        }
      })
      .catch((err) => {
        setSuccessMsg("Something went wrong, Please try again later!!");
      });
  };

  const editData = (e, product) => {
    setFlag(false);
    setEditProduct({});
    document.getElementById("frmProductadd").reset();
    setShow((show) => !show);
    setEditProduct(product);
    setp_categorydrp(product.p_category);
    setp_subcategorydrp(product.p_subcategory);
    setActualPrice(product.p_actual_price);
    setNetProductPrice(product.p_net_product_price);
    setTaxPercentage(product.p_tax);
    setp_maincategorydrp(product.p_maincategory);
    setp_Branddrp(product.p_brand);
    setContent(product.p_description);
    console.log("descriptioneditor1state", content);
    console.log("descriptioneditor2", product.p_description);
  };
  const switchproduct = () => {
    setShow((show) => !show);
    setp_maincategorydrp("");
    setNetProductPrice("");
    setp_categorydrp("");
    setp_subcategorydrp("");
    setContent("");
  };
  const refresh = () => {
    window.location.reload(false);
  };
  const taxCalculation = (price, tax) => {
    // if (tax === 0) {
    //   setNetProductPrice(price);
    // } else {
    //   setNetProductPrice((parseFloat(price) + parseFloat((tax / 100.0) * price)).toFixed(2));
    // }
  };

  const { p_name, p_actual_price, p_price, p_quantity, p_description, moreimageurl, p_net_product_price, p_tax, p_returnaccepted, p_productweight, p_grossproductweight, p_expirydate, p_brand, p_itemcode } = Object.keys(editProduct).length > 0 ? editProduct : {};

  const columns = [
    {
      title: "Sno",
      field: "sno",
    },
    {
      title: "Product",
      field: "p_image",
    },
    {
      title: "Name",
      field: "p_name",
      // cellStyle: {
      //   whiteSpace: "nowrap",
      // },
    },
    {
      title: "Category",
      field: "p_category",
      // cellStyle: {
      //   whiteSpace: "nowrap",
      // },
    },
    {
      title: "MRP",
      field: "p_net_product_price",
    },

    {
      title: "OfferPrice",
      field: "p_price",
    },
    {
      title: "Tax%",
      field: "p_tax",
    },
    {
      title: "Quantity",
      field: "p_quantity",
    },
    {
      title: "Expiry",
      field: "p_expirydate",
      cellStyle: {
        whiteSpace: "nowrap",
      },
    },
    {
      title: "New Arrivals",
      field: "featured",
    },
    {
      title: "On Sale",
      field: "onsale",
    },
    {
      title: "Edit/Disalbe",
      field: "action",
    },
    {
      title: "Delete",
      field: "delete",
    },
  ];

  const productsdata =
    products &&
    products.length > 0 &&
    products.map((product, index) => ({
      sno: index + 1,
      p_image: (
        <>
          <img className="smallimage" src={product.p_image} height="15" alt="" />
        </>
      ),
      p_name: product.p_name,
      p_category: product.p_category,
      p_actual_price: product.p_actual_price,
      p_tax: product.p_tax,
      p_net_product_price: product.p_net_product_price,
      p_price: product.p_price,
      p_quantity: product.p_quantity,
      p_expirydate: product.p_expirydate,
      featured: (
        <>
          <Link to="#" onClick={(e) => isFeatured(product.p_id, product.isfeatured === 1 ? 0 : 1, "")}>
            {product.isfeatured === 0 || product.isfeatured == null ? <img src={off} alt="featured" className="iconwidth" /> : <img src={on} alt="activate" className="iconwidth" />}
          </Link>
        </>
      ),
      onsale: (
        <>
          <Link to="#" onClick={(e) => isFeatured(product.p_id, product.isonsale === 1 ? 0 : 1, "onsale")}>
            {product.isonsale === 0 || product.isonsale == null || product.isonsale === undefined ? <img src={off} alt="isonsale" className="iconwidth" /> : <img src={on} alt="activate" className="iconwidth" />}
          </Link>
        </>
      ),
      action: (
        <>
          {" "}
          <Link to="#" className="py-1" onClick={(e) => (setProduct_image(product.p_image), editData(e, product))}>
            <img src={edit} alt="edit" className="iconwidth" />
          </Link>{" "}
          <Link to="#" onClick={(e) => activateDeactivateProduct(product.p_id, product.isactive === 1 ? 0 : 1)}>
            {product.isactive === 0 ? <img src={off} alt="deactivate" className="iconwidth" /> : <img src={on} alt="activate" className="iconwidth" />}
          </Link>
        </>
      ),
      delete: (
        <>
          <Link to="#" className="" onClick={(e) => deleteProduct(product.p_id, product.p_image, product.moreimageurl)}>
            <img src={deleteicon} alt="delete" className="iconwidth" />
          </Link>
        </>
      ),
    }));

  {
    return access ? (
      <div>
        <Modal size="sm" className="z-index" show={smShow} onHide={() => setSmShow(false)}>
          <Modal.Header closeButton>
            <span className="text-dark"> {successMsg}</span>
          </Modal.Header>
        </Modal>
        <Modal size="lg" className="popup" show={smShowCategory} onHide={() => setSmShowCategory(false)}>
          <Modal.Header closeButton>
            <span className="text-dark">Add Product MainCategory/Category/Sub Category</span>
          </Modal.Header>
          <Modal.Body closeButton>
            <AdminCategory type="product" maincategory={true} maincategoryvalue={p_maincategorydrp} categoryvalue={p_categorydrp} />
          </Modal.Body>
        </Modal>
        <Modal size="lg" className="z-index" show={imgshow} onHide={() => setImgShow(false)}>
          <Modal.Header closeButton className="text-light bg-primary">
            Add images. (Max 5 images per product)
          </Modal.Header>
          <Modal.Body>
            {" "}
            {Object.keys(editProduct).length > 0 && (
              <div className="comment-form-author">
                <div className="row">
                  <div className="col">
                    <label className="text-nowrap">Upload Image (max.Size-300Kb)</label>
                    <input placeholder="Upload Product Image" disabled={editProduct?.moreimageurl?.length > config.imagecount ? true : false} name="p_image" onChange={onChange_image_more} accept="image/*" className="form-control" type="file" />
                  </div>
                  <div className="col">
                    <label>Image</label>
                    <div>
                      <img className="smallimage" src={product_image_more} />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex">
                    {editProduct.moreimageurl &&
                      editProduct.moreimageurl.length > 0 &&
                      editProduct.moreimageurl.map((url, index) => (
                        <div className="d-flex-column p-1">
                          <div>
                            <img className="smallimage" src={url} />
                          </div>
                          <div>
                            <a to="" onClick={(e) => deleteMoreImage(index)}>
                              Delete
                            </a>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <button disabled={smShow || product_image_more === "" ? true : false} className="btnadmin my-2" onClick={addMoreimage}>
                  Add image
                </button>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <p>Add Additional image to the product</p>
          </Modal.Footer>
        </Modal>

        <div id="review_form_wrapper">
          <div className={!show ? "" : "d-none"} id="review_form">
            <button className="btnadmin my-2" onClick={(e) => (switchproduct(), getProductDetails())}>
              View all Products
            </button>{" "}
            {Object.keys(editProduct).length > 0 && (
              <button className={"btnadmin my-2"} onClick={(e) => setImgShow((imgshow) => !imgshow)}>
                Add more image
              </button>
            )}{" "}
            <button className="btnadmin my-2" onClick={(e) => setSmShowCategory(true)}>
              Add Categories
            </button>
            <div id="respond" className="comment-respond">
              <h3 className="comment-reply-title" id="reply-title">
                Add a Product
              </h3>

              <form className="comment-form" onSubmit={handleSubmit(onSubmit)} id="frmProductadd">
                <div className="comment-form-author w-75">
                  <label>
                    Name <span className="required">*</span>
                  </label>
                  <input type="text" className="form-control" required aria-required="true" defaultValue={p_name} name="p_name" ref={register} id="p_name" />
                </div>
                <div className="comment-form-author w-75">
                  <div className="row">
                    <div className="col">
                      {" "}
                      <label>
                        MainCategory <span className="required">*</span>
                      </label>
                      <select value={p_maincategorydrp} className="form-select" id="p_maincategory" ref={register} name="p_maincategory" onChange={(e) => setp_maincategorydrp(e.target.value)}>
                        <option value={""}>{""}</option>
                        {masterMainCategory.length > 0 && masterMainCategory.map((cat) => <option value={cat.maincategory}>{cat.maincategory}</option>)}
                      </select>
                      {/* <Select name="p_maincategory_new" name="p_maincategory" className="form-select1" options={masterMainCategory} values={masterMainCategory} onChange={(e) => handleChange(e, "maincategory")} /> */}
                    </div>
                    <div className="col">
                      {" "}
                      <label>
                        Category <span className="required">*</span>
                      </label>
                      <select value={p_categorydrp} className="form-select" id="p_category" ref={register} name="p_category" onChange={(e) => setp_categorydrp(e.target.value)}>
                        <option value={""}>{""}</option>
                        {p_maincategorydrp && masterCategory.filter((cat1) => cat1.maincategory === p_maincategorydrp).map((cat) => <option value={cat.category}>{cat.category}</option>)}
                      </select>
                    </div>
                    <div className="col">
                      {" "}
                      <label>
                        Sub Category <span className="required"></span>
                      </label>
                      <select value={p_subcategorydrp} className="form-select" id="p_subcategory" ref={register} name="p_subcategory" onChange={(e) => setp_subcategorydrp(e.target.value)}>
                        <option value={""}>{""}</option>
                        {p_categorydrp && masterSubCategory.filter((cat1) => cat1.category === p_categorydrp).map((cat) => <option value={cat.subcategory}>{cat.subcategory}</option>)}
                        {/* {masterSubCategory.map((cat) => (
                          <option value={cat.subcategory}>{cat.subcategory}</option>
                        ))} */}
                      </select>
                    </div>
                  </div>
                </div>
                {Object.keys(editProduct).length > 0 && (
                  <div className="comment-form-author w-75">
                    <div className="row">
                      <div className="col">
                        <label className="text-nowrap">Upload Image (max.Size-300Kb)</label>
                        <input placeholder="Upload Product Image" name="p_image" onChange={onChange_image} accept="image/*" className="form-control" type="file" />
                      </div>
                      <div className="col">
                        <label>Image</label>
                        <img className="daintymediumimage" src={product_image} />
                      </div>
                    </div>
                  </div>
                )}
                <div className="comment-form-author w-75">
                  <div className="row">
                    <div className="col">
                      {" "}
                      <label>
                        Brand <span className="required"></span>
                      </label>
                      <select value={p_branddrp} className="form-select" id="p_brand" ref={register} name="p_brand" onChange={(e) => setp_Branddrp(e.target.value)}>
                        <option value={""}>{""}</option>
                        {masterBrand.map((cat) => (
                          <option value={cat.brand}>{cat.brand}</option>
                        ))}
                      </select>
                    </div>

                    <div className="col">
                      {" "}
                      <label className="text-nowrap">
                        Avaliable Quantity <span className="required">*</span>
                      </label>
                      <input type="number" min={0} className="form-control" defaultValue={p_quantity} required aria-required="true" size="30" name="p_quantity" ref={register} id="p_quantity" />
                    </div>
                    <div className="col">
                      {" "}
                      <label className="text-nowrap">Item Code</label>
                      <input type="text" className="form-control" defaultValue={p_itemcode} name="p_itemcode" ref={register} id="p_itemcode" />
                    </div>
                  </div>
                </div>

                <div className="comment-form-author w-75">
                  <div className="row">
                    <div className="col">
                      {" "}
                      <label className="text-nowrap">
                        Tax % <span></span>
                      </label>
                      {/* <input type="number" min={0} className="form-control" defaultValue={p_tax} size="30" name="p_tax" ref={register} id="p_tax" onChange={(e) => (setTaxPercentage(e.target.value), taxCalculation(actualPrice, e.target.value))} /> */}
                      <input type="number" min={0} className="form-control" defaultValue={p_tax} size="30" name="p_tax" ref={register} id="p_tax" />
                    </div>
                    <div className="col">
                      {" "}
                      <label>
                        Net Price/ MRP <span className="required">*</span>
                        <i className="small"> (with tax)</i>
                      </label>
                      {/* <input type="number" min={0} className="form-control" required aria-required="true" value={netProductPrice} onChange={(e) => setNetProductPrice(e.value)} defaultValue={p_net_product_price} size="30" name="p_net_product_price" ref={register} id="p_actual_price" /> */}
                      <input type="number" min={0} className="form-control" required aria-required="true" defaultValue={p_net_product_price} size="30" name="p_net_product_price" ref={register} id="p_net_product_price" />
                    </div>
                    <div className="col">
                      {" "}
                      <label>
                        Offer Price <i className="small">Optional</i>
                        <i className="small d-none"> (if Offer is 0, then no offer), </i>
                      </label>
                      <input type="number" min={0} className="form-control" defaultValue={p_price} size="30" name="p_price" ref={register} id="p_price" />
                    </div>
                  </div>
                </div>
                <div className="comment-form-author w-75">
                  <div className="row">
                    <div className="col">
                      <label>
                        Product Weight <i className="small"> (in gms) </i> <span className="required">*</span>
                      </label>
                      <input type="number" min={0} className="form-control" required defaultValue={p_productweight} name="p_productweight" ref={register} id="p_productweight" />
                    </div>
                    <div className="col">
                      <label>
                        Gross Weight <i className="small"> (in gms, used for shipping) </i> <span className="required"></span>
                      </label>
                      <input type="number" min={0} className="form-control" defaultValue={p_grossproductweight} name="p_grossproductweight" ref={register} id="p_grossproductweight" />
                    </div>
                    <div className="col">
                      <label className="text-nowrap">
                        Expiry Date <span></span>
                      </label>
                      <input type="date" className="form-control" defaultValue={p_expirydate} name="p_expirydate" ref={register} id="p_expirydate" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label className="text-nowrap"> Returns</label>
                      <input type="checkbox" defaultChecked={p_returnaccepted === "true" || p_returnaccepted === true ? true : false} className="form-check-input" id="p_returnaccepted" name="p_returnaccepted" ref={register} value="" />
                      <label className="form-check-label" for="p_returnaccepted">
                        Return Accepted
                      </label>
                    </div>
                  </div>
                </div>

                <div className="comment-form-comment w-100">
                  <label>
                    Product Description <span className="required">*</span>{" "}
                  </label>
                  <div className="row">
                    <div className="col-lg-12">
                      {/* <input type="textarea" aria-required="true" defaultValue={postcontent} rows="8" cols="45" required name="postcontent" {...register("postcontent")} id="postcontent" /> */}
                      <JoditEditor
                        ref={editor}
                        //defaultValue={p_description}
                        value={content}
                        config={config_}
                        tabIndex={2} // tabIndex of textarea
                        rows="8"
                        onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={(newContent) => {}}
                      />
                    </div>{" "}
                  </div>
                  {/* {errors.p_description && "Product Description is required"} */}
                </div>
                <div className="form-submit">
                  {Object.keys(editProduct).length === 0 && (
                    <button type="submit" disabled={smShow} className="btnadmin btnhover">
                      Add Product
                    </button>
                  )}
                  {Object.keys(editProduct).length > 0 && (
                    <button className="btnadmin btnhover" disabled={smShow} type="submit">
                      Update Product
                    </button>
                  )}{" "}
                  {/* <button className="btnadmin btnhover bggrey" onClick={(e) => refresh()}>
                    Cancel
                  </button> */}
                </div>
              </form>
            </div>
          </div>
          <div id="product" className={show ? "table check-tbl" : "d-none"}>
            <button
              className="btnadmin my-2"
              onClick={(e) => {
                setEditProduct({});
                setShow((show) => !show);
                setProduct_image("");
                setFlag(false);
              }}
            >
              Add Product ({products.length > 0 ? products.length : 0})
            </button>{" "}
            <hr />
            <div className="overflow-auto px-1 text-small  w-99">
              {/* <MDBDataTableV5 disableRetreatAfterSorting={true} entriesOptions={[5, 20, 25]} entries={30} pagesAmount={4} data={datatable} pagingTop={false} searchTop searchBottom={false} /> */}
              <small>
                <MaterialTable title="All Dainty Flavors Products" icons={tableIcons} data={productsdata.length > 0 ? productsdata : [{ sno: "Loading..." }]} columns={columns} options={{ search: true, paging: true, filtering: false, sorting: true, pageSize: 20, exportButton: true }} />
              </small>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div> </div>
    );
  }
};

export default AdminDaintyProduct;
