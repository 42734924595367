import React, { useState, useEffect, useRef } from "react";
import config from "../../config.json";
import { Link, useHistory } from "react-router-dom";
import uuid from "react-uuid";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import firebase from "./../../firebase";
import JoditEditor from "jodit-react";
import Moment from "moment";
import "./../../css/style.css";
import "./../../css/templete.css";
import "./../../css/skin/skin-1.css";

const AdminProfile = (props) => {
  const [successMsg, setSuccessMsg] = useState("");
  const [profile, setProfile] = useState([]);
  //const [networkError, setNetworkError] = useState("");
  const [smShow, setSmShow] = useState(false);
  const history = useHistory();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [access, setAccess] = useState(false);
  const [networkError, setNetworkError] = useState("");
  const handleVisible = () => {
    setSmShow(true);
    setTimeout(() => {
      setSmShow(false);
    }, 1000);
  };

  const changePassword = async (e) => {
    e.preventDefault();
    let _data = {};
    _data.oldPassword = oldPassword;
    _data.newPassword = newPassword;
    _data.uuid = localStorage.getItem("uuid");
    await fetch(config.service_url + "changeadminpassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({ data: _data }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          localStorage.clear();
          setSuccessMsg("Password updated sucessfullly.");
          handleVisible();
        } else if (data.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
          handleVisible();
        }
        console.log(data, "profile");
        console.log(profile, "profilestate");
      });
  };

  const getUserProfile = () => {
    fetch(config.service_url + "getadminprofile", { method: "POST", headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") }, body: JSON.stringify({ userid: localStorage.getItem("uuid") }) })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          // setSuccessMsg(data.message);
          setProfile(data.data);
          // handleVisible();
        } else if (data.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
          handleVisible();
        }
        console.log(data, "profile");
        console.log(profile, "profilestate");
      });
  };

  const checkaccess = async () => {
    let datas = {};
    datas.userid = localStorage.getItem("uuid");
    datas.page = "Profile";
    await fetch(config.service_url + "checkaccess", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ data: datas }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("access", data);
        if (data.status === 200) {
          setAccess(true);
        } else {
          history.push("/adminhome");
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
      });
  };

  useEffect(() => {
    checkaccess();
    if (access) {
      getUserProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: {}, mode: "blur" });
  const onSubmit = (userdetails, e) => {
    let methodname = "profile";
    setSuccessMsg("Please wait");
    setSmShow(true);
    e.preventDefault();
    if (localStorage.getItem("uuid") === undefined || localStorage.getItem("uuid") === null) {
      history.push("/");
    } else {
      userdetails.updateddate = new Date();

      //delete userdetails.password;
    }
    console.log("profile", JSON.stringify({ id: localStorage.getItem("uuid"), userdetails: userdetails }));
    fetch(config.service_url + `updateadminprofile`, { method: "POST", headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") }, body: JSON.stringify({ id: localStorage.getItem("uuid"), userdetails: userdetails }) })
      .then((response) => response.json())
      .then((data) => {
        console.log("regitered user", data);
        if (data.status === 200) {
          setSuccessMsg(data.message);
          handleVisible();
        } else if (data.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
        }
      })
      .catch((err) => {
        setSuccessMsg("Something went wrong, Please try again later!!");
      });
  };
  {
    return access ? (
      <div>
        <Modal size="sm" show={smShow} onHide={() => setSmShow(false)}>
          <Modal.Header closeButton>{successMsg}</Modal.Header>
        </Modal>
        <div className="content-block">
          <div className="container woo-entry">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  <div className="dlab-tabs product-description tabs-site-button m-t30">
                    <ul className="nav nav-tabs">
                      <li>
                        <Link className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-review">
                          Personal Info
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-address">
                          Manage Address
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-changepwd">
                          Change Password
                        </Link>
                      </li>
                    </ul>
                    {profile?.map((profile) => (
                      <div className="tab-content">
                        <div className="tab-pane " id="pills-address">
                          <div id="review_form_wrapper">
                            <form className="comment-form" onSubmit={handleSubmit(onSubmit)}>
                              <div id="review_form">
                                <div id="respond" className="comment-respond">
                                  <div className="comment-form-author">
                                    <label>
                                      Full Address <span className="required">*</span>
                                    </label>
                                    <input className="bg-white" type="text" aria-required="true" defaultValue={profile.address} required size="30" name="address" ref={register} id="author" />
                                  </div>
                                  <div className="comment-form-author">
                                    <label>
                                      City <span className="required">*</span>
                                    </label>
                                    <input type="text" className="bg-white" aria-required="true" defaultValue={profile.city} required size="30" name="city" ref={register} id="author" />
                                  </div>
                                  <div className="comment-form-author">
                                    <label>
                                      State<span className="required">*</span>
                                    </label>
                                    <input type="text" className="bg-white" aria-required="true" defaultValue={profile.state} required size="30" name="state" ref={register} id="author" />
                                  </div>
                                  <div className="comment-form-author">
                                    <label>
                                      Pincode<span className="required">*</span>
                                    </label>
                                    <input type="text" className="bg-white" aria-required="true" defaultValue={profile.pincode} required size="30" name="pincode" ref={register} id="author" />
                                  </div>

                                  <div className="form-submit">
                                    <button type="submit" className="btnadmin btn-secondary btnhover">
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>

                        <div className="tab-pane active" id="pills-review">
                          <div id="review_form_wrapper1">
                            <form className="comment-form" onSubmit={handleSubmit(onSubmit)}>
                              <div id="review_form1">
                                <div id="respond" className="comment-respond">
                                  <div className="comment-form-author">
                                    <label>
                                      Name <span className="required">*</span>
                                    </label>
                                    <input type="text" className="bg-white" aria-required="true" defaultValue={profile.name} required name="name" ref={register} id="author" />
                                  </div>
                                  <div className="comment-form-author">
                                    <label>
                                      Email <span className="required">*</span>
                                    </label>
                                    <input type="text" className="bg-white" aria-required="true" defaultValue={profile.email} required name="email" ref={register} id="author" />
                                  </div>
                                  <div className="comment-form-author">
                                    <label>
                                      Phone Number <span className="required">*</span>
                                    </label>
                                    <input type="number" aria-required="true" disabled={true} defaultValue={profile.phonenumber} required name="phonenumber" ref={register} id="author" />
                                  </div>

                                  <div className="form-submit">
                                    <button type="submit" className="btnadmin btn-secondary btnhover">
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="tab-pane " id="pills-changepwd">
                          <div id="review_form_wrapper2">
                            <div id="review_form2" className="comment-form">
                              <div id="respond" className="comment-respond">
                                <div className="comment-form-author">
                                  <label>
                                    Current Password <span className="required">*</span>
                                  </label>
                                  <input type="password" aria-required="true" size="30" name="password" required={false} id="password" onChange={(e) => setOldPassword(e.target.value)} />
                                </div>
                                <div className="comment-form-author">
                                  <label>
                                    New Password <span className="required">*</span>
                                  </label>
                                  <input type="password" aria-required="true" size="30" name="newpwd" required={false} id="newpwd" onChange={(e) => setNewPassword(e.target.value)} />
                                </div>
                                <div className="comment-form-author">
                                  <label>
                                    Confirm Password <span className="required">*</span>
                                  </label>
                                  <input type="password" aria-required="true" size="30" name="cnfpwd" required={false} id="cnfpwd" onChange={(e) => setConfirmPwd(e.target.value)} />
                                </div>

                                <div className="form-submit">
                                  <button type="button" className="btnadmin btn-secondary btnhover" onClick={(e) => changePassword(e)}>
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div> </div>
    );
  }
};

export default AdminProfile;
