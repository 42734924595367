import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useForm } from "react-hook-form";
import uuid from "react-uuid";
import config from "../../config.json";
const Register = ({ history }) => {
  const [message, setMessage] = useState("");
  const [valmessage, setValMessage] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const onSubmit = (data, e) => {
    e.preventDefault();
    if (data.password != data.cnfpassword) return setMessage("Password & Confirm Password doesnt match");

    let pages = {};
    if (data.chkblog) {
      pages.Blog = "/adminblog";
    }
    if (data.chkgallery) {
      pages.Gallery = "/admingallery";
    }
    if (data.chkorder) {
      pages.Order = "/adminorder";
    }
    if (data.chkproduct) {
      pages.Product = "/adminproduct";
    }
    if (data.chkuser) {
      pages.User = "/adminuser";
    }
    if (data.chkdashboard) {
      pages.Dashboard = "/admindashboard";
    }
    if (data.chkprofile) {
      pages.Profile = "/adminprofile";
    }
    if (data.chkrazorpay) {
      pages.Razorpay = "/adminrazorpay";
    }
    if (data.chktestimonial) {
      pages.Testimonial = "/admintestimonial";
    }
    if (data.chkpages) {
      pages.Pages = "/adminpages";
    }
    if (data.chkpodcast) {
      pages.Podcast = "/adminpodcast";
    }
    let datas = {
      userid: data.collection + "-" + uuid(),
      createddate: new Date(),
      isactive: 0,
      city: data.city,
      phonenumber: data.phonenumber,
      notes: data.notes,
      website: data.website,
      collection: data.collection,
      address: data.address,
      state: data.state,
      password: data.password,
      pincode: data.pincode,
      email: data.email,
      name: data.firstname + " " + data.lastname,
      loggedin: false,
    };
    console.log("registered user", datas);
    pages.userid = datas.userid;
    pages.website = data.website;
    pages.collection = data.collection;
    pages.name = data.firstname + " " + data.lastname;

    fetch(config.service_url + "adminregistration", { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify({ datas, pages }) })
      .then((response) => response.json())
      .then((data) => {
        console.log("regitered user", data);
        if (data.status === 200) {
          setMessage(data.message);
          setValMessage("");
          e.target.reset();
        } else {
          setValMessage(data.message);
          setMessage("");
        }
      })
      .catch((err) => {
        setValMessage("Something went wrong, Please try again later!!");
      });
  };

  return (
    <div>
      <div className="bg-white">
        <div className="section-full shop-account">
          <div className="row">
            <div className="col-lg-12">
              <div className="max-w900 m-auto radius-sm">
                <div className="">
                  <form id="login" className="tab-pane active" onSubmit={handleSubmit(onSubmit)}>
                    <h3 className="m-b5">Admin Register</h3>
                    <p>
                      If you have an account with us, please <Link to="/login">log in.</Link>
                    </p>
                    <div id="personalinfo">
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label>First Name *</label>
                          <input name="firstname" required className="form-control border" placeholder="First Name" type="text" ref={register} />
                        </div>
                        <div className="form-group col-md-6">
                          <label>Last Name</label>
                          <input name="lastname" className="form-control border " placeholder="Last Name" type="text" ref={register} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label>E-Mail</label>
                          <input name="email" placeholder="hello@example.com" className="form-control border" type="email" ref={register} />
                        </div>
                        <div className="form-group col-md-6">
                          {" "}
                          <label>Phone Number *</label>
                          <input name="phonenumber" type="text" required className="form-control border " placeholder="Enter Phone Number" ref={register} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label>Password *</label>
                          <input name="password" required className="form-control border" placeholder="Type Password" type="password" ref={register} />
                        </div>
                        <div className="form-group col-md-6">
                          <label>Confirm Password *</label>
                          <input name="cnfpassword" required className="form-control border" placeholder="Type Password" type="password" ref={register} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label>WebSite *</label>
                          <input name="website" required type="text" className="form-control border" placeholder="Website Name" ref={register} />
                        </div>
                        <div className="form-group col-md-6">
                          <label>Collection Name *</label>
                          <input name="collection" required type="text" className="form-control border" placeholder="Collection Name" ref={register} />
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Notes</label>
                        <input name="notes" type="text" className="form-control border" placeholder="Notes" ref={register} />
                      </div>
                      <div className="form-group">
                        <label>Address</label>
                        <input name="address" required type="text" className="form-control border" placeholder="Address" ref={register} />
                      </div>
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label>Town/City *</label>
                          <input name="city" required type="text" className="form-control border" placeholder="Town/City" ref={register} />
                        </div>
                        <div className="form-group col-md-6">
                          <label>State/Country *</label>
                          <input name="state" required type="text" className="form-control border" placeholder="State/Country" ref={register} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label>Postcode/Zip *</label>
                          <input name="pincode" required type="text" className="form-control border" placeholder="Pincode" ref={register} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group ">
                          <label>Page Access *</label>
                          <div className="form-check w-25">
                            <input type="checkbox" className="form-check-input" id="chkblog" name="chkblog" ref={register} />
                            <label className="form-check-label" for="chkblog">
                              Blog
                            </label>
                          </div>
                          <div className="form-check w-25">
                            <input type="checkbox" className="form-check-input" id="chkpodcast" name="chkpodcast" ref={register} />
                            <label className="form-check-label" for="chkpodcast">
                              Podcast
                            </label>
                          </div>
                          <div className="form-check w-25">
                            <input type="checkbox" className="form-check-input" id="chkproduct" name="chkproduct" ref={register} />
                            <label className="form-check-label" for="chkproduct">
                              Product
                            </label>
                          </div>
                          <div className="form-check w-25">
                            <input type="checkbox" className="form-check-input" id="chkorder" name="chkorder" ref={register} />
                            <label className="form-check-label" for="chkorder">
                              Order
                            </label>
                          </div>
                          <div className="form-check w-25">
                            <input type="checkbox" className="form-check-input" id="chkdashboard" name="chkdashboard" ref={register} />
                            <label className="form-check-label" for="chkdashboard">
                              Dashboard
                            </label>
                          </div>
                          <div className="form-check w-25">
                            <input type="checkbox" className="form-check-input" id="chkgallery" name="chkgallery" ref={register} />
                            <label className="form-check-label" for="chkgallery">
                              Gallery
                            </label>
                          </div>
                          <div className="form-check w-25">
                            <input type="checkbox" className="form-check-input" id="chkuser" name="chkuser" ref={register} />
                            <label className="form-check-label" for="chkuser">
                              Users
                            </label>
                          </div>
                          <div className="form-check w-25">
                            <input type="checkbox" className="form-check-input" id="chkprofile" name="chkprofile" ref={register} />
                            <label className="form-check-label" for="chkprofile">
                              Profile
                            </label>
                          </div>
                          <div className="form-check w-25">
                            <input type="checkbox" className="form-check-input" id="chktestimonial" name="chktestimonial" ref={register} />
                            <label className="form-check-label" for="chktestimonial">
                              Testimonial
                            </label>
                          </div>
                          <div className="form-check w-25">
                            <input type="checkbox" className="form-check-input" id="chkrazorpay" name="chkrazorpay" ref={register} />
                            <label className="form-check-label" for="chkrazorpay">
                              Razorpay
                            </label>
                          </div>
                          <div className="form-check w-25">
                            <input type="checkbox" className="form-check-input" id="chkpages" name="chkpages" ref={register} />
                            <label className="form-check-label" for="chkpages">
                              Pages
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className=" form-group  row">
                        <div className="form-group col-md-6">
                          <div className="text-success">{message}</div>
                          <div className="text-red">{valmessage}</div>
                          <input type="submit" className="btn btn-primary btnhover" value="Register" />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
