import React, { useState, useEffect, useRef } from "react";
import config from "../../config.json";
import { Link, useHistory } from "react-router-dom";
import uuid from "react-uuid";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import firebase from "./../../firebase";
import AdminPayment from "./AdminPayment";
import AdminClients from "./AdminClients";
import AdminIncomeExpense from "./AdminIncomeExpense";
import LoginRegister from "../Login/Register";
import JoditEditor from "jodit-react";
import Moment from "moment";
const AdminTUC = (props) => {
  const [gallery, setGallery] = useState([]);
  const [networkError, setNetworkError] = useState("");
  const [gallery_image, setGallery_image] = useState("");
  const history = useHistory();
  const [smShow, setSmShow] = useState(false);
  const [showp, setShowp] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [newsletterUsers, setNewsletterUsers] = useState("");
  const [accoutUsers, setAccoutUsers] = useState("");
  const [access, setAccess] = useState(false);

  const handleVisible = () => {
    setSmShow(true);
    setTimeout(() => {
      setSmShow(false);
    }, 1000);
  };

  const checkaccess = async () => {
    let datas = {};
    datas.userid = localStorage.getItem("uuid");
    datas.page = "AdminTUC";
    await fetch(config.service_url + "checkaccess", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ data: datas }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("access", data);
        if (data.status === 200) {
          setAccess(true);
        } else {
          history.push("/adminhome");
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
      });
  };

  useEffect(() => {
    checkaccess();
    if (access) {
      //getNewsletterUsers();
      //getAccoutUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access]);
  {
    return access ? (
      <div>
        <div className="content-block">
          <div className="container woo-entry">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  <div className="dlab-tabs product-description tabs-site-button m-t30">
                    <ul className="nav nav-tabs">
                      <li>
                        <Link className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-register">
                          Register
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-client">
                          Clients Details
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-incomeexpense">
                          Income/Expense
                        </Link>
                      </li>
                      <li>
                        <Link className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-payment">
                          Payment
                        </Link>
                      </li>
                    </ul>

                    <div className="tab-content">
                      <div className="tab-pane active" id="pills-register">
                        <LoginRegister />
                      </div>
                      <div className="tab-pane " id="pills-client">
                        <AdminClients />
                      </div>
                      <div className="tab-pane " id="pills-incomeexpense">
                        <AdminIncomeExpense />
                      </div>

                      <div className="tab-pane " id="pills-payment">
                        <AdminPayment />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div> Please wait or You dont have permission to view this page. </div>
    );
  }
};

export default AdminTUC;
