import React, { useEffect, useState } from "react";
import config from "../../../config.json";

import { getClosest, getSiblings, slideToggle, slideUp } from "../../../utils";
import { NavLink, useHistory } from "react-router-dom";
const MobileNavMenu = (props) => {
  let page = "AdminTUC";
  let urlname = window.location.href;
  let urlname1 = window.location.pathname;
  console.log("urlnames", urlname, urlname);
  const history = useHistory();
  const logout = () => {
    localStorage.clear();
    history.push("/login");
  };
  const [adminPages, setAdminPages] = useState([]);
  const [networkError, setNetworkError] = useState("");
  const onClickHandler = (e) => {
    const target = e.currentTarget;
    const parentEl = target.parentElement;
    if (parentEl?.classList.contains("menu-toggle") || target.classList.contains("menu-toggle")) {
      const element = target.classList.contains("icon") ? parentEl : target;
      const parent = getClosest(element, "li");
      const childNodes = parent.childNodes;
      const parentSiblings = getSiblings(parent);
      parentSiblings.forEach((sibling) => {
        const sibChildNodes = sibling.childNodes;
        sibChildNodes.forEach((child) => {
          if (child.nodeName === "UL") {
            slideUp(child, 1000);
          }
        });
      });
      childNodes.forEach((child) => {
        if (child.nodeName === "UL") {
          slideToggle(child, 1000);
        }
      });
    }
  };
  // const getMenuDetails = async () => {
  //   await fetch(config.service_url + "getadminpages", {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
  //     body: JSON.stringify({ data: { userid: localStorage.getItem("uuid") } }),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.status === 200) {
  //         localStorage.setItem("pages", JSON.stringify(data.data));
  //         console.log("menus", data.data);
  //       } else if (data?.status === 499) {
  //         history.push("/login");
  //       }
  //     })
  //     .catch((err) => {
  //       setNetworkError("Something went wrong, Please try again later!!");
  //       // console.log(networkError);
  //     });
  // };

  useEffect(() => {
    console.log("pages", JSON.parse(localStorage.getItem("pages")));
    if (localStorage.getItem("pages") === undefined) {
      // getMenuDetails();
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <nav className="site-mobile-menu">
      <ul>
        {localStorage.getItem("pages") !== undefined &&
          localStorage.getItem("pages") !== null &&
          Object.keys(JSON.parse(localStorage.getItem("pages")))
            ?.sort()
            .map(
              (key, index) =>
                JSON.parse(localStorage.getItem("pages"))[key].includes("/") && (
                  <li className={urlname.toLowerCase().includes(key.toLowerCase()) ? "bg-primary border-left px-1" : ""}>
                    <NavLink to={process.env.PUBLIC_URL + JSON.parse(localStorage.getItem("pages"))[key]}>
                      <span className="menu-text">{key}</span>
                    </NavLink>{" "}
                  </li>
                )
            )}
        {/* <NavLink to={process.env.PUBLIC_URL + "/adminhome"}>
            <span className="menu-text">Products</span>
          </NavLink>
          <NavLink to={process.env.PUBLIC_URL + "/adminhome"}>
            <span className="menu-text">Orders</span>
          </NavLink> */}

        {/* <ul className="sub-menu">
              <li>
                <NavLink to={process.env.PUBLIC_URL + "/home-one"}>
                  <span className="menu-text">Home One</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={process.env.PUBLIC_URL + "/home-two"}>
                  <span className="menu-text">Home Two</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={process.env.PUBLIC_URL + "/home-three"}>
                  <span className="menu-text">Home Three</span>
                </NavLink>
              </li>
            </ul> */}

        {/* <li>
          <NavLink to={process.env.PUBLIC_URL + "/adminhome"}>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={process.env.PUBLIC_URL + "/adminhome"}>
            <span className="menu-text">Gallery</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={process.env.PUBLIC_URL + "/adminhome"}>
            <span className="menu-text">Blog</span>
          </NavLink>
        </li> */}
        <li>
          {localStorage.getItem("uuid") === undefined || localStorage.getItem("uuid") === null ? (
            <NavLink to={process.env.PUBLIC_URL + "/login"}>
              <span className="menu-text">Log in</span>
            </NavLink>
          ) : (
            <NavLink to={process.env.PUBLIC_URL + "/login"} onClick={logout}>
              <span className="menu-text">Log out</span>
            </NavLink>
          )}
        </li>
        {/* <li className="has-children">
          <NavLink to={process.env.PUBLIC_URL + "/work"}>
            <span className="menu-text">Work</span>
          </NavLink>
          <span className="menu-toggle" onClick={onClickHandler}>
            <i className="far fa-angle-down"></i>
          </span>
          <ul className="sub-menu">
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/work"}>
                <span className="menu-text">Work</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={process.env.PUBLIC_URL + `/work-details/1`}>
                <span className="menu-text">Work Details</span>
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="has-children">
          <NavLink to={process.env.PUBLIC_URL + "/blog-grid"}>
            <span className="menu-text">Blog</span>
          </NavLink>
          <span className="menu-toggle" onClick={onClickHandler}>
            <i className="far fa-angle-down"></i>
          </span>
          <ul className="sub-menu">
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/blog-grid"}>
                <span className="menu-text">Blog Grid</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/blog-classic"}>
                <span className="menu-text">Blog Classic</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={process.env.PUBLIC_URL + `/blog-details/1`}>
                <span className="menu-text">Blog Details</span>
              </NavLink>
            </li>
          </ul>
        </li> */}
      </ul>
    </nav>
  );
};

export default MobileNavMenu;
