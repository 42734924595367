import { Fragment, useState, useEffect } from "react";
import Logo from "../../components/logo/Logo";
import NavBarAdmin from "../../components/NavBar/NavBarAdmin";
import HeaderSearch from "../../components/HeaderSearch/HeaderSearch";
import Btn from "../../components/Btn/Btn";
import MobileMenu from "../../components/NavBar/MobileMenu";
import SideMenu from "../../components/NavBar/SideMenu";
import MainSearch from "../../components/NavBar/MainSearch";
import logo from "../../assets/images/logo/logo.png";
import "./../../css/style.css";
import "./../../css/templete.css";
import "./../../css/skin/skin-1.css";

const Header = () => {
  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };
  const [searchbarShow, setSearchbarShow] = useState(false);
  const onSearchHandler = () => {
    setSearchbarShow((prev) => !prev);
  };
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {}, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <Fragment>
      <SideMenu show={true} onClose={onCanvasHandler} />
    </Fragment>
  );
};

export default Header;
