import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import Payment from "../container/ContactInformation/Payment";
import GoogleMap from "../container/Map/GoogleMap";
import ContactFromContainer from "../container/ContactFromContainer/ContactFromContainer";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import config from "../config.json";

const Contact = () => {
  return (
    <React.Fragment>
      <SEO title="Unique Creations || Payment" />
      <Header />
      <Breadcrumb image="images/bg/breadcrumb-bg-five.jpg" title="Payment" content="Home" contentTwo="Payment" />
      <Payment serviceurl={config.clientname === "daintyflavors.com" ? config.daintyservice_url : config.service_url} client={config.clientname} />
      {/* <GoogleMap /> */}

      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default Contact;
