import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from "../../components/SectionTitles/SectionTitleTwo";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const AboutFour = () => {
  const [didViewCountUp, setDidViewCountUp] = useState(false);
  const onVisibilityChange = (isVisible) => {
    if (isVisible) {
      setDidViewCountUp(true);
    }
  };
  const [scale] = useState(1.04);
  const sceneEl = useRef(null);
  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);
  return (
    <div className="section section-padding-top about-section-padding-bottom-200">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
            <div className="about-content-area mt-0 mb-md-10 mb-10">
              <SectionTitleTwo subTitle="Web design and digital marketing" title="We think strategy, UX design, and web development" />
              <p>We are an Agile software development team with over decade of experience in building dynamic web and mobile applications.We bring together top expertise in digital technologies, subject matter experts, user experience, web design and development to deliver the right solution for our customers. We bring the right management and development staff with strong skills and the correct philosophy so that your project is done the right way the first time. </p>
              <p>Unique Creation has developed several products and custom software for our customers using proven agile software development methodologies in an efficient manner. With a practical approach, we keep the focus on what really matters: collaboratively delivering high quality software that meets our customer needs.</p>
              <div className="row row-cols-sm-2 row-cols-auto mb-n6">
                <div className="col mb-6">
                  <div className="about-funfact">
                    <div className="number">
                      <VisibilitySensor onChange={onVisibilityChange} offset={{ top: 10 }} delayedCall>
                        <CountUp end={didViewCountUp ? 3 : 0} />
                      </VisibilitySensor>
                      +
                    </div>
                    <h6 className="text">Happy Clients</h6>
                    <p>We help our clients increase profits by increasing their visibility online.</p>
                  </div>
                </div>
                <div className="col mb-6">
                  <div className="about-funfact">
                    <div className="number">
                      <VisibilitySensor onChange={onVisibilityChange} offset={{ top: 10 }} delayedCall>
                        <CountUp end={didViewCountUp ? 2 : 0} />
                      </VisibilitySensor>
                      +
                    </div>
                    <h6 className="text">Completed projects</h6>
                    <p>We help our clients increase profits by increasing their visibility online.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
            <div className="about-image-area about-shape-animation right-0 mr-0">
              <div className="about-image js-tilt">
                <Tilt scale={scale} transitionSpeed={4000}>
                  <img src={process.env.PUBLIC_URL + "images/about/about-1.jpg"} alt="" />
                </Tilt>
              </div>
              <div className="about-image js-tilt">
                <Tilt scale={scale} transitionSpeed={4000}>
                  <img src={process.env.PUBLIC_URL + "images/about/about-2.jpg"} alt="" />
                </Tilt>
              </div>
              <div className="shape shape-1" id="scene" ref={sceneEl}>
                <span data-depth="1">
                  <img src={process.env.PUBLIC_URL + "images/shape-animation/video-shape-1.png"} alt="" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
