import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
const description_default = "The Unique Creations, service provider for Web Design and web Hosting in cloud. We create customised web and mobile applications.";
const keywords_default = "Software company in Perambalur, Software company in Trichy,theuniquecreations, uniquecreations,the unique creations, Unique Creations Perambalur, Mangoon, Thuraiyur, IT company in Perambalur";
const url = "https://www.theuniquecreations.com";
const image_default = "https://firebasestorage.googleapis.com/v0/b/tucfbclouddb.appspot.com/o/TUC%2Ftuclogonew.jpg?alt=media&token=609c8583-a993-4607-b166-8c3f2f7fe88b";
const sitename = "The Unique Creations,  Software Company in Trichy, Perambalur";
const favicon = "https://firebasestorage.googleapis.com/v0/b/tucfbclouddb.appspot.com/o/TUC%2FFAVCION.PNG?alt=media&token=b33fefc7-3a09-4a09-b031-c5bc93a7621f";
const SEO = ({ title, description, image_url, keywords, contenttitle }) => {
  console.log("image_url", image_url);
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      {/* <meta name="robots" content="noindex, follow" /> */}
      <link rel="icon" href={favicon} />
      <meta charset="utf-8" />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={sitename} />
      <meta name="keywords" content={keywords !== undefined ? keywords : keywords_default} />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <meta name="description" content={description !== undefined ? description : description_default} />
      <meta name="author" content="The Uniquie Creations" />
      <meta property="og:title" content={contenttitle !== undefined ? contenttitle : title} />
      <meta property="og:description" content={description !== undefined ? description : description_default} />
      <meta property="og:image" itemProp="image" content={image_url !== undefined ? image_url : image_default} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />

      <meta property="twitter:title" content={contenttitle !== undefined ? contenttitle : title} />
      <meta property="twitter:description" content={description !== undefined ? description : description_default} />
      <meta property="twitter:image" itemProp="image" content={image_url !== undefined ? image_url : image_default} />
      <meta property="og:image" content={image_url !== undefined ? image_url : image_default} />
      <meta property="twitter:card" content={description !== undefined ? description : description_default} />
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
};

export default SEO;
