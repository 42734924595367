import { React, useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Link, useHistory } from "react-router-dom";
import config from "../../config.json";
const Chart = (props) => {
  const history = useHistory();

  const [orderDetails, setorderDetails] = useState([]);
  const chartData = orderDetails;
  const countOrdered = [];
  const countCompleted = [];
  const Cancelled = [];

  chartData.forEach((data) => {
    if (data.orderstatus === "Ordered") {
      countOrdered.push(data.orderstatus);
    }
    if (data.orderstatus === "Completed") {
      countCompleted.push(data.orderstatus);
    }
    if (data.orderstatus === "Cancelled") {
      Cancelled.push(data.orderstatus);
    }
  });

  const data = {
    series: [
      {
        name: "Ordered",
        data: [countOrdered.length, countOrdered.length, countOrdered.length],
      },
      {
        name: "Completed",
        data: [countCompleted.length, countCompleted.length, countCompleted.length],
      },
      {
        name: "Cancelled",
        data: [Cancelled.length, Cancelled.length, Cancelled.length],
      },
    ],
    options: {
      colors: ["#FFDC00", "#00D100", "#FF5500"],

      chart: {
        toolbar: {
          show: false,
        },
      },

      plotOptions: {
        bar: {
          columnWidth: "30%",
          //barHeight: "10%",
          borderRadius: 4,
          dataLabels: {
            position: "bottom", // top, center, bottom
          },
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: true,
      },

      // colors: ['#34c38f'],
      grid: {
        borderColor: "#f1f1f1",
      },
      xaxis: {
        categories: ["2019", "2020", "2021"],
      },
    },
  };
  const getAdminOrderHistory = async (e) => {
    await fetch(props.serviceurl + "getAdminOrderHistory", { method: "POST", headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") }, body: JSON.stringify({ userid: localStorage.getItem("uuid") }) })
      .then((response) => response.json())
      .then((data) => {
        console.log("regitered user", data);
        if (data.status === 200) {
          // setSuccessMsg(data.message);
          setorderDetails(data.data);
          // handleVisible();
        } else if (data.status === 500) {
          //setSuccessMsg(data.message);
          //handleVisible();
        } else if (data.status === 499) {
          //history.push("/login");
        } else {
          //setSuccessMsg(data.message);
          //handleVisible();
          history.push("/login");
        }
      })
      .catch((err) => {
        //setSuccessMsg("Something went wrong, Please try again later!!");
        // alert(err);
        //handleVisible();
      });
  };
  useEffect(() => {
    getAdminOrderHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={props.clas}>
      <div className="white dash_title">
        <div className="d-flex">
          <div className="p-2 flex-grow-1">
            <b>{props.name}</b>
          </div>

          <div className="p-2">
            <select className="t4finput-dropdown">
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="">2025</option>
              <option value="">2026</option>
              <option value="">2027</option>
              <option value="">2028</option>
              <option value="">2029</option>
              <option value="">2030</option>
            </select>
          </div>
        </div>
      </div>
      <ReactApexChart options={data.options} series={data.series} type="bar" height="300" />
    </div>
  );
};

export default Chart;
