import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import config from "../../config.json";
import uuid from "react-uuid";
//import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import firebase from "./../../firebase";
import Moment from "moment";
import Chart from "./Chart";
import AdminManagePosts from "./AdminManagePosts";
import AdminCategory from "./AdminCategory";
import AdminManageGallery from "./AdminManageGallery";
import AdminViewUsers from "./AdminViewUsers";
import AdminOrder from "./AdminOrder";
import on from "./../../assets/images/icons/on.png";
import off from "./../../assets/images/icons/off.png";
import edit from "./../../assets/images/icons/edit.png";
import JoditEditor from "jodit-react";
import deleteicon from "./../../assets/images/icons/delete.png";
import "./../../css/style.css";
import "./../../css/templete.css";
import "./../../css/skin/skin-1.css";
const AdminClients = (props) => {
  //const [productDtl, setProductDtl] = useState({});
  //const [validationMsg, setValidationMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [product_image, setProduct_image] = useState("");
  const [product_image_more, setProduct_image_more] = useState("");
  const [smShow, setSmShow] = useState(false);
  const [show, setShow] = useState(true);
  const [imgshow, setImgShow] = useState(false);
  const [products, setProducts] = useState([]);
  const history = useHistory();
  const editor = useRef(null);
  const [orderDetails, setorderDetails] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [editProduct, setEditProduct] = useState({});
  const [networkError, setNetworkError] = useState("");

  const [flag, setFlag] = useState(false);
  const [access, setAccess] = useState(false);
  const [dueamount_current, setDueamount_current] = useState(0);
  const [billamount_current, setBillamount_current] = useState(0);
  const [payemntstatusdrp, setpayemntstatusdrp] = useState("");
  const config_ = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };
  const handleVisible = () => {
    setSmShow(true);
    setTimeout(() => {
      setSmShow(false);
    }, 1000);
  };

  const onChange_image = async (e) => {
    setSuccessMsg("Please wait");
    setSmShow(true);
    const file = e.target.files[0];
    console.log("file", e.target.files[0]);
    if (file.size < 300000) {
      console.log("add products", file);
      const path = localStorage.getItem("name") + "-" + localStorage.getItem("uuid") + "/products";
      const storageRef = firebase.storage().ref(path);
      const fileRef = storageRef.child(editProduct.p_id);
      await fileRef.put(file);
      setProduct_image(await fileRef.getDownloadURL());
      console.log("add products", product_image);
      setSmShow(false);
      setSuccessMsg("");
    } else {
      setSuccessMsg("Please upload file less than 300 kb");
    }
  };

  const getclientdetails = async () => {
    console.log("cakecategory", props);
    await fetch(config.service_url + "getclientdetails", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
    })
      .then((response) => response.json())
      .then((data) => {
        {
          setProducts(data.data);
          console.log(data, "clients");
        }
      })
      .catch((err) => {
        //setNetworkError("Something went wrong, Please try again later!!");
        console.log("product error", err);
      });
  };

  const updateclientdetails = (userid, id) => {
    console.log(id);
    fetch(config.service_url + "updateclientdetails", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ data: { userid: userid, isactive: id } }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          //console.log(JSON.stringify({ data: { p_id: pid, isactive: id } }));
          setSuccessMsg(data.message);
          handleVisible();
          getclientdetails();
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
          handleVisible();
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };

  const deleteFromFirebase = (url) => {
    console.log("File url : " + url);
    var fileRef = firebase.storage().refFromURL(url);
    // Delete the file using the delete() method
    fileRef
      .delete()
      .then(function () {
        // File deleted successfully
        console.log("File Deleted");
        setSuccessMsg("Deleted Sucessfully");
        handleVisible();
      })
      .catch(function (error) {
        console.log("File error", error);
      });

    // console.log("File in database after delete exists : " + fileRef?.exists());
  };

  useEffect(() => {
    getclientdetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag, access]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: {} });
  const onSubmit = (data, e) => {
    let methodname = "updateclientdetails";
    setSuccessMsg("Please wait");
    setSmShow(true);
    e.preventDefault();
    if (localStorage.getItem("uuid") === undefined || localStorage.getItem("uuid") === null) {
      history.push("/login");
    } else if (Object.keys(editProduct).length > 0) {
      data.userid = editProduct.userid;
      data.updateddate = new Date();
      methodname = "updateclientdetails";
    }
    console.log("add products", data);
    fetch(config.service_url + methodname, { method: "POST", headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken"), accesslogin: "" }, body: JSON.stringify({ data }) })
      .then((response) => response.json())
      .then((data) => {
        console.log("regitered user", data);
        if (data.status === 200) {
          e.target.reset();
          reset({ p_name: "" });
          setEditProduct({});
          setSuccessMsg(data.message);
          setSmShow(false);
          handleVisible();
          setShow((show) => !show);
          setFlag(true);
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
        }
      })
      .catch((err) => {
        setSuccessMsg("Something went wrong, Please try again later!!");
      });
  };

  const editData = (e, product) => {
    setFlag(false);
    setEditProduct({});
    document.getElementById("frmProductadd").reset();
    setShow((show) => !show);
    setEditProduct(product);
    setpayemntstatusdrp(product.paymentstatus);
    setBillamount_current(product.billamount);
    setDueamount_current(product.dueamount);
  };
  const CalculateDueAmount = (value) => {
    setDueamount_current(billamount_current - value);
  };
  const { name, website, email, phonenumber, collection, billamount, invoicedate, advanceamount, advancedate, dueamount, duedate, paymentstatus, notes, renewalamount, renewaldate } = Object.keys(editProduct).length > 0 ? editProduct : {};
  {
    return (
      <div>
        <Modal size="sm" className="z-index" show={smShow} onHide={() => setSmShow(false)}>
          <Modal.Header closeButton>
            <span className="text-dark"> {successMsg}</span>
          </Modal.Header>
        </Modal>

        <div id="review_form_wrapper">
          <div className={!show ? "" : "d-none"} id="review_form">
            <button className="btnadmin my-2" onClick={(e) => setShow((show) => !show)}>
              View all Clients ({products.length > 0 ? products.length : 0})
            </button>{" "}
            <div id="respond" className="comment-respond">
              <form className="comment-form" onSubmit={handleSubmit(onSubmit)} id="frmProductadd">
                <div className="comment-form-author">
                  <label>
                    Client Name :<span className="required">{name}</span>
                  </label>
                  <label>
                    Website :<span className="required">{website}</span>
                  </label>
                  <label>
                    Email :<span className="required">{email}</span>
                  </label>
                  <label>
                    Phonenumber :<span className="required">{phonenumber}</span>
                  </label>
                </div>
                <div className="comment-form-author">
                  <div className="row">
                    <div className="col">
                      <label>Bill/Invoice Amount</label>
                      <input type="number" min={0} defaultValue={billamount} name="billamount" ref={register} id="billamount" onChange={(e) => setBillamount_current(e.target.value)} />
                    </div>
                    <div className="col">
                      {" "}
                      <label className="text-nowrap">Invoice Date</label>
                      <input type="date" defaultValue={invoicedate} aria-required="true" name="invoicedate" ref={register} id="invoicedate" />
                      {/* {errors.p_quantity && "Available Quantity is required"} */}
                    </div>
                  </div>
                </div>
                <div className="comment-form-author">
                  <div className="row">
                    <div className="col">
                      {" "}
                      <label>Adv. Amount</label>
                      <input type="number" min={0} defaultValue={advanceamount} name="advanceamount" ref={register} id="advanceamount" onChange={(e) => CalculateDueAmount(e.target.value)} />
                    </div>

                    <div className="col">
                      {" "}
                      <label className="text-nowrap">
                        Adv. Received Date <span className="required">*</span>
                      </label>
                      <input type="date" defaultValue={advancedate} aria-required="true" name="advancedate" ref={register} id="advancedate" />
                      {/* {errors.p_quantity && "Available Quantity is required"} */}
                    </div>
                  </div>
                </div>
                <div className="comment-form-author">
                  <div className="row">
                    <div className="col">
                      {" "}
                      <label>Due Amount</label>
                      <input type="number" min={0} value={dueamount_current} defaultValue={dueamount} name="dueamount" ref={register} id="dueamount" />
                    </div>

                    <div className="col">
                      {" "}
                      <label className="text-nowrap">
                        Due Date <span className="required">*</span>
                      </label>
                      <input type="date" defaultValue={duedate} aria-required="true" size="30" name="duedate" ref={register} id="duedate" />
                      {/* {errors.p_quantity && "Available Quantity is required"} */}
                    </div>
                  </div>
                </div>
                <div className="comment-form-author">
                  <div className="row">
                    <div className="col">
                      {" "}
                      <label>Renewal Amount</label>
                      <input type="number" min={0} defaultValue={renewalamount} name="renewalamount" ref={register} id="renewalamount" />
                    </div>

                    <div className="col">
                      {" "}
                      <label className="text-nowrap">
                        Renewal Date <span className="required">*</span>
                      </label>
                      <input type="date" defaultValue={renewaldate} aria-required="true" size="30" name="renewaldate" ref={register} id="renewaldate" />
                      {/* {errors.p_quantity && "Available Quantity is required"} */}
                    </div>
                  </div>
                </div>
                <div className="comment-form-author">
                  <label>Notes</label>
                  <textarea type="textarea" aria-required="true" defaultValue={notes} rows="4" cols="45" required name="notes" ref={register} id="notes" />
                </div>
                <div className="comment-form-author">
                  <label>Payment Status</label>
                  <select value={payemntstatusdrp} className="form-select" id="paymentstatus" ref={register} name="paymentstatus" onChange={(e) => setpayemntstatusdrp(e.target.value)}>
                    <option value={""}>{""}</option>
                    <option value={"Advance Paid"}>{"Advance Paid"}</option>
                    <option value={"Pending"}>{"Pending"}</option>
                    <option value={"Completed"}>{"Completed"}</option>
                    <option value={"Cancelled"}>{"Cancelled"}</option>
                  </select>
                </div>
                <div className="form-submit">
                  {Object.keys(editProduct).length === 0 && (
                    <button type="submit" disabled={smShow} className="btnadmin btnhover">
                      Update
                    </button>
                  )}
                  {Object.keys(editProduct).length > 0 && (
                    <button className="btnadmin btnhover" disabled={smShow} type="submit">
                      Update
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          <div id="product" className={show ? "table check-tbl" : "d-none"}>
            <table className="table check-tbl">
              <thead>
                <tr>
                  <th>Client</th>

                  <th>Bill/Invoice</th>
                  <th>Due/Pending</th>
                  <th>Renewal</th>
                  <th>Payment</th>

                  <th>Account</th>
                </tr>
              </thead>
              <tbody>
                {products.length > 0
                  ? products.map((product, key) => (
                      <tr>
                        <td className="font-weight-normal">
                          Name: {product.name}
                          <br />
                          <p className="font-weight-light">
                            <small>Website: {product.website}</small>
                          </p>
                        </td>
                        <td className="font-weight-normal">
                          Amt: ₹ {product.billamount}
                          <br />
                          <p className="font-weight-light">
                            <small>Date: {product.invoicedate}</small>
                          </p>
                        </td>
                        <td className="font-weight-normal">
                          <span className={product.dueamount === "0" || product.dueamount === undefined ? "" : "text-danger"}> Amt: ₹ {product.dueamount}</span>
                          <br />
                          <p className="font-weight-light">
                            <small>Date: {product.duedate}</small>
                          </p>
                        </td>
                        <td className="font-weight-normal">
                          Amt: ₹ {product.renewalamount}
                          <br />
                          <p className="font-weight-light">
                            <small>Date: {product.renewaldate}</small>
                          </p>
                        </td>
                        <td className="font-weight-normal">{product.paymentstatus}</td>
                        <td>
                          <Link to="#" className="py-1" onClick={(e) => editData(e, product)}>
                            <img src={edit} alt="edit" className="iconwidth" />
                          </Link>{" "}
                          <Link to="#" onClick={(e) => updateclientdetails(product.userid, product.isactive === 1 ? 0 : 1)}>
                            {product.isactive === 0 ? <img src={off} alt="deactivate" className="iconwidth" /> : <img src={on} alt="activate" className="iconwidth" />}
                          </Link>
                        </td>
                      </tr>
                    ))
                  : "No Clients Detail found"}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
};

export default AdminClients;
