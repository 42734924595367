import React, { useEffect } from "react";
import SEO from "../components/SEO";
import SideHeader from "../partials/header/SideHeader";
import Breadcrumbadmin from "../container/Breadcrumb/Breadcrumbadmin";
import AdminHome from "../container/AdminHome/AdminHome";
import NavBarAdmin from "../components/NavBar/NavBarAdmin";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import MessengerCustomerChat from "react-messenger-customer-chat";
import config from "../config.json";
const AdminHomePage = () => {
  useEffect(() => {
    // console.log("mobile view", isMobile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <SEO title="Unique Creations || Login" />
      <div className="d-flex">
        <div>
          <SideHeader />
        </div>
        <div className="flex-grow-1">
          <div className="border-0 bg-dark section">
            <div className="header-inner ">
              <NavBarAdmin />
            </div>
          </div>
          <div className=" px-3">
            <AdminHome />
          </div>
          {/* <div id="fb-root"></div>
          <div id="fb-customer-chat" className="fb-customerchat"></div>
          <MessengerCustomerChat pageId={config.fbpageid} appId={config.fbappid} /> */}
        </div>
      </div>

      {/* <Breadcrumbadmin image="images/bg/breadcrumb-bg.jpg" title="" content="Home" contentTwo="Admin Home" /> */}

      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default AdminHomePage;
