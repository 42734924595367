import daintyfirebase from "firebase";
import "firebase/storage";

const configfb = {
  /////new//// Jan 3 2021 // qa
  apiKey: "AIzaSyDnWq4RI2y8WMurtZJgtodKNRrtAvjB__Q",
  authDomain: "daintyflavours-api.firebaseapp.com",
  projectId: "daintyflavours-api",
  storageBucket: "daintyflavours-api.appspot.com",
  messagingSenderId: "1035336748700",
  appId: "1:1035336748700:web:1969c4016a50295477a759",
};
daintyfirebase.initializeApp(configfb, "daintyfb");
export const storage = daintyfirebase.storage();
export default daintyfirebase;
