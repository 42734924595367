import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import config from "../../config.json";
import uuid from "react-uuid";
//import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import firebase from "./../../firebase";
import Moment from "moment";
import Chart from "./Chart";
import AdminManagePosts from "./AdminManagePosts";
import AdminManageGallery from "./AdminManageGallery";
import AdminViewUsers from "./AdminViewUsers";
import AdminOrder from "./AdminOrder";
import AdminProduct from "./AdminProduct";
import on from "./../../assets/images/icons/on.png";
import off from "./../../assets/images/icons/off.png";
import edit from "./../../assets/images/icons/edit.png";
import JoditEditor from "jodit-react";
import deleteicon from "./../../assets/images/icons/delete.png";
import "./../../css/style.css";
import "./../../css/templete.css";
import "./../../css/skin/skin-1.css";
import NavBarAdmin from "../../components/NavBar/NavBarAdmin";
import Slider from "./Slider";
const Admin = (props) => {
  //const [productDtl, setProductDtl] = useState({});
  //const [validationMsg, setValidationMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [product_image, setProduct_image] = useState("");
  const [smShow, setSmShow] = useState(false);
  const [show, setShow] = useState(false);
  const [products, setProducts] = useState([]);
  const history = useHistory();
  const editor = useRef(null);
  const [orderDetails, setorderDetails] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [editProduct, setEditProduct] = useState({});
  const [networkError, setNetworkError] = useState("");
  const [masterCategory, setMasterCategory] = useState([]);
  const [masterSubCategory, setMasterSubCategory] = useState([]);
  const [p_categorydrp, setp_categorydrp] = useState("");
  const [p_subcategorydrp, setp_subcategorydrp] = useState("");
  //const [dataUri, setDataUri] = useState("");
  const [showCamera, setShowCamera] = useState(false);
  const [ownerNotes, setownerNotes] = useState("");
  const [content, setContent] = useState("");
  const [flag, setFlag] = useState(false);
  const config_ = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };
  const homeimg = config.homeimage;
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (localStorage.getItem("uuid") === undefined || localStorage.getItem("uuid") === null) {
      history.push("/login");
    }
  }, [flag]);
  return (
    <div>
      <div className="container woo-entry">
        <div className="row ">
          <div className="col-lg-12">
            <div className="text-center p-5">
              <h2>Welcome to Unique Creations Admin Panel.</h2>
              <h4>Manage your website here.</h4>
            </div>
            <div className="text-center p-5">
              <Slider />
            </div>
            <div className="text-center p-5">
              <h6 className="font-weight-light">
                For support: Please contact us at <strong>info@theuniquecreations.com</strong> or{" "}
                <strong>
                  <a href="https://www.theuniquecreations.com/contact" target="_blank">
                    Contact us here
                  </a>
                </strong>
              </h6>
            </div>
          </div>
        </div>

        {/* <Owl category={productDtl.p_category} /> */}
        <div className="section-full related-products content-inner bg-gray-light">
          <div className="container">
            <div className="products-carousel"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
