import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import LoginRegister from "../container/Login/Register";
import Video from "../container/Video/Video";
import AboutFive from "../container/About/AboutFive";
import TestimonialContainer from "../container/Testimonial/TestimonialContainer";
import CallToActionTwo from "../container/CallToAction/CallToActionTwo";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";

const Register = () => {
  return (
    <React.Fragment>
      <SEO title="Unique Creations || Register" />
      <Header />
      <Breadcrumb image="images/bg/breadcrumb-bg.jpg" title="Register user" content="Home" contentTwo="Register" />
      <div className="text-center p-3">
        <h2 className="py-3 my-3">Please contact us to register.</h2>
      </div>

      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default Register;
