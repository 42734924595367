import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import ServiceIconBoxTwo from "../container/service/ServiceIconBoxTwo";
import ServiceSkill from "../container/service/ServiceSkill";
import AboutSix from "../container/About/AboutSix";
import FunfactTwo from "../container/Funfact/FunfactTwo";
import ContactInformationThree from "../container/ContactInformation/ContactInformationThree";
import Footer from "../container/Footer/Footer";
import Pricing from "../container/Pricing/Pricing";
import ScrollToTop from "../components/ScrollToTop.jsx";

const Service = () => {
  return (
    <React.Fragment>
      <SEO title="Unique Creations || Pricing" />
      <Header />
      <Breadcrumb image="images/bg/breadcrumb-bg-three.jpg" title="Our Pricing" content="Home" contentTwo="Pricing" />
      {/* <ServiceIconBoxTwo /> */}
      {/* <ServiceSkill /> */}
      {/* <AboutSix /> */}
      <Pricing />
      <FunfactTwo />
      <ContactInformationThree />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default Service;
