import firebase from "firebase";
import "firebase/storage";

const configfb = {
  /////new//// Jan 3 2021 // qa
   apiKey: "AIzaSyB4JcU0HlqeG9Z4L1PVuzHSbwu5IIsGQXE",
  authDomain: "tucfbclouddb.firebaseapp.com",
  databaseURL: "https://tucfbclouddb-default-rtdb.firebaseio.com",
  projectId: "tucfbclouddb",
  storageBucket: "tucfbclouddb.appspot.com",
  messagingSenderId: "630144356084",
  appId: "1:630144356084:web:cd09fed36cb2bcac2758a1",
  measurementId: "G-H3YBW1GSN8"
};
if (!firebase.apps.length) {
  firebase.initializeApp(configfb);
}
//firebase.initializeApp(configfb);
export const storage = firebase.storage();
export default firebase;
