import React, { useState, useEffect, useRef } from "react";
import config from "../../config.json";
import { Link, useHistory } from "react-router-dom";
import uuid from "react-uuid";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import firebase from "./../../firebase";
import JoditEditor from "jodit-react";
import on from "./../../assets/images/icons/on.png";
import off from "./../../assets/images/icons/off.png";
import edit from "./../../assets/images/icons/edit.png";
import deleteicon from "./../../assets/images/icons/delete.png";
import publish from "./../../assets/images/icons/publish.png";
import unpublish from "./../../assets/images/icons/unpublish.png";
import Moment from "moment";
const Testimonial = (props) => {
  const [posts, setPosts] = useState([]);
  const [editPost, setEditPost] = useState({});
  const [networkError, setNetworkError] = useState("");
  const [masterCategory, setMasterCategory] = useState([]);
  const [masterSubCategory, setMasterSubCategory] = useState([]);
  const [t_image, setPost_image] = useState("");
  const history = useHistory();
  const [p_categorydrp, setp_categorydrp] = useState("");
  const [p_subcategorydrp, setp_subcategorydrp] = useState("");
  const [smShow, setSmShow] = useState(false);
  const [showp, setShowp] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [t_ratingvalue, sett_rating] = useState("");
  const [allMasterCategory, setAllMasterCategory] = useState([]);
  const [allSubMasterCategory, setAllSubMasterCategory] = useState([]);
  const [postType, setPostType] = useState([]);
  const [flag, setFlag] = useState(false);
  const [access, setAccess] = useState(false);

  const config_ = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };
  // const postType = [
  //   { value: "Blog", label: "Blog" },
  //   { value: "Course", label: "Course" },
  // ];
  const handleVisible = () => {
    setSmShow(true);
    setTimeout(() => {
      setSmShow(false);
    }, 1000);
  };
  const getPostDetails = async () => {
    await fetch(props.serviceurl + "getadmintestimonial", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          let active = data.data
            //.filter((filter) => filter.isactive === "1")
            .map((data) => {
              return data;
            });
          setPosts(active);
        } else if (data?.status === 499) {
          history.push("/login");
        }
        console.log(data, "getadmintestimonial");
      })
      .catch((err) => {
        console.log(err, "errorgetadmintestimonial");
        //setNetworkError("Something went wrong, Please try again later!!");
        // console.log(networkError);
      });
  };
  const deletePost = (id, url) => {
    console.log(id);
    fetch(props.serviceurl + "deletetestimonial", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ id: id }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          console.log(JSON.stringify({ id: id }));
          if (url !== undefined && url !== null && url !== "") {
            deleteFromFirebase(url);
          }
          setSuccessMsg(data.message);
          handleVisible();
          getPostDetails();
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
          handleVisible();
        }
      })
      .catch((err) => {
        //setNetworkError("Something went wrong, Please try again later!!");
        //console.log(networkError);
      });
  };
  const deleteFromFirebase = (url) => {
    console.log("File url : " + url);
    var fileRef = firebase.storage().refFromURL(url);
    // Delete the file using the delete() method
    fileRef
      .delete()
      .then(function () {
        // File deleted successfully
        console.log("File Deleted");
        setSuccessMsg("Deleted Sucessfully");
        handleVisible();
      })
      .catch(function (error) {
        console.log("File error", error);
      });

    // console.log("File in database after delete exists : " + fileRef?.exists());
  };
  const activateDeactivatePost = (type, pid, id) => {
    console.log(id);
    let post = {};
    post.t_id = pid;
    post.updateddate = new Date();
    if (type === "activate") post.isactive = id;
    if (type === "publish") post.published = id;
    fetch(props.serviceurl + "updatetestimonial", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },

      body: JSON.stringify({ data: post }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          console.log(JSON.stringify({ data: post }));
          setSuccessMsg(data.message);
          handleVisible();
          getPostDetails();
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
          handleVisible();
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const onChange_Rating = (value) => {
    sett_rating(value);
  };
  const onChange_image_post = async (e, imagename) => {
    setSuccessMsg("Please wait");
    console.log("image name", imagename);
    setSmShow(true);
    const file = e.target.files[0];
    console.log("file", e.target.files[0]);
    if (file.size < 500000) {
      console.log("add products", file);
      const path = localStorage.getItem("name") + "-" + localStorage.getItem("uuid") + "/testimonial";
      const storageRef = firebase.storage().ref(path);
      const fileRef = storageRef.child(editPost.t_id);
      await fileRef.put(file);
      setPost_image(await fileRef.getDownloadURL());
      console.log("add products", t_image);
      setSmShow(false);
      setSuccessMsg("");
    } else {
      setSuccessMsg("Please upload file less than 500 kb");
    }
  };

  const checkaccess = async () => {
    let datas = {};
    datas.userid = localStorage.getItem("uuid");
    datas.page = props.page;
    console.log("pagename", datas.page);
    await fetch(config.service_url + "checkaccess", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ data: datas }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("access", data);
        if (data.status === 200) {
          setAccess(true);
        } else {
          history.push("/adminhome");
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
      });
  };
  useEffect(() => {
    checkaccess();
    if (access) {
      getPostDetails();
    }
    // console.log("mobile view", isMobile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag, access]);

  const editPostData = (e, post) => {
    setPost_image(post.t_image);
    setEditPost({});
    document.getElementById("frmPostadd").reset();
    setShowp((showp) => !showp);
    setEditPost(post);
    setContent(post.t_content);
    sett_rating(post.t_rating);
    setFlag(false);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: {}, mode: "blur" });
  const onSubmit = (data, e) => {
    let methodname = "addtestimonial";
    console.log("content", content);
    setSuccessMsg("Please wait");
    setSmShow(true);
    e.preventDefault();
    if (localStorage.getItem("uuid") === undefined || localStorage.getItem("uuid") === null) {
      history.push("/");
    } else if (Object.keys(editPost).length > 0) {
      data.t_id = editPost.t_id;
      methodname = "updatetestimonial";
      data.t_title = data.t_title !== "" ? data.t_title : editPost.t_title;
      data.t_description = data.t_description !== "" ? data.t_description : editPost.t_description;
      data.t_content = data.t_content !== "" ? content : editPost.t_content;
      data.t_image = t_image;
      data.t_slug = data.t_title.replace(/\s/g, "-").toLowerCase();
      data.updateddate = new Date();
    } else {
      data.t_id = uuid();
      data.createddate = new Date();
      data.createduserid = localStorage.getItem("uuid");
      data.createdby = localStorage.getItem("name");
      data.published = 0;
      data.t_slug = data.t_title.replace(/\s/g, "-").toLowerCase();
      data.isactive = 1;
      data.displaydate = Moment().format("LL");
      data.updateddate = new Date();
      data.t_image = t_image;
      data.t_content = content;
    }
    console.log("add post", data);
    fetch(props.serviceurl + methodname, { method: "POST", headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") }, body: JSON.stringify({ data }) })
      .then((response) => response.json())
      .then((data) => {
        console.log("regitered user", data);
        if (data.status === 200) {
          e.target.reset();
          reset({ posttitle: "" });
          setEditPost({});
          setSuccessMsg(data.message);
          setSmShow(false);
          handleVisible();
          setp_categorydrp("");
          setp_subcategorydrp("");
          setPost_image("");
          sett_rating("");
          getPostDetails();
          setFlag(true);
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
        }
      })
      .catch((err) => {
        setSuccessMsg("Something went wrong, Please try again later!!");
      });
  };
  const { t_id, t_title, t_description, t_content, t_rating } = Object.keys(editPost).length > 0 ? editPost : {};
  {
    return access ? (
      <div>
        <Modal size="sm" className="popup" show={smShow} onHide={() => setSmShow(false)}>
          <Modal.Header closeButton>{successMsg}</Modal.Header>
        </Modal>
        <div id="review_form_wrapper">
          <div className={!showp ? "" : "d-none"} id="review_form">
            <button className="btnadmin my-2" onClick={(e) => setShowp((showp) => !showp)}>
              View Testimonial ({posts.length > 0 ? posts.length : 0})
            </button>
            <div id="respond" className="comment-respond">
              <h3 className="comment-reply-title" id="reply-title">
                Add Testimonial
              </h3>

              <form className="comment-form" onSubmit={handleSubmit(onSubmit)} id="frmPostadd">
                <div className="comment-form-author">
                  <label>
                    Title/Client Name <span className="required">*</span>
                  </label>
                  <input type="textarea" className="form-control" defaultValue={t_title} required name="t_title" ref={register} id="t_title" />
                  {/* {errors.p_name && "Product name is required"} */}
                </div>
                <div className="comment-form-author">
                  <label>
                    Description <span className="required">*</span>
                  </label>
                  <input type="textbox" className="form-control" defaultValue={t_description} required name="t_description" ref={register} id="t_description" />
                </div>

                {Object.keys(editPost).length > 0 && (
                  <div className="comment-form-author">
                    <div className="row">
                      <div className="col">
                        <label>Image (max. size 500 kb)</label>
                        <input placeholder="Upload Product Image" name="t_image" onChange={onChange_image_post} accept="image/*" className="form-control" type="file" />
                        {/* <input placeholder="Upload Product Image" name="p_image" onChange={onChange_image} type="file" {...register("p_image")} id="p_image" /> */}
                      </div>
                      <div className="col">
                        <label>Image</label>
                        <img className="smallimage" src={t_image} />
                      </div>
                    </div>
                  </div>
                )}
                <div className="comment-form-author">
                  <label>Rating</label>
                  <select value={t_ratingvalue} className="form-control" id="t_rating" ref={register} name="t_rating" onChange={(e) => onChange_Rating(e.target.value)}>
                    <option value=""></option>
                    <option value="1">1</option>
                    <option value="1.5">1.5</option>
                    <option value="2">2</option>
                    <option value="2.5">2.5</option>
                    <option value="3">3</option>
                    <option value="3.5">3.5</option>
                    <option value="4">4</option>
                    <option value="4.5">4.5</option>
                    <option value="5">5</option>
                  </select>{" "}
                </div>

                <div className="comment-form-comment w-100">
                  <label>
                    Testimonial Content <span className="required">*</span>{" "}
                  </label>
                  <div className="row">
                    <div className="col-lg-12">
                      {/* <input type="textarea" aria-required="true" defaultValue={postcontent} rows="8" cols="45" required name="postcontent" {...register("postcontent")} id="postcontent" /> */}
                      <JoditEditor
                        ref={editor}
                        value={content}
                        config={config_}
                        tabIndex={2} // tabIndex of textarea
                        rows="8"
                        onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={(newContent) => {}}
                      />
                    </div>{" "}
                  </div>
                  {/* {errors.p_description && "Product Description is required"} */}
                </div>

                <div className="form-submit">
                  {Object.keys(editPost).length === 0 && (
                    <button type="submit" className="btnadmin btnhover">
                      Save Testimonial
                    </button>
                  )}
                  {Object.keys(editPost).length > 0 && (
                    <button className="btnadmin btnhover" type="submit">
                      Update Testimonial
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          <div id="post" className={showp ? "table check-tbl" : "d-none"}>
            <button
              className="btnadmin my-2"
              onClick={(e) => {
                setEditPost({});
                setShowp((showp) => !showp);
                setPost_image("");
                setFlag(false);
              }}
            >
              Add Testimonial
            </button>
            <table className="table check-tbl">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Title/Client Name</th>
                  <th>Create Date</th>
                  <th>Edit</th>
                  <th>Publish</th>
                  <th>Disable</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {posts.length > 0
                  ? posts.map((post, key) => (
                      <tr>
                        <td className="product-item-img">
                          <img className="smallimage" src={post.t_image} height="15" alt="" />
                        </td>
                        <td className="product-item-name font-weight-normal">{post.t_title}</td>

                        <td className="product-item-price font-weight-normal">{Moment(post.createddate).format("DD-MMM-YYYY")}</td>

                        <td className="text-nowrap">
                          <Link to="#" className="py-1" onClick={(e) => editPostData(e, post)}>
                            <img src={edit} alt="edit" className="iconwidth" />
                          </Link>{" "}
                        </td>
                        <td>
                          {" "}
                          <Link to="#" onClick={(e) => activateDeactivatePost("publish", post.t_id, post.published === 1 ? 0 : 1)}>
                            {/* {post.published === 0 ? "Publish" : "UnPublish"} */}
                            {post.published === 1 ? <img src={publish} alt="publish" className="iconwidth" /> : <img src={unpublish} alt="unpublish" className="iconwidth" />}
                          </Link>{" "}
                        </td>
                        <td>
                          {" "}
                          <Link to="#" onClick={(e) => activateDeactivatePost("activate", post.t_id, post.isactive === 1 ? 0 : 1)}>
                            {post.isactive !== 1 ? <img src={off} alt="deactivate" className="iconwidth" /> : <img src={on} alt="activate" className="iconwidth" />}
                          </Link>
                        </td>
                        <td>
                          <Link to="#" onClick={(e) => deletePost(post.t_id, post.t_image)}>
                            <img src={deleteicon} alt="delete" className="iconwidth" />
                          </Link>
                        </td>
                      </tr>
                    ))
                  : "No Testimonial added"}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    ) : (
      <div></div>
    );
  }
};

export default Testimonial;
