import React, { useState, useEffect, useRef } from "react";
import config from "../../config.json";
import { Link, useHistory } from "react-router-dom";
import Gallery from "./Gallery";
const AdminDaintyGallery = (props) => {
  {
    return (
      <div>
        <Gallery serviceurl={config.daintyservice_url} client="daintyflavors.com" page="Gallery" />
      </div>
    );
  }
};

export default AdminDaintyGallery;
