import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import config from "../../config.json";
import uuid from "react-uuid";
//import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import firebase from "./../../firebase";
import Moment from "moment";
import Chart from "./Chart";
import AdminManagePosts from "./AdminManagePosts";
import AdminCategory from "./AdminCategory";
import AdminManageGallery from "./AdminManageGallery";
import AdminViewUsers from "./AdminViewUsers";
import AdminOrder from "./AdminOrder";
import on from "./../../assets/images/icons/on.png";
import off from "./../../assets/images/icons/off.png";
import edit from "./../../assets/images/icons/edit.png";
import JoditEditor from "jodit-react";
import deleteicon from "./../../assets/images/icons/delete.png";
import "./../../css/style.css";
import "./../../css/templete.css";
import "./../../css/skin/skin-1.css";
const Admin = (props) => {
  //const [productDtl, setProductDtl] = useState({});
  //const [validationMsg, setValidationMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [product_image, setProduct_image] = useState("");
  const [product_image_more, setProduct_image_more] = useState("");
  const [smShow, setSmShow] = useState(false);
  const [show, setShow] = useState(false);
  const [imgshow, setImgShow] = useState(false);
  const [products, setProducts] = useState([]);
  const history = useHistory();
  const editor = useRef(null);
  const [orderDetails, setorderDetails] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [editProduct, setEditProduct] = useState({});
  const [networkError, setNetworkError] = useState("");
  const [masterCategory, setMasterCategory] = useState([]);
  const [masterSubCategory, setMasterSubCategory] = useState([]);
  const [p_categorydrp, setp_categorydrp] = useState("");
  const [p_subcategorydrp, setp_subcategorydrp] = useState("");
  const [smShowCategory, setSmShowCategory] = useState(false);
  //const [dataUri, setDataUri] = useState("");
  const [showCamera, setShowCamera] = useState(false);
  const [ownerNotes, setownerNotes] = useState("");
  const [content, setContent] = useState("");
  const [flag, setFlag] = useState(false);
  const [access, setAccess] = useState(false);
  const config_ = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };
  const handleVisible = () => {
    setSmShow(true);
    setTimeout(() => {
      setSmShow(false);
    }, 1000);
  };

  const onChange_image = async (e) => {
    setSuccessMsg("Please wait");
    setSmShow(true);
    const file = e.target.files[0];
    console.log("file", e.target.files[0]);
    if (file.size < 300000) {
      console.log("add products", file);
      const path = localStorage.getItem("name") + "-" + localStorage.getItem("uuid") + "/products";
      const storageRef = firebase.storage().ref(path);
      const fileRef = storageRef.child(editProduct.p_id);
      await fileRef.put(file);
      setProduct_image(await fileRef.getDownloadURL());
      console.log("add products", product_image);
      setSmShow(false);
      setSuccessMsg("");
    } else {
      setSuccessMsg("Please upload file less than 300 kb");
    }
  };
  const onChange_image_more = async (e) => {
    setSuccessMsg("Please wait");
    setSmShow(true);
    const file = e.target.files[0];
    console.log("file", e.target.files[0]);
    if (file.size < 500000) {
      console.log("add products", file);
      const path = localStorage.getItem("name") + "-" + localStorage.getItem("uuid") + "/products";
      const storageRef = firebase.storage().ref(path);
      const fileRef = storageRef.child(uuid() + "more");
      await fileRef.put(file);
      setProduct_image_more(await fileRef.getDownloadURL());
      console.log("add products", product_image_more);
      setSmShow(false);
      setSuccessMsg("");
    } else {
      setSuccessMsg("Please upload file less than 500 kb");
    }
  };

  const getProductDetails = async () => {
    console.log("cakecategory", props);
    await fetch(config.service_url + "getadminproducts", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
    })
      .then((response) => response.json())
      .then((data) => {
        {
          let active = data
            //.filter((filter) => filter.isactive === "1")
            .map((data) => {
              return data;
            });
          setProducts(active);
          console.log(data, "products");
        }
      })
      .catch((err) => {
        //setNetworkError("Something went wrong, Please try again later!!");
        console.log("product error", err);
      });
  };
  const deleteProduct = (id, url, moreimages) => {
    console.log(id);
    fetch(config.service_url + "deleteProduct", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ Id: id }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          console.log(JSON.stringify({ Id: id }));
          setSuccessMsg(data.message);
          handleVisible();
          getProductDetails();
          console.log("mainurl", url);

          moreimages?.map((url) => {
            console.log("moreimageurl", url);
            deleteFromFirebase(url);
          });

          if (url !== undefined && url !== null && url !== "") {
            deleteFromFirebase(url);
          }
        } else if (data.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
          handleVisible();
        }
      })
      .catch((err) => {
        //setNetworkError("Something went wrong, Please try again later!!");
        //console.log(networkError);
      });
  };
  const activateDeactivateProduct = (pid, id) => {
    console.log(id);
    fetch(config.service_url + "updateproduct", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ data: { p_id: pid, isactive: id } }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          //console.log(JSON.stringify({ data: { p_id: pid, isactive: id } }));
          setSuccessMsg(data.message);
          handleVisible();
          getProductDetails();
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
          handleVisible();
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const isFeatured = (pid, id) => {
    console.log(id);
    fetch(config.service_url + "updateproduct", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ data: { p_id: pid, isfeatured: id } }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          //console.log(JSON.stringify({ data: { p_id: pid, isactive: id } }));
          setSuccessMsg(data.message);
          handleVisible();
          getProductDetails();
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
          handleVisible();
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const addMoreimage = () => {
    let url = [{ url: product_image_more }];
    let _allUrls = [];
    console.log("addmoreurl", product_image_more);
    console.log("addmoredb", moreimageurl);
    //console.log("check length", editProduct.moreimageurl.length);

    if (editProduct.moreimageurl && editProduct?.moreimageurl?.length > config.imagecount) {
      return;
    }
    // if (moreimageurl !== undefined) {
    // _allUrls = moreimageurl.push(product_image_more);
    moreimageurl?.map((url) => {
      return _allUrls.push(url);
    });
    _allUrls.push(product_image_more);
    editProduct.moreimageurl = _allUrls;
    // moreimageurl.push(_allUrls);
    // _allUrls = [...moreimageurl, product_image_more];
    // _allUrls.push(product_image_more);
    console.log("addmoredbpushext", _allUrls);
    // } else {
    //   _allUrls.push(product_image_more);
    //   console.log("addmoredbpushnew", _allUrls);
    // }
    console.log("addmoredbpush", _allUrls);
    // add this to exisitng image url array in the table.
    // add delete fucntion to delte each image from the array.
    fetch(config.service_url + "updateproduct", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ data: { p_id: editProduct.p_id, moreimageurl: _allUrls } }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setSuccessMsg(data.message);
          setProduct_image_more("");
          let findProductIndex = products.findIndex((prod) => prod.p_id === editProduct.p_id); //finding index of the item
          products[findProductIndex].moreimageurl = _allUrls;
          handleVisible();
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
          handleVisible();
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const deleteMoreImage = (deleteIndex) => {
    let localImage = [];

    let deleteUrl = editProduct.moreimageurl.map((durl, index) => {
      if (deleteIndex === index) {
        console.log("durl", durl);
        deleteFromFirebase(durl);
      }
    });

    editProduct.moreimageurl.map((url, index) => {
      if (index !== deleteIndex) {
        localImage.push(url);
      }
      return localImage;
    });
    editProduct.moreimageurl = localImage;

    fetch(config.service_url + "updateproduct", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ data: { p_id: editProduct.p_id, moreimageurl: localImage } }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setSuccessMsg(data.message);
          setProduct_image_more("");
          handleVisible();
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
          handleVisible();
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };

  const deleteFromFirebase = (url) => {
    console.log("File url : " + url);
    var fileRef = firebase.storage().refFromURL(url);
    // Delete the file using the delete() method
    fileRef
      .delete()
      .then(function () {
        // File deleted successfully
        console.log("File Deleted");
        setSuccessMsg("Deleted Sucessfully");
        handleVisible();
      })
      .catch(function (error) {
        console.log("File error", error);
      });

    // console.log("File in database after delete exists : " + fileRef?.exists());
  };

  const getCategories = async () => {
    await fetch(config.service_url + "getcategory", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          console.log("master category", data);
          let _filterData = data.data.filter((_d) => _d.type === "product");
          setMasterCategory(_filterData);
        } else if (data.status === 400) {
          console.log("No Data");
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const getSubCategories = async () => {
    await fetch(config.service_url + "getsubcategory", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          let _filterData = data.data.filter((_d) => _d.type === "product");
          console.log("master sub category", data);
          setMasterSubCategory(_filterData);
        } else if (data.status === 400) {
          console.log("No Data");
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const checkaccess = async () => {
    let datas = {};
    datas.userid = localStorage.getItem("uuid");
    datas.page = "Product";
    await fetch(config.service_url + "checkaccess", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ data: datas }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("access", data);
        if (data.status === 200) {
          setAccess(true);
        } else {
          history.push("/adminhome");
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
      });
  };
  useEffect(() => {
    checkaccess();
    // if (access) {
    getCategories();
    getSubCategories();
    getProductDetails();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag, access]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: {} });
  const onSubmit = (data, e) => {
    let methodname = "products";
    setSuccessMsg("Please wait");
    setSmShow(true);
    e.preventDefault();
    if (localStorage.getItem("uuid") === undefined || localStorage.getItem("uuid") === null) {
      history.push("/login");
    } else if (Object.keys(editProduct).length > 0) {
      data.p_id = editProduct.p_id;
      // data.createddate = new Date();
      // data.createduserid = localStorage.getItem("uuid");
      // data.createdby = localStorage.getItem("name");
      console.log("update before", data);
      data.isactive = "1";
      data.p_updateddate = new Date();
      methodname = "updateproduct";
      data.p_name = data.p_name !== "" ? data.p_name : editProduct.p_name;
      data.p_category = data.p_category !== "" ? data.p_category : editProduct.p_category;
      data.p_subcategory = data.p_subcategory !== "" ? data.p_subcategory : editProduct.p_subcategory;
      data.p_quantity = data.p_quantity !== "" ? data.p_quantity : editProduct.p_quantity;
      data.p_description = data.p_description !== "" ? content : editProduct.p_description;
      if (data.p_price === "" || data.p_price <= 0 || data.p_price === "0") {
        if (editProduct.p_price === "" || editProduct.p_price <= 0 || editProduct.p_price === "0") {
          data.p_price = data.p_actual_price;
        } else {
          data.p_price = editProduct.p_price;
        }
      }

      data.p_image = product_image;
      data.p_actual_price = data.p_actual_price !== "" ? data.p_actual_price : editProduct.p_actual_price;
    } else {
      data.p_id = uuid();
      data.createddate = new Date();
      data.createduserid = localStorage.getItem("uuid");
      data.createdby = localStorage.getItem("name");
      //data.p_category = p_categorydrp;
      //data.p_subcategory = p_subcategorydrp;
      data.isactive = "1";
      data.p_updateddate = new Date();
      data.p_image = product_image;
      data.p_price = data.p_price === "" || data.p_price <= 0 ? data.p_actual_price : data.p_price;
      data.p_description = content;
    }
    console.log("add products", data);
    fetch(config.service_url + methodname, { method: "POST", headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken"), accesslogin: "" }, body: JSON.stringify({ data }) })
      .then((response) => response.json())
      .then((data) => {
        console.log("regitered user", data);
        if (data.status === 200) {
          e.target.reset();
          reset({ p_name: "" });
          setEditProduct({});
          setSuccessMsg(data.message);
          setSmShow(false);
          handleVisible();
          // document.getElementById("p_category").value = "";
          // document.getElementById("p_subcategory").value = "";
          setp_categorydrp("");
          setp_subcategorydrp("");
          setProduct_image("");
          setFlag(true);
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
        }
      })
      .catch((err) => {
        setSuccessMsg("Something went wrong, Please try again later!!");
      });
  };

  const editData = (e, product) => {
    setFlag(false);
    setEditProduct({});
    document.getElementById("frmProductadd").reset();
    setShow((show) => !show);
    setEditProduct(product);
    setp_categorydrp(product.p_category);
    setp_subcategorydrp(product.p_subcategory);
  };

  const { p_name, p_actual_price, p_price, p_quantity, p_description, moreimageurl } = Object.keys(editProduct).length > 0 ? editProduct : {};
  {
    return access ? (
      <div>
        <Modal size="sm" className="z-index" show={smShow} onHide={() => setSmShow(false)}>
          <Modal.Header closeButton>
            <span className="text-dark"> {successMsg}</span>
          </Modal.Header>
        </Modal>
        <Modal size="md" className="popup" show={smShowCategory} onHide={() => setSmShowCategory(false)}>
          <Modal.Header closeButton>
            <span className="text-dark">Add Product Category/Sub Category</span>
          </Modal.Header>
          <Modal.Body closeButton>
            <AdminCategory type="product" />
          </Modal.Body>
        </Modal>
        <Modal size="lg" className="z-index" show={imgshow} onHide={() => setImgShow(false)}>
          <Modal.Header closeButton className="text-light bg-primary">
            Add images. (Max 5 images per product)
          </Modal.Header>
          <Modal.Body>
            {" "}
            {Object.keys(editProduct).length > 0 && (
              <div className="comment-form-author">
                <div className="row">
                  <div className="col">
                    <label className="text-nowrap">Upload Image (max.Size-300Kb)</label>
                    <input placeholder="Upload Product Image" disabled={editProduct?.moreimageurl?.length > config.imagecount ? true : false} name="p_image" onChange={onChange_image_more} accept="image/*" className="form-control" type="file" />

                    {/* <input placeholder="Upload Product Image" name="p_image" onChange={onChange_image} type="file" {...register("p_image")} id="p_image" /> */}
                  </div>
                  <div className="col">
                    <label>Image</label>
                    <div>
                      <img className="smallimage" src={product_image_more} />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex">
                    {editProduct.moreimageurl &&
                      editProduct.moreimageurl.length > 0 &&
                      editProduct.moreimageurl.map((url, index) => (
                        <div className="d-flex-column p-1">
                          <div>
                            <img className="smallimage" src={url} />
                          </div>
                          <div>
                            <a to="" onClick={(e) => deleteMoreImage(index)}>
                              Delete
                            </a>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <button disabled={smShow || product_image_more === "" ? true : false} className="btnadmin my-2" onClick={addMoreimage}>
                  Add image
                </button>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <p>Add Additional image to the product</p>
          </Modal.Footer>
        </Modal>

        <div id="review_form_wrapper">
          <div className={!show ? "" : "d-none"} id="review_form">
            <button className="btnadmin my-2" onClick={(e) => setShow((show) => !show)}>
              View all Products ({products.length > 0 ? products.length : 0})
            </button>{" "}
            {Object.keys(editProduct).length > 0 && (
              <button className={"btnadmin my-2"} onClick={(e) => setImgShow((imgshow) => !imgshow)}>
                Add more image
              </button>
            )}
            <div id="respond" className="comment-respond">
              <h3 className="comment-reply-title" id="reply-title">
                Add a Product
              </h3>

              <form className="comment-form" onSubmit={handleSubmit(onSubmit)} id="frmProductadd">
                <div className="comment-form-author">
                  <label>
                    Name <span className="required">*</span>
                  </label>
                  <input type="text" className="form-control" required aria-required="true" defaultValue={p_name} size="30" name="p_name" ref={register} id="p_name" />
                  {/* {errors.p_name && "Product name is required"} */}
                </div>
                <div className="comment-form-author">
                  <div className="row">
                    <div className="col">
                      {" "}
                      <label>
                        Category <span className="required">*</span>
                      </label>
                      <select value={p_categorydrp} className="form-control" id="p_category" ref={register} name="p_category" onChange={(e) => setp_categorydrp(e.target.value)}>
                        <option value={""}>{""}</option>
                        {masterCategory.map((cat) => (
                          <option value={cat.category}>{cat.category}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col">
                      {" "}
                      <label>
                        Sub Category <span className="required">*</span>
                      </label>
                      <select value={p_subcategorydrp} className="form-control" id="p_subcategory" ref={register} name="p_subcategory" onChange={(e) => setp_subcategorydrp(e.target.value)}>
                        <option value={""}>{""}</option>
                        {masterSubCategory.map((cat) => (
                          <option value={cat.subcategory}>{cat.subcategory}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col">
                      <label>Add</label>
                      <Link to="#" onClick={(e) => setSmShowCategory(true)}>
                        +
                      </Link>
                    </div>
                  </div>
                </div>
                {Object.keys(editProduct).length > 0 && (
                  <div className="comment-form-author">
                    <div className="row">
                      <div className="col">
                        <label className="text-nowrap">Upload Image (max.Size-300Kb)</label>
                        <input placeholder="Upload Product Image" name="p_image" onChange={onChange_image} accept="image/*" className="form-control" type="file" />
                        {/* <input placeholder="Upload Product Image" name="p_image" onChange={onChange_image} type="file" {...register("p_image")} id="p_image" /> */}
                      </div>
                      <div className="col">
                        <label>Image</label>
                        <img className="smallimage" src={product_image} />
                      </div>
                    </div>
                  </div>
                )}
                <div className="comment-form-author">
                  <div className="row">
                    <div className="col">
                      {" "}
                      <label>
                        Actual Price <span className="required">*</span>
                      </label>
                      <input type="number" min={0} required aria-required="true" defaultValue={p_actual_price} size="30" name="p_actual_price" ref={register} id="p_actual_price" />
                      {/* {errors.p_price && "Price is required"} */}
                    </div>
                    <div className="col">
                      {" "}
                      <label className="text-nowrap">
                        Price / Offer Price <span></span>
                      </label>
                      <input type="number" min={0} defaultValue={p_price} size="30" name="p_price" ref={register} id="p_price" />
                      {/* {errors.p_price && "Price is required"} */}
                    </div>
                    <div className="col">
                      {" "}
                      <label className="text-nowrap">
                        Avaliable Quantity <span className="required">*</span>
                      </label>
                      <input type="text" defaultValue={p_quantity} required aria-required="true" size="30" name="p_quantity" ref={register} id="p_quantity" />
                      {/* {errors.p_quantity && "Available Quantity is required"} */}
                    </div>
                  </div>
                </div>

                <div className="col d-none">
                  <label>
                    Product Description <span className="required">*</span>{" "}
                  </label>
                  {/* <textarea type="textarea" aria-required="true" defaultValue={p_description} rows="8" cols="45" required name="p_description" ref={register} id="p_description" /> */}
                  {/* {errors.p_description && "Product Description is required"} */}
                </div>
                <div className="comment-form-comment w-100">
                  <label>
                    Product Description <span className="required">*</span>{" "}
                  </label>
                  <div className="row">
                    <div className="col-lg-12">
                      {/* <input type="textarea" aria-required="true" defaultValue={postcontent} rows="8" cols="45" required name="postcontent" {...register("postcontent")} id="postcontent" /> */}
                      <JoditEditor
                        ref={editor}
                        value={p_description}
                        config={config_}
                        tabIndex={2} // tabIndex of textarea
                        rows="8"
                        onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={(newContent) => {}}
                      />
                    </div>{" "}
                  </div>
                  {/* {errors.p_description && "Product Description is required"} */}
                </div>
                <div className="form-submit">
                  {Object.keys(editProduct).length === 0 && (
                    <button type="submit" disabled={smShow} className="btnadmin btnhover">
                      Add Product
                    </button>
                  )}
                  {Object.keys(editProduct).length > 0 && (
                    <button className="btnadmin btnhover" disabled={smShow} type="submit">
                      Update Product
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          <div id="product" className={show ? "table check-tbl" : "d-none"}>
            <button
              className="btnadmin my-2"
              onClick={(e) => {
                setEditProduct({});
                setShow((show) => !show);
                setProduct_image("");
                setFlag(false);
              }}
            >
              Add Product
            </button>
            <table className="table check-tbl">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Product name</th>
                  <th>Category</th>
                  <th>Actual Price</th>
                  <th>Offer Price</th>
                  <th>Quantity</th>
                  <th>Action</th>
                  <th className={config.featureduser === localStorage.getItem("uuid") ? "" : "d-none"}>Featured</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {products.length > 0
                  ? products.map((product, key) => (
                      <tr>
                        <td className="product-item-img">
                          <img className="smallimage" src={product.p_image} height="15" alt="" />
                        </td>
                        <td className="product-item-name font-weight-normal">{product.p_name}</td>
                        <td className="product-item-name font-weight-normal">{product.p_category}</td>
                        <td className="product-item-price font-weight-normal">{product.p_actual_price}</td>
                        <td className="product-item-price font-weight-normal">{product.p_price}</td>
                        <td className="product-item-quantity font-weight-normal">{product.p_quantity}</td>
                        <td className="d-none">{product.moreimageurl}</td>
                        <td>
                          <Link to="#" className="py-1" onClick={(e) => (setProduct_image(product.p_image), editData(e, product))}>
                            <img src={edit} alt="edit" className="iconwidth" />
                          </Link>{" "}
                          <Link to="#" onClick={(e) => activateDeactivateProduct(product.p_id, product.isactive === "1" ? "0" : "1")}>
                            {product.isactive === "0" ? <img src={off} alt="deactivate" className="iconwidth" /> : <img src={on} alt="activate" className="iconwidth" />}
                          </Link>
                        </td>
                        <td className={config.featureduser === localStorage.getItem("uuid") ? "" : "d-none"}>
                          <Link to="#" onClick={(e) => isFeatured(product.p_id, product.isfeatured === 1 ? 0 : 1)}>
                            {product.isfeatured === 0 || product.isfeatured == null ? <img src={off} alt="featured" className="iconwidth" /> : <img src={on} alt="activate" className="iconwidth" />}
                          </Link>
                        </td>
                        <td className="">
                          <Link to="#" className="" onClick={(e) => deleteProduct(product.p_id, product.p_image, product.moreimageurl)}>
                            <img src={deleteicon} alt="delete" className="iconwidth" />
                          </Link>
                        </td>
                      </tr>
                    ))
                  : "No Product added"}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    ) : (
      <div> </div>
    );
  }
};

export default Admin;
