import React, { useState, useEffect, useRef } from "react";
import config from "../../config.json";
import { Link, useHistory } from "react-router-dom";
import uuid from "react-uuid";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import firebase from "./../../firebase";
import JoditEditor from "jodit-react";
import Moment from "moment";
import on from "./../../assets/images/icons/on.png";
import off from "./../../assets/images/icons/off.png";
import edit from "./../../assets/images/icons/edit.png";
import deleteicon from "./../../assets/images/icons/delete.png";
import copy from "./../../assets/images/icons/copy.png";
import show from "./../../assets/images/icons/show.png";
import hide from "./../../assets/images/icons/hide.png";
const AdminPostCategory = (props) => {
  const [cat, setCategory] = useState([]);
  const [subcat, setSubCat] = useState([]);
  const [maincat, setMainCategory] = useState([]);
  const [thumbnail_image, setThumbnail_image] = useState("");
  const [banner_image, setBanner_image] = useState("");
  const [networkError, setNetworkError] = useState("");
  const [gallery_image, setGallery_image] = useState("");
  const history = useHistory();
  const [smShow, setSmShow] = useState(false);
  const [showp, setShowp] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [access, setAccess] = useState(false);
  const [catTypeValue, setcatTypeValue] = useState("");
  const [catName, setcatName] = useState("");
  const handleVisible = () => {
    setSmShow(true);
    setTimeout(() => {
      setSmShow(false);
    }, 1000);
  };
  const getMainCategoryDetails = async () => {
    await fetch(config.service_url + "getmaincategory", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          let _filterData_main_category = data.data.filter((_d) => _d.type === props.type);
          setMainCategory(_filterData_main_category);
        } else if (data.status === 400) {
          setSuccessMsg("No Data");
          handleVisible();
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const getCategoryDetails = async () => {
    await fetch(config.service_url + "getcategory", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          let _filterData = data.data.filter((_d) => _d.type === props.type);
          setCategory(_filterData);
        } else if (data.status === 400) {
          setSuccessMsg("No Data");
          handleVisible();
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const getSubCategories = async () => {
    await fetch(config.service_url + "getsubcategory", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          let _filterData = data.data.filter((_d) => _d.type === props.type);
          console.log(_filterData);
          console.log(data.data);
          setSubCat(_filterData);
        } else if (data.status === 400) {
          setSuccessMsg("No Data");
          handleVisible();
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const deletecat = (id) => {
    console.log("catid", id);
    fetch(config.service_url + "deletecat", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ Id: id }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(JSON.stringify({ Id: id }));
        getCategoryDetails();
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        //console.log(networkError);
      });
  };
  const deletesubcat = (id) => {
    console.log(id);
    //deleteFromFirebase(url); need to fix it
    fetch(config.service_url + "deletesubcat", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ Id: id }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(JSON.stringify({ Id: id }));
        getSubCategories();
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        //console.log(networkError);
      });
  };

  const checkaccess = async () => {
    let datas = {};
    datas.userid = localStorage.getItem("uuid");
    datas.page = "Gallery";
    await fetch(config.service_url + "checkaccess", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ data: datas }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("access", data);
        if (data.status === 200) {
          setAccess(true);
        } else {
          history.push("/adminhome");
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
      });
  };

  const onChange_PostType = (value) => {
    setcatTypeValue(value);
  };

  useEffect(() => {
    checkaccess();
    if (access) {
      getMainCategoryDetails();
      getCategoryDetails();
      getSubCategories();
      console.log("maincat", props.maincategoryvalue, "cat", props.categoryvalue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access]);
  const updategallery = (type, pid, id) => {
    console.log(id);
    let post = {};
    post.gallery_id = pid;
    post.updateddate = new Date();
    post.viewingallery = id;
    fetch(config.service_url + "updategallery", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },

      body: JSON.stringify({ data: post }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(JSON.stringify({ data: post }));
        setSuccessMsg(data.message);
        handleVisible();
        getCategoryDetails();
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: {}, mode: "blur" });
  const onSubmit = (data, e) => {
    let methodname;
    setSuccessMsg("Please wait");
    setSmShow(true);
    e.preventDefault();
    if (localStorage.getItem("uuid") === undefined || localStorage.getItem("uuid") === null) {
      history.push("/");
    } else {
      const cattype = catTypeValue;
      data.id = props.type + "_" + uuid();
      data.createddate = new Date();
      data.isactive = 1;
      data.type = props.type;
      if (catTypeValue === "category") {
        methodname = "addcategory";
        data.category = catName;
      } else if (catTypeValue === "subcategory") {
        methodname = "addsubcategory";
        console.log("addsubcategory");
        data.subcategory = catName;
      } else {
        setSuccessMsg("Please select Type");
        handleVisible();
        setSmShow(false);
        return;
      }
    }
    console.log(data);
    fetch(config.service_url + methodname, { method: "POST", headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") }, body: JSON.stringify({ data }) })
      .then((response) => response.json())
      .then((data) => {
        console.log("regitered user", data);
        if (data.status === 200) {
          e.target.reset();
          setSuccessMsg(data.message);
          setSmShow(false);
          handleVisible();
          getCategoryDetails();
          getSubCategories();
        } else {
          setSuccessMsg(data.message);
        }
      })
      .catch((err) => {
        setSuccessMsg("Something went wrong, Please try again later!!");
      });
  };

  {
    return access ? (
      <div>
        <Modal size="sm" show={smShow} onHide={() => setSmShow(false)}>
          <Modal.Header closeButton>{successMsg}</Modal.Header>
        </Modal>
        <div id="review_form_wrapper">
          <div className={!showp ? "" : "d-none"} id="review_form">
            <button className="btnadmin my-2" onClick={(e) => setShowp((showp) => !showp)}>
              View all Category ({cat.length > 0 ? cat.length : 0})/Subcategory ({subcat.length > 0 ? subcat.length : 0})
            </button>
            <div id="respond" className="comment-respond">
              <h3 className="comment-reply-title" id="reply-title">
                Add Category/Subcategory
              </h3>

              <form className="comment-form" onSubmit={handleSubmit(onSubmit)} id="frmPostadd">
                <div className="comment-form-author">
                  <label>
                    Type <span className="required">*</span>
                  </label>
                  <select className="form-select" id={catTypeValue} name={catTypeValue} required onChange={(e) => onChange_PostType(e.target.value)}>
                    <option value={""}>{""}</option>
                    {props?.maincategory ? <option value="maincategory">Main Category</option> : ""}
                    <option value="category">Category</option>
                    <option value="subcategory">Sub Category</option>
                  </select>
                  {/* {errors.p_name && "Product name is required"} */}
                </div>
                <div className="comment-form-author">
                  <label>
                    Category <span className="required">*</span>
                  </label>
                  <input type="text" required aria-required="true" size="30" name="category" id="category" onChange={(e) => setcatName(e.target.value)} />
                  {/* {errors.p_name && "Product name is required"} */}
                </div>

                <div className="form-submit">
                  <div>{successMsg}</div>
                  <button type="submit" className="btnadmin btnhover">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div id="product" className={showp ? "table check-tbl" : "d-none"}>
            <button
              className="btnadmin my-2"
              onClick={(e) => {
                setShowp((showp) => !showp);
                setGallery_image("");
              }}
            >
              Add
            </button>

            <table className="table check-tbl">
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Type</th>

                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {cat.length > 0
                  ? cat.map((post, key) => (
                      <tr>
                        <td className="product-item-name font-weight-normal">{post.category}</td>
                        <td className="product-item-name font-weight-normal">{post.type}</td>

                        <td className="product-item-price font-weight-normal text-no-wrap d-none">{Moment(post.createddate).format("DD-MMM-YYYY")}</td>

                        <td>
                          <Link to="#" onClick={(e) => deletecat(post.id)}>
                            <img src={deleteicon} alt="delete" className="iconwidth" />
                          </Link>
                        </td>
                      </tr>
                    ))
                  : "No Category added"}
              </tbody>
            </table>
            <table className="table check-tbl">
              <thead>
                <tr>
                  <th>Sub Category</th>
                  <th>Type</th>

                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {subcat.length > 0
                  ? subcat.map((post, key) => (
                      <tr>
                        <td className="product-item-name font-weight-normal">{post.subcategory}</td>
                        <td className="product-item-name font-weight-normal">{post.type}</td>

                        <td className="product-item-price font-weight-normal text-no-wrap d-none">{Moment(post.createddate).format("DD-MMM-YYYY")}</td>

                        <td>
                          <Link to="#" onClick={(e) => deletesubcat(post.id)}>
                            <img src={deleteicon} alt="delete" className="iconwidth" />
                          </Link>
                        </td>
                      </tr>
                    ))
                  : "No Sub Category added"}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    ) : (
      <div></div>
    );
  }
};

export default AdminPostCategory;
