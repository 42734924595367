import React from "react";
import PropTypes from "prop-types";
import Logo from "../../components/logo/Logo";
import SideNavMenu from "./sub-component/SideNavMenu";
import { NavLink, useHistory } from "react-router-dom";
import logo from "../../assets/images/logo/logo.png";

const SideMenu = ({ props, show, onClose }) => {
  console.log("side admin menu active", props?.active);
  const history = useHistory();
  const logout = () => {
    localStorage.clear();
    history.push("/login");
  };
  return (
    <div id="site-main-mobile-menu" className={`site-main-mobile-menu-admin ${show ? "active" : ""}`}>
      <div className="site-main-mobile-menu-inner w-auto">
        <div className="mobile-menu-header-admin">
          <div className="mobile-menu-logo text-light">
            <Logo image={logo} />
          </div>
          <div className="mobile-menu-close d-none">
            <button className="toggle" onClick={onClose}>
              <i className="icon-top"></i>
              <i className="icon-bottom"></i>
            </button>
          </div>
        </div>
        <div className="mobile-menu-content">
          <SideNavMenu />
        </div>
      </div>
    </div>
  );
};

SideMenu.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SideMenu;
