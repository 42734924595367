import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import contactData from "../../data/contactInfo/contactInfo.json";
import SectionTitle from "../../components/SectionTitles/SectionTitle";
import ContactInfoItem from "../../components/ContactInfo/ContactInfoItem.jsx";
import Parallax from "parallax-js";
import config from "../../config.json";

const ContactInformation = ({ classOption }) => {
  const sceneEl = useRef(null);
  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);

  const Razorpay = () => {
    return (
      <div>
        <div className="razorpay-embed-btn" data-url="https://pages.razorpay.com/pl_I9yaspCgHRtdhx/view" data-text="Pay Now" data-color="#011D57" data-size="large"></div>
      </div>
    );
  };

  return (
    <div className={`section section-padding-t90-b100 ${classOption}`}>
      {config.paymentactive ? (
        <div className="container shape-animate">
          <SectionTitle titleOption="section-title text-center mb-lg-12 mb-sm-8 mb-xs-8" title={config.paymentname} subTitle={config.paymentdesc} />
          <div className="p-3 text-center">
            <div className="p-3"></div>
            <a className="btn btn-primary" href={config.paymenturl}>
              Pay Here
            </a>
          </div>{" "}
          <div className="shape shape-1" id="scene" ref={sceneEl}>
            <span data-dept h="1">
              <img src={process.env.PUBLIC_URL + "images/shape-animation/video-shape-1.png"} alt="shape" />
            </span>
          </div>
        </div>
      ) : (
        <div className="container shape-animate">
          <SectionTitle titleOption="section-title text-center mb-lg-6 mb-sm-8 mb-xs-8" title="Unique Creations Payment Gateway" subTitle="" />
          <div className="p-3 text-center">
            <div className="p-3"></div>

            <div className="p-2">
              <a className="btn btn-primary" href="https://rzp.io/l/uniquecreations">
                Pay Now
              </a>
            </div>
            <a className="" href="/contact">
              Contact us
            </a>
          </div>{" "}
          <div className="shape shape-1" id="scene" ref={sceneEl}>
            <span data-dept h="1">
              <img src={process.env.PUBLIC_URL + "images/shape-animation/video-shape-1.png"} alt="shape" />
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

ContactInformation.propTypes = {
  classOption: PropTypes.string,
};
ContactInformation.defaultProps = {
  classOption: "section section-padding-t90-b100",
};

export default ContactInformation;
