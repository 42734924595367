import React from "react";
import { NavLink, useHistory } from "react-router-dom";

const NavBar = () => {
  const history = useHistory();
  const logout = () => {
    localStorage.clear();
    history.push("/login");
  };
  return (
    <nav className="site-main-menu">
      <ul>
        <li>
          {/* <NavLink to={process.env.PUBLIC_URL + "/home-one"}>
            <span className="menu-text">Admin Home</span>
          </NavLink> */}

          {/* <ul className="sub-menu">
              <li>
                <NavLink to={process.env.PUBLIC_URL + "/home-one"}>
                  <span className="menu-text">Home One</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={process.env.PUBLIC_URL + "/home-two"}>
                  <span className="menu-text">Home Two</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={process.env.PUBLIC_URL + "/home-three"}>
                  <span className="menu-text">Home Three</span>
                </NavLink>
              </li>
            </ul> */}
        </li>

        {/* <li className="has-children">
          <NavLink to={process.env.PUBLIC_URL + "/work"}>
            <span className="menu-text">Work</span>
          </NavLink>
          <span className="menu-toggle">
            <i className="far fa-angle-down"></i>
          </span>
          <ul className="sub-menu">
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/work"}>
                <span className="menu-text">Work</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={process.env.PUBLIC_URL + `/work-details/1`}>
                <span className="menu-text">Work Details</span>
              </NavLink>
            </li>
          </ul>
        </li> */}
        {/* <li className="has-children">
          <NavLink to={process.env.PUBLIC_URL + "/blog-grid"}>
            <span className="menu-text">Blog</span>
          </NavLink>
          <span className="menu-toggle">
            <i className="far fa-angle-down"></i>
          </span>
          <ul className="sub-menu">
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/blog-grid"}>
                <span className="menu-text">Blog Grid</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={process.env.PUBLIC_URL + "/blog-classic"}>
                <span className="menu-text">Blog classic</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={process.env.PUBLIC_URL + `/blog-details/1`}>
                <span className="menu-text">Blog Details</span>
              </NavLink>
            </li>
          </ul>
        </li> */}
        <li>
          <NavLink to="/adminhome">
            <span className="menu-text">Home</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="#">
            <span className="menu-text">Welcome , {localStorage.getItem("name")}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/adminsupport">
            <span className="menu-text">Support</span>
          </NavLink>
        </li>
        <li>
          {localStorage.getItem("uuid") === undefined || localStorage.getItem("uuid") === null ? (
            <NavLink to={process.env.PUBLIC_URL + "/login"}>
              <span className="menu-text">Log in</span>
            </NavLink>
          ) : (
            <NavLink to={process.env.PUBLIC_URL + "/login"} onClick={logout}>
              <span className="menu-text">Log out</span>
            </NavLink>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
