import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import config from "../../config.json";
import uuid from "react-uuid";
//import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import firebase from "./../../firebase";
import { MDBDataTableV5 } from "mdbreact";
import Moment from "moment";
import "./../../css/style.css";
import "./../../css/templete.css";
import "./../../css/skin/skin-1.css";

import PDF, { Text, AddPage, Line, Image, Table, Html } from "jspdf-react";
const Invoice = (props) => {
  const styleH1 = {
    fontSize: "15px",
    textAlign: "center",
    color: "red",
  };
  console.log("Invoice", props.orderInvoice);
  const invisibleStyle = {
    display: "block",
  };
  const properties = { header: "Acme" };
  const head = [["ID", "Name", "Country"]];
  const body = [
    [1, "Shaw", "Tanzania"],
    [2, "Nelson", "Kazakhstan"],
    [3, "Garcia", "Madagascar"],
  ];
  return (
    <React.Fragment>
      <PDF properties={properties} preview={true}>
        <Text x={35} y={25} size={40}>
          Octonyan loves jsPDF
        </Text>
        <Image src={"OctoCatImage"} x={15} y={40} width={180} height={180} />
        <AddPage />
        <Table head={head} body={body} />
        <AddPage format="a6" orientation="l" />
        <Text x={10} y={10} color="red">
          Sample
        </Text>
        <Line x1={20} y1={20} x2={60} y2={20} />
        <AddPage />
        <Html sourceById="page" />
      </PDF>

      <div id="page" style={invisibleStyle}>
        <p>
          {props.orderInvoice.orderid}
          <br />
          {props.orderInvoice.deliverystatus}
          <br />
          {props.orderInvoice.orderstatus}
          <br />
          {props.orderInvoice.userid}
          <br />
        </p>
      </div>
    </React.Fragment>
  );
};
export default Invoice;
