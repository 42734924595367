import React, { useState, useEffect, useRef } from "react";
import config from "../../config.json";
import { Link, useHistory } from "react-router-dom";
import uuid from "react-uuid";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import firebase from "./../../firebase";
import JoditEditor from "jodit-react";
import AdminCategory from "./AdminCategory";
import AdminManageGallery from "./AdminManageGallery";
import on from "./../../assets/images/icons/on.png";
import off from "./../../assets/images/icons/off.png";
import edit from "./../../assets/images/icons/edit.png";
import deleteicon from "./../../assets/images/icons/delete.png";
import publish from "./../../assets/images/icons/publish.png";
import unpublish from "./../../assets/images/icons/unpublish.png";
import Moment from "moment";
const AdminManagePages = (props) => {
  const [posts, setPosts] = useState([]);
  const [editPage, setEditPage] = useState({});
  const [networkError, setNetworkError] = useState("");
  const [masterCategory, setMasterCategory] = useState([]);
  const [masterSubCategory, setMasterSubCategory] = useState([]);
  const [page_image, setPage_image] = useState("");
  const history = useHistory();
  const [smShow, setSmShow] = useState(false);
  const [smShowCategory, setSmShowCategory] = useState(false);
  const [showp, setShowp] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [postTypeValue, setPostTypeValue] = useState("");
  const [allMasterCategory, setAllMasterCategory] = useState([]);
  const [allSubMasterCategory, setAllSubMasterCategory] = useState([]);
  const [postType, setPostType] = useState([]);
  const [flag, setFlag] = useState(false);
  const [access, setAccess] = useState(false);

  const config_ = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  const handleVisible = () => {
    setSmShow(true);
    setTimeout(() => {
      setSmShow(false);
    }, 1000);
  };
  const getPageDetails = async () => {
    await fetch(config.service_url + "getadmincontentpages", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("page123", data.data);
        if (data.status === 200) {
          let active = data.data
            //.filter((filter) => filter.isactive === "1")
            .map((data) => {
              return data;
            });
          setPosts(active);
        } else if (data?.status === 499) {
          history.push("/login");
        }
      })
      .catch((err) => {
        //setNetworkError("Something went wrong, Please try again later!!");
        console.log("page123", err);
      });
  };
  const deletePost = (id) => {
    console.log(id);
    fetch(config.service_url + "deletepage", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ id: id }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          console.log(JSON.stringify({ id: id }));
          setSuccessMsg(data.message);
          handleVisible();
          getPageDetails();
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
          handleVisible();
        }
      })
      .catch((err) => {
        //setNetworkError("Something went wrong, Please try again later!!");
        //console.log(networkError);
      });
  };
  const activateDeactivatePost = (type, pid, id) => {
    console.log(id);
    let post = {};
    post.id = pid;
    post.updateddate = new Date();
    if (type === "activate") post.isactive = id;
    if (type === "publish") post.published = id;
    fetch(config.service_url + "updatepage", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },

      body: JSON.stringify({ data: post }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          console.log(JSON.stringify({ data: post }));
          setSuccessMsg(data.message);
          handleVisible();
          getPageDetails();
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
          handleVisible();
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };

  const onChange_image_post = async (e, imagename) => {
    setSuccessMsg("Please wait");
    console.log("image name", imagename);
    setSmShow(true);
    const file = e.target.files[0];
    console.log("file", e.target.files[0]);
    if (file.size < 500000) {
      console.log("add products", file);
      const path = localStorage.getItem("name") + "-" + localStorage.getItem("uuid") + "/pages";
      const storageRef = firebase.storage().ref(path);
      const fileRef = storageRef.child(editPage.id);
      await fileRef.put(file);
      setPage_image(await fileRef.getDownloadURL());
      console.log("add products", page_image);
      setSmShow(false);
      setSuccessMsg("");
    } else {
      setSuccessMsg("Please upload file less than 500 kb");
    }
  };

  const checkaccess = async () => {
    let datas = {};
    datas.userid = localStorage.getItem("uuid");
    datas.page = "Pages";
    await fetch(config.service_url + "checkaccess", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ data: datas }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("access", data);
        if (data.status === 200) {
          setAccess(true);
        } else {
          history.push("/adminhome");
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
      });
  };
  useEffect(() => {
    checkaccess();
    if (access) {
      getPageDetails();
    }
    // console.log("mobile view", isMobile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag, access]);

  const editPageData = (e, post) => {
    setPage_image(post.page_image);
    setEditPage({});
    document.getElementById("frmPostadd").reset();
    setShowp((showp) => !showp);
    setEditPage(post);
    setContent(post.pagecontent);

    setFlag(false);
    console.log("editpost", post);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: {}, mode: "blur" });
  const onSubmit = (data, e) => {
    let methodname = "addpage";
    setSuccessMsg("Please wait");
    setSmShow(true);
    e.preventDefault();
    if (localStorage.getItem("uuid") === undefined || localStorage.getItem("uuid") === null) {
      history.push("/");
    } else if (Object.keys(editPage).length > 0) {
      data.id = editPage.id;
      data.p_updateddate = new Date();
      methodname = "updatepage";
      data.pagetitle = data.pagetitle !== "" ? data.pagetitle : editPage.pagetitle;
      data.pagecontent = data.pagecontent !== "" ? content : editPage.pagecontent;
      data.page_image = page_image;
      //data.slug = data.pagetitle.replace(/\s/g, "-").toLowerCase();
      data.updateddate = new Date();
    } else {
      data.id = uuid();
      data.createddate = new Date();
      data.createdby = localStorage.getItem("name");
      data.createduserid = localStorage.getItem("uuid");
      data.published = 0;
      data.slug = data.pagetitle.replace(/\s/g, "-").toLowerCase();
      data.isactive = 0;
      data.displaydate = Moment().format("LL");
      data.viewcount = 0;
      data.updateddate = new Date();
      data.page_image = page_image;
      data.pagecontent = content;
    }
    console.log("add page", data);
    fetch(config.service_url + methodname, { method: "POST", headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") }, body: JSON.stringify({ data }) })
      .then((response) => response.json())
      .then((data) => {
        console.log("regitered user", data);
        if (data.status === 200) {
          e.target.reset();
          reset({ posttitle: "" });
          setEditPage({});
          setSuccessMsg(data.message);
          setSmShow(false);
          handleVisible();
          setPage_image("");
          getPageDetails();
          setFlag(true);
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
        }
      })
      .catch((err) => {
        setSuccessMsg("Something went wrong, Please try again later!!");
      });
  };
  const { id, pagetitle, pagecontent } = Object.keys(editPage).length > 0 ? editPage : {};
  {
    return access ? (
      <div>
        <Modal size="sm" className="popup" show={smShow} onHide={() => setSmShow(false)}>
          <Modal.Header closeButton>{successMsg}</Modal.Header>
        </Modal>
        <Modal size="lg" className="popup" show={smShowCategory} onHide={() => setSmShowCategory(false)}>
          <Modal.Header closeButton>Add Image to the page</Modal.Header>
          <Modal.Body closeButton>
            <AdminManageGallery />
          </Modal.Body>
        </Modal>
        <div id="review_form_wrapper">
          <div className={!showp ? "" : "d-none"} id="review_form">
            <button className="btnadmin my-2" onClick={(e) => setShowp((showp) => !showp)}>
              View all Pages ({posts.length > 0 ? posts.length : 0})
            </button>
            <div id="respond" className="comment-respond">
              <h3 className="comment-reply-title" id="reply-title">
                Add Page and Save
              </h3>

              <form className="comment-form" onSubmit={handleSubmit(onSubmit)} id="frmPostadd">
                <div className="comment-form-author">
                  <label>
                    Page Title <span className="required">*</span>
                  </label>
                  <input type="textarea" className="form-control" defaultValue={pagetitle} required name="pagetitle" ref={register} id="pagetitle" />
                  {/* {errors.p_name && "Product name is required"} */}
                </div>

                {Object.keys(editPage).length > 0 && (
                  <div className="comment-form-author">
                    <div className="row">
                      <div className="col">
                        <label>Page Header Image (max. size 500 kb)</label>
                        <input placeholder="Upload Product Image" name="postimage" onChange={onChange_image_post} accept="image/*" className="form-control" type="file" />
                      </div>
                      <div className="col">
                        <label>Page Header Image</label>
                        <img className="smallimage" src={page_image} />
                      </div>
                    </div>
                  </div>
                )}
                <div className="comment-form-comment w-100">
                  <label>
                    Page Content <span className="required">*</span>{" "}
                    <Link to="#" onClick={(e) => setSmShowCategory(true)}>
                      Add image: +
                    </Link>
                  </label>

                  <div className="row">
                    <div className="col-lg-12">
                      <JoditEditor
                        ref={editor}
                        value={content}
                        config={config_}
                        tabIndex={2} // tabIndex of textarea
                        rows="8"
                        onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={(newContent) => {}}
                      />
                    </div>{" "}
                  </div>
                  {/* {errors.p_description && "Product Description is required"} */}
                </div>

                <div className="form-submit">
                  {Object.keys(editPage).length === 0 && (
                    <button type="submit" className="btnadmin btnhover">
                      Save Page
                    </button>
                  )}
                  {Object.keys(editPage).length > 0 && (
                    <button className="btnadmin btnhover" type="submit">
                      Update Page
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          <div id="post" className={showp ? "table check-tbl" : "d-none"}>
            <button
              className="btnadmin my-2"
              onClick={(e) => {
                setEditPage({});
                setShowp((showp) => !showp);
                setPage_image("");
                setFlag(false);
              }}
            >
              Add Page
            </button>
            <table className="table check-tbl">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Page Title</th>
                  <th>Create Date</th>
                  <th>Edit</th>
                  <th>Publish</th>
                  <th>Disable</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {posts.length > 0
                  ? posts.map((post, key) => (
                      <tr>
                        <td className="product-item-img">
                          <img className="smallimage" src={post.page_image} height="15" alt="" />
                        </td>
                        <td className="product-item-name font-weight-normal">{post.pagetitle}</td>

                        <td className="product-item-price font-weight-normal">{Moment(post.createddate).format("DD-MMM-YYYY")}</td>

                        <td className="text-nowrap">
                          <Link to="#" className="py-1" onClick={(e) => editPageData(e, post)}>
                            <img src={edit} alt="edit" className="iconwidth" />
                          </Link>{" "}
                        </td>
                        <td>
                          <Link to="#" onClick={(e) => activateDeactivatePost("publish", post.id, post.published === 1 ? 0 : 1)}>
                            {/* {post.published === 0 ? "Publish" : "UnPublish"} */}
                            {post.published === 1 ? <img src={publish} alt="publish" className="iconwidth" /> : <img src={unpublish} alt="unpublish" className="iconwidth" />}
                          </Link>{" "}
                        </td>
                        <td>
                          {" "}
                          <Link to="#" onClick={(e) => activateDeactivatePost("activate", post.id, post.isactive === 1 ? 0 : 1)}>
                            {post.isactive !== 1 ? <img src={off} alt="deactivate" className="iconwidth" /> : <img src={on} alt="activate" className="iconwidth" />}
                          </Link>
                        </td>
                        <td>
                          <Link to="#" onClick={(e) => deletePost(post.id)}>
                            <img src={deleteicon} alt="delete" className="iconwidth" />
                          </Link>
                        </td>
                      </tr>
                    ))
                  : "No Post added"}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    ) : (
      <div></div>
    );
  }
};

export default AdminManagePages;
