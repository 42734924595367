import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import config from "../../config.json";
import uuid from "react-uuid";
import { MDBDataTableV5 } from "mdbreact";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import firebase from "./../../firebase";
import Moment from "moment";
import Chart from "./Chart";
import AdminManagePosts from "./AdminManagePosts";
import AdminCategory from "./AdminCategory";
import AdminManageGallery from "./AdminManageGallery";
import AdminViewUsers from "./AdminViewUsers";
import AdminOrder from "./AdminOrder";
import on from "./../../assets/images/icons/on.png";
import off from "./../../assets/images/icons/off.png";
import edit from "./../../assets/images/icons/edit.png";
import JoditEditor from "jodit-react";
import deleteicon from "./../../assets/images/icons/delete.png";
import "./../../css/style.css";
import "./../../css/templete.css";
import "./../../css/skin/skin-1.css";
import { forwardRef } from "react";
import Select from "react-select";
import MaterialTable from "material-table";
import { alpha } from "@material-ui/core/styles";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const AdminIncomeExpense = (props) => {
  //const [productDtl, setProductDtl] = useState({});
  //const [validationMsg, setValidationMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [smShow, setSmShow] = useState(false);
  const [show, setShow] = useState(true);
  const [imgshow, setImgShow] = useState(false);
  const [incExp, setincExp] = useState([]);
  const history = useHistory();
  const editor = useRef(null);

  const [editProduct, setEditProduct] = useState({});
  const [networkError, setNetworkError] = useState("");

  const [flag, setFlag] = useState(false);
  const [access, setAccess] = useState(false);
  const [drpType, setdrpType] = useState("");
  const [drpStatus, setdrpStatus] = useState("");

  const config_ = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };
  const handleVisible = () => {
    setSmShow(true);
    setTimeout(() => {
      setSmShow(false);
    }, 1000);
  };

  const getincomeexpense = async () => {
    console.log("cakecategory", props);
    await fetch(config.service_url + "getincomeexpense", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
    })
      .then((response) => response.json())
      .then((data) => {
        {
          setincExp(data.data);
          console.log(data, "incomeexpense");
        }
      })
      .catch((err) => {
        setincExp();
        //setNetworkError("Something went wrong, Please try again later!!");
        console.log("income expence error", err);
      });
  };

  useEffect(() => {
    getincomeexpense();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag, access]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: {} });
  const onSubmit = (data, e) => {
    let methodname = "addincomeexpense";
    setSuccessMsg("Please wait");
    setSmShow(true);
    e.preventDefault();
    if (localStorage.getItem("uuid") === undefined || localStorage.getItem("uuid") === null) {
      history.push("/login");
    } else if (Object.keys(editProduct).length > 0) {
      data.id = editProduct.id;
      data.updateddate = new Date();
      methodname = "updateincomeexpense";
    } else {
      data.id = uuid();
    }
    console.log("income expense", data);
    fetch(config.service_url + methodname, { method: "POST", headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken"), accesslogin: "" }, body: JSON.stringify({ data }) })
      .then((response) => response.json())
      .then((data) => {
        console.log("regitered user", data);
        if (data.status === 200) {
          e.target.reset();
          reset({ p_name: "" });
          setEditProduct({});
          setSuccessMsg(data.message);
          setSmShow(false);
          handleVisible();
          setShow((show) => !show);
          setFlag(true);
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
        }
      })
      .catch((err) => {
        setSuccessMsg("Something went wrong, Please try again later!!");
      });
  };

  const editData = (e, product) => {
    setFlag(false);
    setEditProduct({});
    document.getElementById("formincomeexpense").reset();
    setShow((show) => !show);
    setEditProduct(product);
    setdrpType(product.type);
    setdrpStatus(product.status);
  };

  const { title, description, type, amount, notes, createddate, status } = Object.keys(editProduct).length > 0 ? editProduct : {};
  const columns = [
    {
      title: "Sno",
      field: "sno",
      sort: "enabled",
      width: 150,
    },
    {
      title: "Title",
      field: "title",
      sort: "enabled",
      width: 150,
    },
    {
      title: "description",
      field: "description",
      sort: "disbled",
      width: 150,
    },
    {
      title: "Type",
      field: "type",
      sort: "enabled",
      width: 150,
    },
    {
      title: "Amount",
      field: "amount",
      sort: "disabled",
      width: 150,
    },

    {
      title: "Status",
      field: "status",
      sort: "enabled",
      width: 150,
    },
    {
      title: "Date",
      field: "createddate",
      sort: "enabled",
      width: 150,
    },
    {
      title: "Notes",
      field: "notes",
      sort: "disabled",
      width: 150,
    },
    {
      title: "Action",
      field: "action",
      sort: "disabled",
      width: 150,
    },
  ];

  const expdata =
    incExp &&
    incExp.length > 0 &&
    incExp.map((ie, index) => ({
      sno: index + 1,
      title: ie.title,
      description: ie.description,
      type: ie.type,
      amount: <span className={ie.type === "credit" && ie.status === "completed" ? "text-green" : ie.status === "pending" ? "text-orange" : "text-red"}>₹ {ie.amount}</span>,
      notes: ie.notes,
      createddate: ie.createddate,
      status: ie.status,
      action: (
        <>
          <Link to="#" className="py-1" onClick={(e) => editData(e, ie)}>
            <img src={edit} alt="edit" className="iconwidth" />
          </Link>{" "}
        </>
      ),
    }));

  const datatable1 = {
    columns: [
      {
        label: "#",
        field: "sno",
        sort: "enabled",
        width: 150,
      },
      {
        label: "Title",
        field: "title",
        sort: "enabled",
        width: 150,
      },
      {
        label: "description",
        field: "description",
        sort: "disbled",
        width: 150,
      },
      {
        label: "Type",
        field: "type",
        sort: "enabled",
        width: 150,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "disabled",
        width: 150,
      },

      {
        label: "Status",
        field: "status",
        sort: "enabled",
        width: 150,
      },
      {
        label: "Date",
        field: "createddate",
        sort: "enabled",
        width: 150,
      },
      {
        label: "Notes",
        field: "notes",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 150,
      },
    ],
    rows:
      incExp &&
      incExp.length > 0 &&
      incExp.map((ie, index) => ({
        sno: index + 1,
        title: ie.title,
        description: <p className="font-weight-light">{ie.description}</p>,
        type: ie.type,
        amount: <span className={ie.type === "credit" && ie.status === "completed" ? "text-green" : ie.status === "pending" ? "text-orange" : "text-red"}>₹ {ie.amount}</span>,
        notes: <p className="font-weight-light">{ie.notes}</p>,
        createddate: ie.createddate,
        status: ie.status,
        action: (
          <>
            <Link to="#" className="py-1" onClick={(e) => editData(e, ie)}>
              <img src={edit} alt="edit" className="iconwidth" />
            </Link>{" "}
          </>
        ),
      })),
  };
  {
    return (
      <div>
        <Modal size="sm" className="z-index" show={smShow} onHide={() => setSmShow(false)}>
          <Modal.Header closeButton>
            <span className="text-dark"> {successMsg}</span>
          </Modal.Header>
        </Modal>
        <div className="border bg-gray text-dark d-none">
          <div className="row p-3">
            <div className="col m-auto">
              <input type="date" className="col form-control" name="fromddate" id="fromddate" />
            </div>
            <div className="col m-auto">
              <input type="date" className=" col form-control" name="toddate" id="todate" />
            </div>
            <div className="col m-auto">
              <button type="button" className="btnadmin btn-sm text-white" name="btnfilter" id="btnfilter">
                Submit
              </button>{" "}
            </div>
          </div>
        </div>
        <div>
          <div className="row text-center d-none">
            <div className="col border p-2 m-1 bg-orange text-dark m-auto">
              Pending <div>₹ 200</div>
            </div>
            <div className="col border p-2 m-1 bg-green text-dark m-auto">
              Income<div>₹ 200</div>
            </div>
            <div className="col border p-2 m-1 bg-red text-dark m-auto">
              Expense<div>₹200</div>
            </div>
          </div>
        </div>
        <div id="review_form_wrapper">
          <div className={!show ? "" : "d-none"} id="review_form">
            <button className="btnadmin my-2" onClick={(e) => setShow((show) => !show)}>
              View all Transactions
            </button>{" "}
            <div id="respond" className="comment-respond">
              <form className="comment-form" onSubmit={handleSubmit(onSubmit)} id="formincomeexpense">
                <div className="comment-form-author">
                  <div className="row">
                    <div className="col">
                      <label>Title</label>
                      <input type="text" defaultValue={title} name="title" required ref={register} id="title" />
                    </div>
                    <div className="col">
                      {" "}
                      <label>Description</label>
                      <input type="text" defaultValue={description} aria-required="true" name="description" ref={register} id="description" />
                      {/* {errors.p_quantity && "Available Quantity is required"} */}
                    </div>
                  </div>
                </div>
                <div className="comment-form-author">
                  <div className="row">
                    <div className="col">
                      <label>Type</label>
                      <select type="text" className="form-select" value={drpType} name="type" ref={register} id="type" required onChange={(e) => setdrpType(e.target.value)}>
                        <option value=""></option>
                        <option value="credit">credit</option>
                        <option value="debit">debit</option>
                      </select>
                    </div>
                    <div className="col">
                      <label className="text-nowrap">Amount ₹.</label>
                      <input type="number" min={0} className="form-control" defaultValue={amount} name="amount" required ref={register} id="amount" />
                    </div>
                  </div>
                </div>

                <div className="comment-form-author">
                  <label>Notes</label>

                  <textarea type="textarea" aria-required="true" defaultValue={notes} rows="4" cols="45" required name="notes" ref={register} id="notes" />
                </div>
                <div className="comment-form-author">
                  <div className="row">
                    <div className="col">
                      <label className="text-nowrap">Created Date</label>
                      <input type="date" min={0} className="form-control" defaultValue={createddate} name="createddate" required ref={register} id="createddate" />
                    </div>
                    <div className="col">
                      <label>Status</label>
                      <select type="text" className="form-select" value={drpStatus} name="status" ref={register} id="status" required onChange={(e) => setdrpStatus(e.target.value)}>
                        <option value=""></option>
                        <option value="pending">pending</option>
                        <option value="completed">completed</option>
                        <option value="completed">cancelled</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-submit">
                  {Object.keys(editProduct)?.length === 0 && (
                    <button type="submit" disabled={smShow} className="btnadmin btnhover">
                      Add
                    </button>
                  )}
                  {Object.keys(editProduct).length > 0 && (
                    <button className="btnadmin btnhover" disabled={smShow} type="submit">
                      Update
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          <div id="product" className={show ? "table check-tbl" : "d-none"}>
            <div className="py-1">
              {" "}
              <button className="btnadmin btn-sm text-white" onClick={(e) => setShow((show) => !show)}>
                Add/Update Transactions
              </button>{" "}
            </div>

            <div className="overflow-auto">
              {/* <MDBDataTableV5 disableRetreatAfterSorting={true} entriesOptions={[5, 20, 25]} entries={30} pagesAmount={4} data={datatable1} pagingTop={false} searchTop searchBottom={false} /> */}
              <small>
                <MaterialTable title="All TUC Income/Expenditure" icons={tableIcons} data={expdata.length > 0 ? expdata : [{ sno: "Loading..." }]} columns={columns} options={{ search: true, paging: true, filtering: false, sorting: true, pageSize: 20, exportButton: true }} />
              </small>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default AdminIncomeExpense;
