import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../components/logo/Logo";
import FooterData from "../../data/Footer/footerItem.json";
import FooterLinkItem from "../../components/Footer/FooterLinkItem.jsx";
import logo from "../../assets/images/logo/logo.png";
const Footer = () => {
  return (
    <div className="footer-section section footer-bg-color">
      <div className="container">
        <div className="row mb-lg-14 mb-md-10 mb-6">
          <div className="col-xl-6 col-lg-4 col-md-4 col-sm-6 col-12 col-12 mb-6">
            <div className="footer-widget">
              <div className="footer-logo titlelogo text-nowrap">
                <Logo image={logo} />
                {/* Unique Creations */}
              </div>
              <div className="footer-widget-content">
                <div className="content">
                  {/* <p>
                    <Link to={process.env.PUBLIC_URL + "/"}>(+1) 212-946-2701</Link>
                  </p> */}
                  <p>
                    <Link className="text-light" to={process.env.PUBLIC_URL + "/"}>
                      info@theuniquecreations.com
                    </Link>{" "}
                  </p>
                </div>
                <div className="footer-social-inline">
                  {/* <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-twitter-square"></i>
                  </a> */}
                  <a href="https://www.facebook.com/theuniquecreations.it" target="_blank" rel="noopener noreferrer">
                    FB
                  </a>
                  <a href="https://www.instagram.com/theuniquecreations.it" target="_blank" rel="noopener noreferrer">
                    Instagram
                  </a>
                </div>
              </div>
            </div>
          </div>

          {FooterData &&
            FooterData.map((single, key) => {
              return (
                <div key={key} className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 mb-6">
                  <FooterLinkItem data={single} key={key} />
                </div>
              );
            })}
          <>
            <a href="https://www.freepik.com/" className="text-small" target="_blank" rel="noopener noreferrer">
              Some Images are from www.freepic.com
            </a>
          </>
        </div>

        <div className="row">
          <div className="col">
            <p className="copyright">
              &copy; {new Date().getFullYear()} <strong>theuniquecreations</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
