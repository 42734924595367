import React from "react";
import SEO from "../../components/SEO";
import SideHeader from "../../partials/header/SideHeader";
import Breadcrumbadmin from "../../container/Breadcrumb/Breadcrumbadmin";
import AdminPayementGateway from "../../container/AdminHome/AdminPayementGateway";
import NavBarAdmin from "../../components/NavBar/NavBarAdmin";
import Footer from "../../container/Footer/Footer";
import ScrollToTop from "../../components/ScrollToTop.jsx";
import config from "../../config.json";
const AdminPG = () => {
  return (
    <React.Fragment>
      <SEO title="Payment" />
      <div className="d-flex">
        <div>
          <SideHeader />
        </div>
        <div className="flex-grow-1">
          <div className="d-flex flex-column">
            <div className="border-0 bg-dark section">
              <div className="header-inner ">
                <NavBarAdmin />
              </div>
            </div>
            <div className=" p-3">
              <AdminPayementGateway serviceurl={config.clientname === "daintyflavors.com" ? config.daintyservice_url : config.service_url} client={config.clientname} />
            </div>
          </div>
        </div>
      </div>

      {/* <Breadcrumbadmin image="images/bg/breadcrumb-bg.jpg" title="" content="Home" contentTwo="Admin Home" /> */}

      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default AdminPG;
