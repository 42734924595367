import React, { useState, useEffect, useRef } from "react";
import config from "../../config.json";
import { Link, useHistory } from "react-router-dom";
import uuid from "react-uuid";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import firebase from "./../../firebase";
import JoditEditor from "jodit-react";
import AdminCategory from "./AdminCategory";
import AdminManageGallery from "./AdminManageGallery";
import on from "./../../assets/images/icons/on.png";
import off from "./../../assets/images/icons/off.png";
import edit from "./../../assets/images/icons/edit.png";
import deleteicon from "./../../assets/images/icons/delete.png";
import publish from "./../../assets/images/icons/publish.png";
import unpublish from "./../../assets/images/icons/unpublish.png";
import Moment from "moment";
const AdminManagePosts = (props) => {
  const [posts, setPosts] = useState([]);
  const [editPost, setEditPost] = useState({});
  const [networkError, setNetworkError] = useState("");
  const [masterCategory, setMasterCategory] = useState([]);
  const [masterSubCategory, setMasterSubCategory] = useState([]);
  const [post_image, setPost_image] = useState("");
  const history = useHistory();
  const [p_categorydrp, setp_categorydrp] = useState("");
  const [p_subcategorydrp, setp_subcategorydrp] = useState("");
  const [smShow, setSmShow] = useState(false);
  const [smShowCategory, setSmShowCategory] = useState(false);
  const [smShowGallery, setSmShowGallery] = useState(false);
  const [showp, setShowp] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [transcriptcontent, setTranscriptContent] = useState("");
  const [postTypeValue, setPostTypeValue] = useState("");
  const [allMasterCategory, setAllMasterCategory] = useState([]);
  const [allSubMasterCategory, setAllSubMasterCategory] = useState([]);
  const [postType, setPostType] = useState([]);
  const [flag, setFlag] = useState(false);
  const [access, setAccess] = useState(false);

  const config_ = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };
  // const postType = [
  //   { value: "Blog", label: "Blog" },
  //   { value: "Course", label: "Course" },
  // ];
  const handleVisible = () => {
    setSmShow(true);
    setTimeout(() => {
      setSmShow(false);
    }, 1000);
  };
  const getPostDetails = async () => {
    console.log("cakecategory", props);
    await fetch(config.service_url + "getadminposts", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          let active = data.data
            //.filter((filter) => filter.isactive === "1")
            .map((data) => {
              return data;
            });
          setPosts(active);
        } else if (data?.status === 499) {
          history.push("/login");
        }
        console.log(data, "getposts");
      })
      .catch((err) => {
        //setNetworkError("Something went wrong, Please try again later!!");
        // console.log(networkError);
      });
  };
  const deletePost = (id) => {
    let text = "Are you sure to delete ? \nDeleting the item cannot be retrived again.";
    if (window.confirm(text) == true) {
      console.log(id);
      fetch(config.service_url + "deletepost", {
        method: "POST",
        headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
        body: JSON.stringify({ Id: id }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 200) {
            console.log(JSON.stringify({ Id: id }));
            setSuccessMsg(data.message);
            handleVisible();
            getPostDetails();
          } else if (data?.status === 499) {
            history.push("/login");
          } else {
            console.log(data.message);
          }
        })
        .catch((err) => {
          //setNetworkError("Something went wrong, Please try again later!!");
          console.log(err);
        });
    } else {
      return;
    }
  };
  const activateDeactivatePost = (type, pid, id) => {
    console.log(id);
    let post = {};
    post.post_id = pid;
    post.updateddate = new Date();
    if (type === "activate") post.isactive = id;
    if (type === "publish") post.published = id;
    fetch(config.service_url + "updatepost", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },

      body: JSON.stringify({ data: post }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          console.log(JSON.stringify({ data: post }));
          setSuccessMsg(data.message);
          handleVisible();
          getPostDetails();
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
          handleVisible();
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const getPostCategory = async () => {
    await fetch(config.service_url + "getcategory", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          console.log("master category", data);
          let _filterData = data.data.filter((_d) => _d.type === "blog" || _d.type === "course" || _d.type === "resource" || _d.type === "podcast");
          let _filterData1 = data.data.filter((_d) => _d.type === "posttype" && _d.isactive === 1);
          setPostType(_filterData1);
          setAllMasterCategory(_filterData);
          setMasterCategory(_filterData);
        } else if (data.status === 400) {
          console.log("No Data");
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const getSubCategories = async () => {
    await fetch(config.service_url + "getsubcategory", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          console.log("master sub category", data);
          let _filterData = data.data.filter((_d) => _d.type === "blog" || _d.type === "course" || _d.type === "resource" || _d.type === "podcast");
          setAllSubMasterCategory(_filterData);
          setMasterSubCategory(_filterData);
        } else if (data.status === 400) {
          console.log("No Data");
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
        console.log(networkError);
      });
  };
  const onChange_image_post = async (e, imagename) => {
    setSuccessMsg("Please wait");
    console.log("image name", imagename);
    setSmShow(true);
    const file = e.target.files[0];
    console.log("file", e.target.files[0]);
    if (file.size < 300000) {
      console.log("add products", file);
      const path = localStorage.getItem("name") + "-" + localStorage.getItem("uuid") + "/posts";
      const storageRef = firebase.storage().ref(path);
      const fileRef = storageRef.child(editPost.post_id);
      await fileRef.put(file);
      setPost_image(await fileRef.getDownloadURL());
      console.log("add products", post_image);
      setSmShow(false);
      setSuccessMsg("");
    } else {
      setSuccessMsg("Please upload file less than 300 kb");
    }
  };

  const onChange_PostType = (value) => {
    const _masterCategory = allMasterCategory;
    const _masterSubCategory = allSubMasterCategory;
    setPostTypeValue(value);
    setMasterCategory(_masterCategory.filter((filter) => filter.type.toLowerCase() === value.toLowerCase()));
    setMasterSubCategory(_masterSubCategory.filter((filter) => filter.type.toLowerCase() === value.toLowerCase()));
  };
  const checkaccess = async () => {
    let datas = {};
    datas.userid = localStorage.getItem("uuid");
    datas.page = "Podcast";
    await fetch(config.service_url + "checkaccess", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ data: datas }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("access", data);
        if (data.status === 200) {
          setAccess(true);
        } else {
          history.push("/adminhome");
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
      });
  };
  useEffect(() => {
    checkaccess();
    if (access) {
      getPostCategory();
      getSubCategories();
      getPostDetails();
    }
    // console.log("mobile view", isMobile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag, access]);

  const editPostData = (e, post) => {
    setPost_image(post.post_image);
    setEditPost({});
    document.getElementById("frmPostadd").reset();
    setShowp((showp) => !showp);
    setEditPost(post);
    setContent(post.postcontent);
    setTranscriptContent(post.transcript);
    setp_categorydrp(post.postcategory);
    setp_subcategorydrp(post.postsubcategory);
    setPostTypeValue(post.posttypevalue);
    setFlag(false);
    console.log("editpost", post);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: {}, mode: "blur" });
  const onSubmit = (data, e) => {
    let methodname = "addpost";
    console.log("content", content);
    setSuccessMsg("Please wait");
    setSmShow(true);
    e.preventDefault();
    if (localStorage.getItem("uuid") === undefined || localStorage.getItem("uuid") === null) {
      history.push("/");
    } else if (Object.keys(editPost).length > 0) {
      data.post_id = editPost.post_id;
      data.p_updateddate = new Date();
      methodname = "updatepost";
      data.courselink = data.courselink !== "" ? data.courselink : editPost.courselink;
      data.posttitle = data.posttitle !== "" ? data.posttitle : editPost.posttitle;
      data.postcategory = data.postcategory !== "" ? data.postcategory : editPost.postcategory;
      data.postsubcategory = data.postsubcategory !== "" ? data.postsubcategory : editPost.postsubcategory;
      data.posttypevalue = data.posttypevalue !== "" ? data.posttypevalue : editPost.posttypevalue;
      //data.transcript = data.transcript !== "" ? data.transcript : editPost.transcript;
      data.transcript = data.transcript !== "" ? transcriptcontent : editPost.transcript;
      data.postcontent = data.postcontent !== "" ? content : editPost.postcontent;
      data.post_image = post_image;
      data.slug = data.posttitle.replace(/\s/g, "-").toLowerCase();
      data.updateddate = new Date();
    } else {
      data.post_id = uuid();
      data.createddate = new Date();
      data.createduserid = localStorage.getItem("uuid");
      data.createdby = localStorage.getItem("name");
      data.published = 0;
      data.slug = data.posttitle.replace(/\s/g, "-").toLowerCase();
      data.isactive = 1;
      data.displaydate = Moment().format("LL");
      data.viewcount = 0;
      data.updateddate = new Date();
      data.post_image = post_image;
      data.postcontent = content;
      data.postcontent = transcriptcontent;
      data.posttypevalue = postTypeValue;
    }
    console.log("add post", data);
    fetch(config.service_url + methodname, { method: "POST", headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") }, body: JSON.stringify({ data }) })
      .then((response) => response.json())
      .then((data) => {
        console.log("regitered user", data);
        if (data.status === 200) {
          e.target.reset();
          reset({ posttitle: "" });
          setEditPost({});
          setSuccessMsg(data.message);
          setSmShow(false);
          handleVisible();
          setp_categorydrp("");
          setp_subcategorydrp("");
          setPost_image("");
          setPostTypeValue("");
          getPostDetails();
          setFlag(true);
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
        }
      })
      .catch((err) => {
        setSuccessMsg("Something went wrong, Please try again later!!");
      });
  };
  const { post_id, posttitle, postcategory, postsubcategory, postcontent, courselink, transcript, posttypevalue, urlid } = Object.keys(editPost).length > 0 ? editPost : {};
  {
    return access ? (
      <div>
        <Modal size="sm" className="popup" show={smShow} onHide={() => setSmShow(false)}>
          <Modal.Header closeButton>{successMsg}</Modal.Header>
        </Modal>
        <Modal size="md" className="popup" show={smShowCategory} onHide={() => setSmShowCategory(false)}>
          <Modal.Header closeButton>Add Post Category/Sub Category</Modal.Header>
          <Modal.Body closeButton>
            <AdminCategory type={postTypeValue} />
          </Modal.Body>
        </Modal>
        <Modal size="lg" className="popup" show={smShowGallery} onHide={() => setSmShowGallery(false)}>
          <Modal.Header closeButton>Add Image to the page</Modal.Header>
          <Modal.Body closeButton>
            <AdminManageGallery />
          </Modal.Body>
        </Modal>
        <div id="review_form_wrapper">
          <div className={!showp ? "" : "d-none"} id="review_form">
            <button className="btnadmin my-2" onClick={(e) => (setShowp((showp) => !showp), setContent(""), setTranscriptContent(""))}>
              View all Podcast ({posts.length > 0 ? posts.length : 0})
            </button>
            <div id="respond" className="comment-respond">
              <h3 className="comment-reply-title" id="reply-title">
                Add Podcast and Save
              </h3>

              <form className="comment-form" onSubmit={handleSubmit(onSubmit)} id="frmPostadd">
                <div className="comment-form-author">
                  <label>
                    Type <span className="required">*</span>
                  </label>
                  <select value={postTypeValue} className="form-control" id="posttypevalue" ref={register} name="posttypevalue" required onChange={(e) => onChange_PostType(e.target.value)}>
                    <option value={""}>{""}</option>
                    {postType.map((type) => (
                      <option value={type.category}>{type.category}</option>
                    ))}
                  </select>
                </div>
                {postTypeValue === "podcast" && (
                  <>
                    <div className="comment-form-author">
                      <label>
                        Episode #<span className="required"></span>
                        <small>
                          <i>Please enter unique value or Text. Eg : Episode#1 or Epi#1</i>
                        </small>
                      </label>
                      <input type="text" className="form-control" defaultValue={urlid} required={postTypeValue === "podcast"} aria-required="true" size="30" name="urlid" ref={register} id="urlid" />
                    </div>
                  </>
                )}
                <div className="comment-form-author">
                  <label>
                    Title <span className="required">*</span>
                  </label>
                  <input type="textarea" className="form-control" defaultValue={posttitle} required name="posttitle" ref={register} id="posttitle" />
                  {/* {errors.p_name && "Product name is required"} */}
                </div>
                <div className="comment-form-author">
                  <div className="row">
                    <div className="col">
                      {" "}
                      <label>
                        Category <span className="required">*</span>
                      </label>
                      <select value={p_categorydrp} className="form-control" id="postcategory" ref={register} name="postcategory" onChange={(e) => setp_categorydrp(e.target.value)}>
                        <option value={""}>{""}</option>
                        {masterCategory.map((cat) => (
                          <option value={cat.category}>{cat.category}</option>
                        ))}
                      </select>
                      {/* <input type="text" defaultValue={p_category} required aria-required="true" size="30" name="p_category" {...register("p_category")} id="p_category" /> */}
                      {/* {errors.p_category && "Category is required"} */}
                    </div>
                    <div className="col">
                      {" "}
                      <label>
                        Sub Category <span className="required"></span>
                      </label>
                      <select value={p_subcategorydrp} name="postsubcategory" id="postsubcategory" ref={register} className="form-control" onChange={(e) => setp_subcategorydrp(e.target.value)}>
                        <option value={""}>{""}</option>
                        {masterSubCategory.map((cat) => (
                          <option value={cat.subcategory}>{cat.subcategory}</option>
                        ))}
                      </select>
                      {/* <input type="text" defaultValue={p_subcategory} required aria-required="true" size="30" name="p_subcategory" {...register("p_subcategory")} id="p_subcategory" /> */}
                      {/* {errors.p_subcategory && "Subcategory is required"} */}
                    </div>
                    <div className="col">
                      <label>Add Category</label>
                      <Link to="#" onClick={(e) => setSmShowCategory(true)}>
                        <span className="addButton btnadmin btnhover py-1">+</span>
                      </Link>
                    </div>
                  </div>
                </div>
                {Object.keys(editPost).length > 0 && (
                  <div className="comment-form-author">
                    <div className="row">
                      <div className="col">
                        <label>Podcast Image (max. size 300 kb)</label>
                        <input placeholder="Upload Product Image" name="postimage" onChange={onChange_image_post} accept="image/*" className="form-control" type="file" />
                        {/* <input placeholder="Upload Product Image" name="p_image" onChange={onChange_image} type="file" {...register("p_image")} id="p_image" /> */}
                      </div>
                      <div className="col">
                        <label>Podcast Image</label>
                        <img className="smallimage" src={post_image} />
                      </div>
                    </div>
                  </div>
                )}
                {postTypeValue === "podcast" && (
                  <>
                    <div className="comment-form-author">
                      <label>
                        Podcast Link<span className="required"></span>
                        <small>
                          <i> Please embed Podcast audio from source like spotify,apple podcast</i>
                        </small>
                      </label>
                      <textarea type="text" className="form-control" defaultValue={courselink} required={postTypeValue === "podcast"} aria-required="true" size="30" name="courselink" ref={register} id="courselink" />
                    </div>
                    <div className="comment-form-author w-100">
                      <label>
                        Transcript<span className="required"></span>
                      </label>
                      <JoditEditor
                        ref={editor}
                        value={transcriptcontent}
                        config={config_}
                        tabIndex={4} // tabIndex of textarea
                        rows="8"
                        onBlur={(newContent) => setTranscriptContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={(newContent) => {}}
                      />
                    </div>{" "}
                  </>
                )}
                <div className="comment-form-comment w-100">
                  <label>
                    Podcast Content <span className="required">*</span>{" "}
                    <Link to="#" onClick={(e) => setSmShowGallery(true)}>
                      Add image: +
                    </Link>
                  </label>

                  <div className="row">
                    <div className="col-lg-12">
                      <JoditEditor
                        ref={editor}
                        value={content}
                        config={config_}
                        tabIndex={6} // tabIndex of textarea
                        rows="8"
                        onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={(newContent) => {}}
                      />
                    </div>{" "}
                  </div>
                  {/* {errors.p_description && "Product Description is required"} */}
                </div>

                <div className="form-submit">
                  {Object.keys(editPost).length === 0 && (
                    <button type="submit" className="btnadmin btnhover">
                      Save Podcast
                    </button>
                  )}
                  {Object.keys(editPost).length > 0 && (
                    <button className="btnadmin btnhover" type="submit">
                      Update Podcast
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          <div id="post" className={showp ? "table check-tbl" : "d-none"}>
            <button
              className="btnadmin my-2"
              onClick={(e) => {
                setEditPost({});
                setShowp((showp) => !showp);
                setPost_image("");
                setFlag(false);
              }}
            >
              Add Podcast
            </button>
            <table className="table check-tbl">
              <thead>
                <tr>
                  <th>Image</th>

                  <th>Podcast Title</th>
                  <th>Type/Category</th>

                  <th>Create Date</th>

                  <th>Edit</th>
                  <th>Publish</th>
                  <th>Disable</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {posts.length > 0
                  ? posts.map((post, key) => (
                      <tr>
                        <td className="product-item-img">
                          <img className="smallimage" src={post.post_image} height="15" alt="" />
                        </td>

                        <td className="product-item-name font-weight-normal">
                          {" "}
                          {post.urlid}/{post.posttitle}
                        </td>
                        <td className="product-item-name font-weight-normal">
                          {post.posttypevalue}/{post.postcategory}
                        </td>
                        <td className="product-item-price font-weight-normal">{Moment(post.createddate).format("DD-MMM-YYYY")}</td>
                        <td className="text-nowrap">
                          <Link to="#" className="py-1" onClick={(e) => editPostData(e, post)}>
                            <img src={edit} alt="edit" className="iconwidth" />
                          </Link>{" "}
                        </td>
                        <td>
                          {" "}
                          <Link to="#" onClick={(e) => activateDeactivatePost("publish", post.post_id, post.published === 1 ? 0 : 1)}>
                            {/* {post.published === 0 ? "Publish" : "UnPublish"} */}
                            {post.published === 1 ? <img src={publish} alt="publish" className="iconwidth" /> : <img src={unpublish} alt="unpublish" className="iconwidth" />}
                          </Link>{" "}
                        </td>
                        <td>
                          {" "}
                          <Link to="#" onClick={(e) => activateDeactivatePost("activate", post.post_id, post.isactive === 1 ? 0 : 1)}>
                            {post.isactive !== 1 ? <img src={off} alt="deactivate" className="iconwidth" /> : <img src={on} alt="activate" className="iconwidth" />}
                          </Link>
                        </td>
                        <td>
                          <Link to="#" onClick={(e) => deletePost(post.post_id)}>
                            <img src={deleteicon} alt="delete" className="iconwidth" />
                          </Link>
                        </td>
                      </tr>
                    ))
                  : "No Podcast added"}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    ) : (
      <div></div>
    );
  }
};

export default AdminManagePosts;
