import React, { useState, useEffect, useRef } from "react";
import config from "../../config.json";
import Gallery from "./Gallery";
const AdminManageGallery = (props) => {
  {
    return (
      <div>
        <Gallery serviceurl={config.service_url} page="Gallery" />
      </div>
    );
  }
};

export default AdminManageGallery;
