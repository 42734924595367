import { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AOS from "aos";
import NavScrollTop from "./components/NavScrollTop";
import HomeOne from "./pages/HomeOne";
import HomeTwo from "./pages/HomeTwo";
import HomeThree from "./pages/HomeThree";
import About from "./pages/About";
import Service from "./pages/Service";
import Work from "./pages/Work";
import WorkDetails from "./pages/WorkDetails";
import BlogGrid from "./pages/BlogGrid";
import BlogClassic from "./pages/BlogClassic";
import BlogDetails from "./pages/BlogDetails";
import BlogCategories from "./pages/BlogCategories";
import BlogTag from "./pages/BlogTag";
import Contact from "./pages/Contact";
import Payment from "./pages/Payment";
import Pricing from "./pages/Pricing";
import Login from "./pages/Login";
import Register from "./pages/Register";
import NotFound from "./pages/NotFound";
import AdminHome from "./pages/AdminHome";
import AdminBlog from "./pages/Admin/AdminBlog";
import AdminPodcast from "./pages/Admin/AdminPodcast";
import AdminPages from "./pages/Admin/AdminPages";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import AdminGallery from "./pages/Admin/AdminGallery";
import AdminDaintyOrders from "./pages/Admin/AdminDaintyOrders";
import AdminOrder from "./pages/Admin/AdminOrder";
import AdminUser from "./pages/Admin/AdminUser";
import AdminProduct from "./pages/Admin/AdminProduct";
import AdminSupport from "./pages/Admin/AdminSupport";
import AdminDaintyProducts from "./pages/Admin/AdminDaintyProducts";
import AdminDaintyGallery from "./pages/Admin/AdminDaintyGallery";
import AdminTestimonial from "./pages/Admin/AdminTestimonial";
import AdminDaintyTestimonial from "./pages/Admin/AdminDaintyTestimonial";
import AdminProfile from "./pages/Admin/AdminProfile";
import AdminTUC from "./pages/Admin/AdminTUC";
import AdminPayementGateway from "./pages/Admin/AdminPayementGateway";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";

// CSS File Here
import "aos/dist/aos.css";
import "react-modal-video/scss/modal-video.scss";
import "./assets/scss/style.scss";

function App() {
  useEffect(() => {
    AOS.init({
      offset: 80,
      duration: 1000,
      once: true,
      easing: "ease",
    });
    AOS.refresh();
  }, []);
  return (
    <Router>
      <NavScrollTop>
        <Switch>
          <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={HomeOne} />
          <Route path={`${process.env.PUBLIC_URL + "/home"}`} exact component={HomeOne} />
          <Route path={`${process.env.PUBLIC_URL + "/home-two"}`} component={HomeTwo} />
          <Route path={`${process.env.PUBLIC_URL + "/home-three"}`} component={HomeThree} />
          <Route path={`${process.env.PUBLIC_URL + "/about"}`} component={About} />
          <Route path={`${process.env.PUBLIC_URL + "/service"}`} component={Service} />
          <Route path={`${process.env.PUBLIC_URL + "/work"}`} component={Work} />
          <Route path={`${process.env.PUBLIC_URL + "/work-details/:id"}`} component={WorkDetails} />
          {/* <Route path={`${process.env.PUBLIC_URL + "/blog"}`} component={BlogGrid} />
          <Route path={`${process.env.PUBLIC_URL + "/blog-classic"}`} component={BlogClassic} />
          <Route path={`${process.env.PUBLIC_URL + "/tag/:slug"}`} component={BlogTag} />
          <Route path={`${process.env.PUBLIC_URL + "/category/:slug"}`} component={BlogCategories} />
          <Route path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`} component={BlogDetails} /> */}
          <Route path={`${process.env.PUBLIC_URL + "/contact"}`} component={Contact} />
          <Route path={`${process.env.PUBLIC_URL + "/payment"}`} component={Payment} />
          <Route path={`${process.env.PUBLIC_URL + "/pricing"}`} component={Pricing} />
          <Route path={`${process.env.PUBLIC_URL + "/login"}`} component={Login} />
          <Route path={`${process.env.PUBLIC_URL + "/register"}`} component={Register} />
          <Route path={`${process.env.PUBLIC_URL + "/adminhome"}`} component={AdminHome} />
          <Route path={`${process.env.PUBLIC_URL + "/adminblog"}`} component={AdminBlog} />
          <Route path={`${process.env.PUBLIC_URL + "/adminpodcast"}`} component={AdminPodcast} />
          <Route path={`${process.env.PUBLIC_URL + "/adminpages"}`} component={AdminPages} />
          <Route path={`${process.env.PUBLIC_URL + "/admingallery"}`} component={AdminGallery} />
          <Route path={`${process.env.PUBLIC_URL + "/admindaintygallery"}`} component={AdminDaintyGallery} />
          <Route path={`${process.env.PUBLIC_URL + "/adminorder"}`} component={AdminOrder} />
          <Route path={`${process.env.PUBLIC_URL + "/admindaintyorders"}`} component={AdminDaintyOrders} />
          <Route path={`${process.env.PUBLIC_URL + "/admindashboard"}`} component={AdminDashboard} />
          <Route path={`${process.env.PUBLIC_URL + "/adminproduct"}`} component={AdminProduct} />
          <Route path={`${process.env.PUBLIC_URL + "/adminsupport"}`} component={AdminSupport} />
          <Route path={`${process.env.PUBLIC_URL + "/admindaintyproduct"}`} component={AdminDaintyProducts} />
          <Route path={`${process.env.PUBLIC_URL + "/admintestimonial"}`} component={AdminTestimonial} />
          <Route path={`${process.env.PUBLIC_URL + "/admindaintytestimonial"}`} component={AdminDaintyTestimonial} />
          <Route path={`${process.env.PUBLIC_URL + "/adminuser"}`} component={AdminUser} />
          <Route path={`${process.env.PUBLIC_URL + "/adminprofile"}`} component={AdminProfile} />
          <Route path={`${process.env.PUBLIC_URL + "/admintuc"}`} component={AdminTUC} />
          <Route path={`${process.env.PUBLIC_URL + "/adminrazorpay"}`} component={AdminPayementGateway} />
          {<Route path="/*" component={NotFound} />}
        </Switch>
      </NavScrollTop>
    </Router>
  );
}

export default App;
