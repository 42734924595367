import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import AboutFour from "../container/About/AboutFour";
import Video from "../container/Video/Video";
import AboutFive from "../container/About/AboutFive";
import TestimonialContainer from "../container/Testimonial/TestimonialContainer";
import CallToActionTwo from "../container/CallToAction/CallToActionTwo";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";

const AboutUs = () => {
  return (
    <React.Fragment>
      <SEO title="Unique Creations || About" image_url="https://firebasestorage.googleapis.com/v0/b/tucfbclouddb.appspot.com/o/TUC%2Faboutus.jpg?alt=media&token=e793ab5e-2e3a-4cda-8538-370b4ad7dc2f" contenttitle="Unique Creations, About us" description="Software company in Perambalur, Tamilnadu" keywords="About us, the uniquecreations, software companu in perambalur" />
      <Header />
      <Breadcrumb image="images/bg/breadcrumb-bg.jpg" title="We are an agency located in India" content="Home" contentTwo="About Us" />
      <AboutFour />
      <Video />
      <AboutFive />
      <TestimonialContainer classOption="bg-primary-blue" />
      <CallToActionTwo />
      <Footer />
      {/* <ScrollToTop /> */}
    </React.Fragment>
  );
};

export default AboutUs;
