import React, { useState } from "react";
import { useForm } from "react-hook-form";

const ContactFrom = () => {
  const [message, setMessage] = useState("");
  const { register, handleSubmit, errors, reset } = useForm({
    mode: "onBlur",
  });

  const onSubmit = (data, e) => {
    console.log("data123", data);
    const body = "<p>Hello, </p>" + "<p>Your message, </p>" + "<p>--------</p>" + "<p>" + data.name + "</p>" + "<p>" + data.phone + "</p>" + "<p>" + data.email + "</p>" + "<p>" + data.message + "</p>" + "<p>--------</p>" + "<p>Thanks for Contacting us, we will get back you shorlty.</p><br/><p>Regards, <b>The Unique Creations</b> Team</p>" + '<a href="https://www.theuniquecreations.com">www.theuniquecreations.com</a>';
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        from: "info@theuniquecreations.com",
        to: data.email + "," + "info@theuniquecreations.com",
        subject: "TUC - Contact us - " + data.name,
        text: "",
        html: body,
      }),
    };
    try {
      fetch(
        "https://asia-south1-tucfbclouddb.cloudfunctions.net/tuc_shop_node_api/email/",
        //"http://localhost:8000/email/",
        requestOptions
      ).then((response) => console.log(response.json()));
      //.then((data) => this.setState({ responsemessage: "Thanks for Contacting us." }));
      //this.setState({ responsemessage: "Thanks for Contacting us." });
      setMessage("Thanks for Contacting us.");
      reset({});
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="contact-form" data-aos="fade-up" data-aos-delay="300">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row mb-n6">
          <div className="col-md-6 col-12 mb-6">
            <input type="text" placeholder="Your Name *" className="border" name="name" ref={register({ required: "Name is required" })} />
            {errors.name && <p>{errors.name.message}</p>}
          </div>
          <div className="col-md-6 col-12 mb-6">
            <input
              type="email"
              className="border"
              placeholder="Email *"
              name="email"
              ref={register({
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "invalid email address",
                },
              })}
            />
            {errors.email && <p>{errors.email.message}</p>}
          </div>
          <div className="col-md-6 col-12 mb-6">
            <input type="text" placeholder="Subject *" name="subject" className="border" ref={register({ required: "Subject is required" })} />
            {errors.subject && <p>{errors.subject.message}</p>}
          </div>
          <div className="col-md-6 col-12 mb-6">
            <input type="text" placeholder="Phone number *" name="phone" className="border" ref={register({ required: "Phone is required" })} />
            {errors.phone && <p>{errors.phone.message}</p>}
          </div>
          <div className="col-12 mb-6">
            <textarea name="message" placeholder="Message" className="border" ref={register({ required: "Message is required" })}></textarea>
            {errors.message && <p>{errors.message.message}</p>}
          </div>
          <div className="p-1 "> {message}</div>

          <div className="col-12 text-center mb-6">
            <button type="submit" className="btn btn-primary btn-hover-secondary">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactFrom;
