import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import Login from "../container/Login/Login";
import Video from "../container/Video/Video";
import AboutFive from "../container/About/AboutFive";
import TestimonialContainer from "../container/Testimonial/TestimonialContainer";
import CallToActionTwo from "../container/CallToAction/CallToActionTwo";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";

const AboutUs = () => {
  return (
    <React.Fragment>
      <SEO title="Unique Creations || Login" />
      <Header />
      <Breadcrumb image="images/bg/breadcrumb-bg.jpg" title="Register and Log in " content="Home" contentTwo="Login" />
      <Login />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default AboutUs;
