import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import config from "../../config.json";
import uuid from "react-uuid";
//import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import firebase from "./../../firebase";
import Moment from "moment";
import Chart from "./Chart";
import AdminManagePosts from "./AdminManagePosts";
import AdminManageGallery from "./AdminManageGallery";
import AdminViewUsers from "./AdminViewUsers";
import AdminOrder from "./AdminOrder";
import on from "./../../assets/images/icons/on.png";
import off from "./../../assets/images/icons/off.png";
import edit from "./../../assets/images/icons/edit.png";
import JoditEditor from "jodit-react";
import deleteicon from "./../../assets/images/icons/delete.png";
import "./../../css/style.css";
import "./../../css/templete.css";
import "./../../css/skin/skin-1.css";

const AdminPayementGateway = (props) => {
  //const [productDtl, setProductDtl] = useState({});
  //const [validationMsg, setValidationMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [srEmail, setsrEmail] = useState("");
  const [srPwd, setsrPwd] = useState("");
  const [srChannelid, setsrchannelid] = useState("");
  const [srpickuploaction, setsrpickuploaction] = useState("");
  const [product_image, setProduct_image] = useState("");
  const [product_image_more, setProduct_image_more] = useState("");
  const [smShow, setSmShow] = useState(false);
  const [show, setShow] = useState(false);
  const [imgshow, setImgShow] = useState(false);
  const [products, setProducts] = useState([]);
  const history = useHistory();
  const editor = useRef(null);
  const [orderDetails, setorderDetails] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [editProduct, setEditProduct] = useState({});
  const [networkError, setNetworkError] = useState("");
  const [masterCategory, setMasterCategory] = useState([]);
  const [masterSubCategory, setMasterSubCategory] = useState([]);
  const [p_categorydrp, setp_categorydrp] = useState("");
  const [p_subcategorydrp, setp_subcategorydrp] = useState("");
  //const [dataUri, setDataUri] = useState("");
  const [showCamera, setShowCamera] = useState(false);
  const [ownerNotes, setownerNotes] = useState("");
  const [content, setContent] = useState("");
  const [flag, setFlag] = useState(false);
  const [access, setAccess] = useState(false);
  const config_ = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };
  const handleVisible = () => {
    setSmShow(true);
    setTimeout(() => {
      setSmShow(false);
    }, 1000);
  };

  const getConfig = async () => {
    console.log("cakecategory", props);
    await fetch(props.serviceurl + "getadminaccountconfig", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
    })
      .then((response) => response.json())
      .then((data) => {
        {
          let active = data
            //.filter((filter) => filter.isactive === "1")
            .map((data) => {
              return data;
            });
          setProducts(active);
          console.log(data, "products");
        }
      })
      .catch((err) => {
        //setNetworkError("Something went wrong, Please try again later!!");
        console.log("product error", err);
      });
  };

  const checkaccess = async () => {
    let datas = {};
    datas.userid = localStorage.getItem("uuid");
    datas.page = "Razorpay";
    await fetch(props.serviceurl + "checkaccess", {
      method: "POST",
      headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken") },
      body: JSON.stringify({ data: datas }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("access", data);
        if (data.status === 200) {
          setAccess(true);
        } else {
          history.push("/adminhome");
        }
      })
      .catch((err) => {
        setNetworkError("Something went wrong, Please try again later!!");
      });
  };
  useEffect(() => {
    checkaccess();
    getConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag, access]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: {} });
  const onSubmit = (data, e) => {
    let methodname = "adminaccountconfig";
    setSuccessMsg("Please wait");
    setSmShow(true);
    e.preventDefault();
    if (localStorage.getItem("uuid") === undefined || localStorage.getItem("uuid") === null) {
      history.push("/login");
    } else {
      data.acc_id = "accountconfig";
      data.user_id = localStorage.getItem("uuid");
      data.createddate = new Date();
      data.createduserid = localStorage.getItem("uuid");
      data.createdby = localStorage.getItem("name");
      data.isactive = 1;
      data.updateddate = new Date();
    }

    fetch(props.serviceurl + methodname, { method: "POST", headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken"), accesslogin: "" }, body: JSON.stringify({ data }) })
      .then((response) => response.json())
      .then((data) => {
        console.log("regitered user", data);
        if (data.status === 200) {
          setEditProduct(data.data);
          setSuccessMsg(data.message);
          setSmShow(false);
          handleVisible();
          setFlag(true);
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
        }
      })
      .catch((err) => {
        setSuccessMsg("Something went wrong, Please try again later!!");
      });
  };

  const shiprocketupdate = (email, pwd) => {
    console.log(email, pwd);
    let data = {};
    let methodname = "adminaccountconfig";
    if (localStorage.getItem("uuid") === undefined || localStorage.getItem("uuid") === null) {
      history.push("/login");
    } else {
      data.acc_id = "shippingaccountconfig";
      data.user_id = localStorage.getItem("uuid");
      data.createddate = new Date();
      data.createduserid = localStorage.getItem("uuid");
      data.createdby = localStorage.getItem("name");
      data.isactive = 1;
      data.updateddate = new Date();
      data.ship_rocket_api_email = email;
      data.ship_rocket_api_password = pwd;
      data.ship_rocket_channelid = srChannelid;
      data.pickuploaction = srpickuploaction;
    }

    fetch(props.serviceurl + methodname, { method: "POST", headers: { "Content-Type": "application/json", authorization: localStorage.getItem("accessToken"), accesslogin: "" }, body: JSON.stringify({ data }) })
      .then((response) => response.json())
      .then((data) => {
        console.log("regitered user", data);
        if (data.status === 200) {
          setSuccessMsg(data.message);
          setSmShow(false);
          handleVisible();
          setFlag(true);
        } else if (data?.status === 499) {
          history.push("/login");
        } else {
          setSuccessMsg(data.message);
        }
      })
      .catch((err) => {
        setSuccessMsg("Something went wrong, Please try again later!!");
      });
  };
  const { key_id, key_secret, webhooks_secret, ship_rocket_api_email, ship_rocket_api_password, ship_rocket_channelid, ship_rocket_pickuplocation } = Object.keys(products).length > 0 ? products : {};
  {
    return access ? (
      <div>
        <Modal size="sm" className="z-index" show={smShow} onHide={() => setSmShow(false)}>
          <Modal.Header closeButton>
            <span className="text-dark"> {successMsg}</span>
          </Modal.Header>
        </Modal>

        <div id="review_form_wrapper">
          <div id="review_form">
            <div id="respond" className="comment-respond">
              <h3 className="comment-reply-title" id="reply-title">
                Add Razor pay secret
              </h3>

              <form className="comment-form" onSubmit={handleSubmit(onSubmit)} id="frmProductadd">
                <div className="comment-form-author">
                  <label>
                    Razor Key Id<span className="required">*</span>
                  </label>
                  <input type="password" className="form-control" required aria-required="true" defaultValue={key_id} name="key_id" ref={register} id="key_id" />
                </div>
                <div className="comment-form-author">
                  <label>
                    Razor Key Secret <span className="required">*</span>
                  </label>
                  <input type="password" className="form-control" required aria-required="true" defaultValue={key_secret} name="key_secret" ref={register} id="key_secret" />
                </div>
                <div className="comment-form-author">
                  <label>
                    Razor Web hook Secret <span className="required">*</span>
                  </label>
                  <input type="password" className="form-control" required aria-required="true" defaultValue={webhooks_secret} name="webhooks_secret" ref={register} id="webhooks_secret" />
                </div>

                <div className="form-submit">
                  <button className="btnadmin btnhover" disabled={smShow} type="submit">
                    Update Razor Secrets
                  </button>
                </div>
              </form>
            </div>

            <div id="respond" className="comment-respond">
              <h3 className="comment-reply-title" id="reply-title">
                Add Shipping Rocket API Id/Password
              </h3>

              <form className="comment-form">
                <div className="comment-form-author">
                  <label>Ship API Email</label>
                  <input type="email" className="form-control" required aria-required="true" defaultValue={ship_rocket_api_email} name="ship_rocket_api_email" onChange={(e) => setsrEmail(e.target.value)} ref={register} id="ship_rocket_api_email" />
                </div>
                <div className="comment-form-author">
                  <label>
                    API Password<span className="required">*</span>
                  </label>
                  <input type="password" className="form-control" required aria-required="true" defaultValue={ship_rocket_api_password} name="ship_rocket_api_password" ref={register} onChange={(e) => setsrPwd(e.target.value)} id="ship_rocket_api_password" />
                </div>
                <div className="comment-form-author">
                  <label>
                    Channel Id<span className="required">*</span>
                  </label>
                  <input type="text" className="form-control" required aria-required="true" defaultValue={ship_rocket_channelid} name="ship_rocket_channelid" ref={register} onChange={(e) => setsrchannelid(e.target.value)} id="ship_rocket_channelid" />
                </div>
                <div className="comment-form-author">
                  <label>
                    Pick up Location<span className="required">*</span>
                  </label>
                  <input type="text" className="form-control" required aria-required="true" defaultValue={ship_rocket_pickuplocation} name="ship_rocket_pickuplocation" ref={register} onChange={(e) => setsrpickuploaction(e.target.value)} id="ship_rocket_pickuplocation" />
                </div>

                <div className="form-submit">
                  <button className="btnadmin btnhover" onClick={(e) => shiprocketupdate(srEmail, srPwd)} disabled={smShow} type="button">
                    Update Shipping Secrets
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div> </div>
    );
  }
};

export default AdminPayementGateway;
